export const PDF_CARD_HEIGHT = 300;
export const PDF_CARD_WIDTH = 250;
export const PDF_CANVAS_HEIGHT = 250;
export const PDF_CANVAS_WIDTH = 200;
export const PDF_CARD_GAP = 30;
export const RIGHT_BAR = 400;

const TextFonts = [
  "Roboto",
  "Courier Prime",
  "Open Sans",
  "Montserrat",
  "Crimson Pro",
  "Tinos",
];
const SignFonts = [
  "Alex Brush",
  "Bonbon",
  "Great Vibes",
  "Herr Von Muellerhoff",
  "League Script",
  "Monsieur La Doulaise",
  "Montez",
  "Mr Dafoe",
  "Mrs Saint Delafield",
  "Princess Sofia",
  "Sacramento",
  "Sarina",
  "Stalemate",
];
const Colors = ["black", "green", "blue", "red", "orange", "yellow"];

export { TextFonts, SignFonts, Colors };
