import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import styled from "styled-components";

const Container = ({ pdfNames, pages, order }) => {
  return (
    <ContainerWrapperStyle>
      <PdfCard height="300px" width="250px" cursor="auto">
        <PdfViewer
          pdfIndex={0}
          pageIndex={0}
          orderIndex={order}
          page={pages[0]}
          pageInfo={pdfNames[order]}
          canvasHeight="230"
          canvasWidth="200"
        />
      </PdfCard>
    </ContainerWrapperStyle>
  );
};

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
  cursor: "move";
`;
