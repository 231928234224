import Footer from "../components/Footer/Footer";
import Home from "../components/Home/Home";
import { ReactComponent as HomeBoxes } from "../assets/images/home_boxes.svg";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import classes from "./HomePage.module.css";

import Layout from "../Layouts/Layout";

const HomePage = (props) => {
  return (
    <Layout>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <div className={classes.homeDesign}>
            <HomeBoxes
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: {
                  xs: `calc(50% - 30px)`,
                  sm: `calc(50% - 30px)`,
                  md: `calc(50% - 30px)`,
                  lg: `calc(50% - 30px)`,
                },
                left: {
                  xs: `calc(50% - 150px)`,
                  sm: `calc(50% - 225px)`,
                  md: `calc(50% - 300px)`,
                  lg: `calc(50% - 375px)`,
                },
                fontSize: {
                  xs: "20px",
                  sm: "30px",
                  md: "40px",
                  lg: "50px",
                },
              }}
            >
              <Typography
                fontStyle="normal"
                fontWeight="700"
                fontSize="inherit"
                lineHeight="59px"
                color="#F9F9F9"
              >
                Every PDF tools are in one place!
              </Typography>
            </Box>
          </div>
          <div className={classes.children}>
            <Home />
          </div>
        </Box>
        <Footer />
      </Box>
    </Layout>
  );
};

export default HomePage;
