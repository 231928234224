import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const RightSection = ({
  imageType,
  changeImageType,
  imageQuality,
  changeImageQuality,
}) => {
  const [pdfToImageOpen, setMPdfToImageOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMPdfToImageOpen(!pdfToImageOpen);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>
      <RightSectionStyle right={pdfToImageOpen}>
        <Box
          sx={{
            height: "25px",
            width: "25px",
            mt: 1,
            display: "none",
            cursor: "pointer",
            ml: 1,
            // color: "#87C7FF",
            p: "8px",
            // width: '30px',
            borderRadius: "50%",
            transition: "0.3s ease-in-out",
            "&:hover": {
              background: "#dedad9",
            },
            "@media(max-width: 900px)": { display: "inline-block" },
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </Box>

        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          Pdf to Image
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />
        <Typography
          sx={{
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: { xs: "12px", md: "14px", lg: "16px", xl: "18px" },
            lineHeight: "22px",
            color: "#818181",

            marginTop: {
              xs: "10px",
              sm: "10px",
              md: "20px",
              lg: "30px",
              xl: "40px",
            },
            padding: "0px 10px",
            textAlign: "center !important",
            textAlignLast: "center",
          }}
        >
          Every page of the PDF will be converted into indivisual image file.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SelectImageTypeWrapper>
            <Typography
              variant="p"
              fontSize="16px"
              lineHeight="19px"
              sx={{
                color: "#FF6429",
              }}
            >
              Image type
            </Typography>

            <ButtonWrapper>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={() => changeImageType("JPEG")}
                sx={{
                  width: { xs: "125px", lg: "150px", xl: "170px" },
                  height: { xs: "50px", lg: "55px", xl: "60px" },
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    imageType === "JPEG" ? "0px 10px 7px -3px #BFD5E9" : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      imageType === "JPEG"
                        ? "0px 10px 7px -3px #BFD5E9"
                        : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${imageType === "JPEG" ? "#FF6429" : "#818181"}`,
                  }}
                >
                  convert to jpeg
                </Typography>
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={() => changeImageType("PNG")}
                sx={{
                  width: { xs: "125px", lg: "150px", xl: "170px" },
                  height: { xs: "50px", lg: "55px", xl: "60px" },
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    imageType === "PNG" ? "0px 10px 7px -3px #BFD5E9" : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      imageType === "PNG" ? "0px 10px 7px -3px #BFD5E9" : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${imageType === "PNG" ? "#FF6429" : "#818181"}`,
                  }}
                >
                  convert to png
                </Typography>
              </Button>
            </ButtonWrapper>
          </SelectImageTypeWrapper>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="p"
            fontSize="16px"
            lineHeight="19px"
            sx={{
              color: "#FF6429",
            }}
          >
            Image Quality
          </Typography>

          <TextField
            select
            value={imageQuality}
            onChange={(event) =>
              changeImageQuality(parseFloat(event.target.value))
            }
            sx={{
              width: "170px",
              "& .MuiInputBase-input": { padding: "6px" },
            }}
          >
            <MenuItem key={0} value={0.5}>
              <Typography
                variant="p"
                fontSize="14px"
                lineHeight="16px"
                textTransform="none"
                sx={{
                  color: "#2c2b2b",
                }}
              >
                50%
              </Typography>
            </MenuItem>
            <MenuItem key={1} value={0.75}>
              <Typography
                variant="p"
                fontSize="14px"
                lineHeight="16px"
                textTransform="none"
                sx={{
                  color: "#2c2b2b",
                }}
              >
                Recommended
              </Typography>
            </MenuItem>
            <MenuItem key={2} value={1}>
              <Typography
                variant="p"
                fontSize="14px"
                lineHeight="16px"
                textTransform="none"
                sx={{
                  color: "#2c2b2b",
                }}
              >
                100%
              </Typography>
            </MenuItem>
          </TextField>
        </Box>
      </RightSectionStyle>
      {/* </Drawer> */}
    </>
  );
};

export default RightSection;

export const RightSectionStyle = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;

  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
  }
`;

export const SelectImageTypeWrapper = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
