import { Box, Divider, TextField, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const SelectPageViewer = ({ open, list, updateList, maxPage }) => {
  const [error, setError] = useState(false);
  if (!open) return null;
  const updateHandler = (event) => {
    const range = event.target.value;
    let isValid = true;

    const parts = range.split(",");
    for (const part of parts) {
      const atom = part.split("-");
      if (
        atom.length === 1 &&
        parseInt(atom[0]) >= 1 &&
        parseInt(atom[0]) <= maxPage
      )
        isValid = true;
      else if (
        atom.length === 2 &&
        parseInt(atom[0]) >= 1 &&
        parseInt(atom[0]) <= maxPage &&
        parseInt(atom[1]) >= 1 &&
        parseInt(atom[1]) <= maxPage &&
        parseInt(atom[0]) <= parseInt(atom[1])
      )
        isValid = true;
      else {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      setError(false);
    } else {
      setError(true);
    }
    updateList(event.target.value);
  };
  return (
    <>
      <ExtractPageViewerWrapper>
        <Typography
          variant="p"
          fontSize="14px"
          lineHeight="16px"
          sx={{
            color: "#4A4A4A",
          }}
        >
          Pages to extract:
        </Typography>
        <TextField
          placeholder="Example: 1,6-10"
          sx={{
            border: "1px solid #B7D2E9",
            borderRadius: "8px",
            height: "40px",
            width: "100%",

            "& .MuiOutlinedInput-root": {
              height: "100%",
              fontSize: "14px",
              lineHeight: "16px",
            },
          }}
          value={list}
          onChange={updateHandler}
        />
        {error && (
          <Typography variant="p" color="error">
            invalid range
          </Typography>
        )}
      </ExtractPageViewerWrapper>
      <Divider
        sx={{
          width: "100%",
          margin: "10px 0px",
          border: "1px solid #87C7FF",
        }}
      />
      <Box
        sx={{
          width: "80%",
          marginTop: "25px",
          backgroundColor: "#E1ECF5",
          padding: "15px",
        }}
      >
        <Typography
          variant="p"
          fontSize="14px"
          lineHeight="16px"
          sx={{
            color: "#4A4A4A",
          }}
        >
          {`Every selected page of this PDF file will be converted in one separated PDF file.`}
        </Typography>
      </Box>
    </>
  );
};
export default SelectPageViewer;

const ExtractPageViewerWrapper = styled.div`
  box-sizing: border-box;
  width: 90%;
  padding: 30px 10px 30px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 10px;
`;
