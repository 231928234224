import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import { ReactComponent as SecondaryUploadIcon } from "../../assets/images/secondaryUpload.svg";

const SecondaryUpload = ({ isMultipleAllowed, fileType, setUploadedFiles }) => {
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onLocalUpload = (event) => {
    if (event.target.files.length === 0) return;
    setUploadedFiles(event.target.files);
  };

  return (
    <Box
      sx={{
        top: "110px",
        right: {
          xs: "30px",
          sm: "30px",
          md: "330px",
          lg: "380px",
          xl: "430px",
        },
        position: "fixed",
        zIndex: 10,
        borderRadius: "50%",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 20%)",
        "&:hover": {
          boxShadow: "0 0 8px 8px rgb(0 0 0 / 20%)",
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <label
        htmlFor="local-upload-button"
        style={{ height: "32px", width: "32px", padding: "0px" }}
      >
        <Input
          multiple={isMultipleAllowed}
          accept={fileType}
          id="local-upload-button"
          type="file"
          onChange={onLocalUpload}
        />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ padding: "0px" }}
        >
          <SecondaryUploadIcon
            style={{
              height: "32px",
              width: "32px",
            }}
            fill={`${hover ? "rgb(68, 65, 65)" : "url(#paint0_linear_11_366)"}`}
          />
        </IconButton>
      </label>
      {/* <IconButton color="primary" sx={{ padding: "0px" }}>
        <img
          src="/assets/images/secondaryUpload.svg"
          alt="secondary upload icon"
          onMouseEnter={handleToggle}
          onMouseLeave={() => setAnchorEl(null)}
        ></img>
      </IconButton>

      <Popper open={open} anchorEl={anchorEl}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "5px",
            marginTop: "5px",
          }}
        >
          <label
            htmlFor="local-upload-button"
            style={{ height: "32px", width: "32px", padding: "0px" }}
          >
            <Input
              multiple={isMultipleAllowed}
              accept={fileType}
              id="local-upload-button"
              type="file"
              onChange={onLocalUpload}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{ padding: "0px" }}
            >
              <img
                src="/assets/images/googleDriveUpload.svg"
                alt="local upload icon"
                height="32px"
                // onClick={handleToggle}
              ></img>
            </IconButton>
          </label>
          <label
            htmlFor="googleDrive-upload-button"
            style={{ height: "32px", width: "32px", padding: "0px" }}
          >
            <Input
              multiple={isMultipleAllowed}
              accept={fileType}
              id="googleDrive-upload-button"
              type="file"
              onChange={onLocalUpload}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{ padding: "0px" }}
            >
              <img
                src="/assets/images/googleDriveUpload.svg"
                alt="googleDrive upload icon"
                height="32px"
                // onClick={handleToggle}
              ></img>
            </IconButton>
          </label>
          <label
            htmlFor="dropbox-upload-button"
            style={{ height: "32px", width: "32px", padding: "0px" }}
          >
            <Input
              multiple={isMultipleAllowed}
              accept={fileType}
              id="dropbox-upload-button"
              type="file"
              onChange={onLocalUpload}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{ padding: "0px" }}
            >
              <img
                src="/assets/images/dropboxUpload.svg"
                alt="dropbox upload icon"
                height="32px"
                onClick={handleToggle}
              ></img>
            </IconButton>
          </label>
        </Box>
      </Popper> */}
    </Box>
  );
};

export default SecondaryUpload;

const Input = styled("input")({
  display: "none",
});
