import classes from "./LoginSignupStyle.module.css";
import { ReactComponent as GoogleSignStyleBoxes } from "../../assets/images/googleSignStyle_boxes.svg";
import { Box } from "@mui/system";

const LoginSignupStyle = ({ children, leftOffset }) => {
  return (
    <div className={classes.style}>
      <GoogleSignStyleBoxes />
      <Box
        sx={{
          top: `calc(50% - 70px)`,
          left: `calc(50% - ${leftOffset}px)`,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default LoginSignupStyle;
