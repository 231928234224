import { useContext, useEffect, useRef, useState } from "react";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
} from "../../../helpers/utils";
import Container from "./Container";
import styled from "styled-components";
import arrayMove from "array-move";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import RightSection from "./RightSection";
import DownloadButton from "../../DownloadSection/DownloadButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NotificationContext } from "../../../context/NotificationContext";

const ProtectPdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const [order, setOrder] = useState([]); //number
  const aRef = useRef(null);
  const [selectedType, setSelectedType] = useState(0);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      const newFiles = [];
      const newNames = [];
      const newPages = [];
      const newOrder = [];
      for (const file of files) {
        try {
          const pdfName = await getFileName(file);
          const doc = await PdfjsDocument(file);
          const pages = await pdfjsExtractPages(doc, [1]);

          newFiles.push(file);
          newNames.push(pdfName);
          newPages.push(pages);
          newOrder.push(uploadedFiles.current.length + newFiles.length - 1);
        } catch (err) {
          setShowNotification(false);
          notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
          notificationType.current = "error";
          setShowNotification(true);
          return;
        }
      }

      uploadedFiles.current.push(...newFiles);
      pdfNames.current.push(...newNames);
      pdfPages.current.push(...newPages);
      setOrder((prev) => [...prev, ...newOrder]);
    };
    initializeStates();
  }, [files]);

  const formik = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password1: Yup.string()
        .min(6, "Password must be atleast 6 characters")
        .required("Password is required"),
      password2: Yup.string()
        .oneOf([Yup.ref("password1")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
  });

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder((oldOrder) => arrayMove(oldOrder, oldIndex, newIndex));
  };
  const deleteHandler = (pdfIndex, pageIndex, orderIndex) => {
    uploadedFiles.current.splice(pdfIndex, 1, null);
    pdfNames.current.splice(pdfIndex, 1, null);
    pdfPages.current.splice(pdfIndex, 1, null);
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
  };

  const onDownloadClick = () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Adding password";
    notificationType.current = "info";
    setShowNotification(true);

    const formData = new FormData();
    let index = 0;
    for (const ord of order) {
      formData.append(`file_${index}`, uploadedFiles.current[ord]);
      index += 1;
    }

    formData.append("fileSize", index);
    formData.append("shouldMerge", 1 ^ selectedType);
    formData.append("password", formik.values.password1);

    fetch(`${process.env.REACT_APP_REST_API_ENDPOINT}/tools/protect-pdf/`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (data?.link) {
          const blob = await fetch(data.link).then((r) => r.blob());
          if (selectedType === 0)
            downloadDocument(
              blob,
              "protectedMerged.pdf",
              "application/pdf",
              aRef
            );
          else
            downloadDocument(blob, "protectedPDF.zip", "application/zip", aRef);

          setShowNotification(false);
          notificationMessage.current = "Successfully protected pdf";
          notificationType.current = "success";
          setShowNotification(true);
          setDisableDownloadButton(false);
        } else {
          throw new Error(data?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = err?.message || "Something went wrong";
        notificationType.current = "error";
        setShowNotification(true);
        return;
      });
  };
  return (
    <>
      <ProtectPdfStyle>
        {order !== null && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            order={order}
            onSortEnd={sortEndHandler}
            onDelete={deleteHandler}
            axis={"xy"}
            distance={5}
          />
        )}
      </ProtectPdfStyle>
      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />
      <RightSection
        formik={formik}
        selectedType={selectedType}
        selectTypeHandler={setSelectedType}
      />
      <DownloadButton
        onDownloadClick={onDownloadClick}
        text="Compress PDF"
        formik={formik}
        buttonPosition="bottom-dynamic"
      />
      <a ref={aRef} />
    </>
  );
};

export default ProtectPdf;

const ProtectPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
