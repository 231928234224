import { Box, Button, Input, Menu, Slider } from "@mui/material";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";

const FontSizeButton = ({ fontSize, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSliderChange = (event, newValue) => {
    onUpdate({ fontSize: newValue });
  };
  const handleInputChange = (event) => {
    onUpdate({ fontSize: parseInt(event.target.value) });
  };
  return (
    <>
      <Button
        variant="outlined"
        disableElevation
        disableRipple
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          padding: "2px",
          margin: 0,
          minWidth: "auto",
          borderRadius: "0px",
          backgroundColor: `${anchorEl ? "#d9f0fa" : "white"}`,
          "&:hover": {
            backgroundColor: `${anchorEl ? "#d9f0fa" : "white"}`,
          },
          "& .MuiButton-endIcon": {
            margin: "0px",
          },
        }}
        onClick={handleOpen}
      >
        <FormatSizeIcon color="primary" variant="outlined" />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        id="basic-menu"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "180px",
            padding: "0px 5px 0px 10px",
            gap: "10px",
          }}
        >
          <Slider
            value={fontSize}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            size="small"
            min={1}
            max={100}
            sx={{
              width: "120px",
            }}
          />
          <Input
            value={fontSize}
            size="small"
            onChange={handleInputChange}
            sx={{
              width: "45px",
              padding: "0px",
              "&:before": {
                border: "none",
              },
              "&:after": {
                border: "none",
              },
            }}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Box>
      </Menu>
    </>
  );
};

export default FontSizeButton;
