import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
} from "../../../helpers/utils";
import Container from "./Container";
import * as Yup from "yup";
import styled from "styled-components";
import DownloadButton from "../../DownloadSection/DownloadButton";
import RightSection from "./RightSection";
import { NotificationContext } from "../../../context/NotificationContext";

const UnlockPdf = ({ files }) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef(); //1d array
  const pdfPages = useRef([]); //1d array
  const [unlockedBlob, setUnlockedBlob] = useState(null);
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      uploadedFiles.current = files[0];
      pdfNames.current = await getFileName(files[0]);
    };
    initializeStates();
  }, [files]);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
    }),
  });

  const onDownloadClick = () => {
    setDisableDownloadButton(true);
    if (unlockedBlob) {
      setShowNotification(false);
      notificationMessage.current = "file will be downloaded soon";
      notificationType.current = "info";
      setShowNotification(true);
      downloadDocument(
        unlockedBlob,
        "UnlockedPDF.pdf",
        "application/pdf",
        aRef
      );
      setDisableDownloadButton(false);
      setShowNotification(false);
      notificationMessage.current = "downloaded!";
      notificationType.current = "success";
      setShowNotification(true);
      return;
    }

    setShowNotification(false);
    notificationMessage.current = "uploading file";
    notificationType.current = "info";
    setShowNotification(true);

    const formData = new FormData();
    formData.append("file", uploadedFiles.current);
    formData.append("password", formik.values.password);
    fetch(`${process.env.REACT_APP_REST_API_ENDPOINT}/tools/unlock-pdf/`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (data?.link) {
          try {
            const blob = await fetch(data.link).then((r) => r.blob());
            const doc = await PdfjsDocument(blob);
            const pages = await pdfjsExtractPages(doc, [1]);
            pdfPages.current = pages;
            setUnlockedBlob(blob);

            setShowNotification(false);
            notificationMessage.current = "Successfully unlocked pdf";
            notificationType.current = "success";
            setShowNotification(true);
            setDisableDownloadButton(false);
          } catch (err) {
            throw new Error("Can not unlock pdf");
          }
        } else {
          throw new Error(data?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = err?.message || "Something went wrong";
        notificationType.current = "error";
        setShowNotification(true);
        return;
      });
  };

  return (
    <>
      <UnlockPdfStyle>
        <Container pdfNames={pdfNames.current} pages={pdfPages.current} />
      </UnlockPdfStyle>
      <RightSection formik={formik} />
      <DownloadButton
        onDownloadClick={onDownloadClick}
        text={unlockedBlob ? "Download" : "Upload PDF"}
        formik={formik}
        buttonPosition="bottom-dynamic"
      />
      <a ref={aRef} />
    </>
  );
};

export default UnlockPdf;

const UnlockPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
