import { ReactComponent as MergePdfIcon } from "../../assets/toolIcons/mergePdf.svg";
import { ReactComponent as SignPdfIcon } from "../../assets/toolIcons/signPdf.svg";
import { ReactComponent as PdfToImageIcon } from "../../assets/toolIcons/pdfToImage.svg";
import { ReactComponent as ImageToPdfIcon } from "../../assets/toolIcons/imageToPdf.svg";
import { ReactComponent as SplitPdfIcon } from "../../assets/toolIcons/splitPdf.svg";
import { ReactComponent as CompressPdfIcon } from "../../assets/toolIcons/compressPdf.svg";
import { ReactComponent as PdfPaginationIcon } from "../../assets/toolIcons/pdfPagination.svg";
import { ReactComponent as WatermarkPdfIcon } from "../../assets/toolIcons/watermarkPdf.svg";
import { ReactComponent as OrganizePdfIcon } from "../../assets/toolIcons/organizePdf.svg";
import { ReactComponent as UnlockPdfIcon } from "../../assets/toolIcons/unlockPdf.svg";
import { ReactComponent as RotatePdfIcon } from "../../assets/toolIcons/rotatePdf.svg";
import { ReactComponent as ProtectPdfIcon } from "../../assets/toolIcons/protectPdf.svg";

const iconStyle = {
  height: "100%",
  width: "100%",
};

const ToolInfos = {
  mergePdf: {
    icon: <MergePdfIcon style={iconStyle} />,
    title: "Merge PDF",
    description:
      "Combine PDFs in the order you want with the easiest PDF merger available.",
    link: "/tools/merge-pdf",
    isMultipleAllowed: true,
    fileType: "application/pdf",
  },
  signPdf: {
    icon: <SignPdfIcon style={iconStyle} />,
    title: "Sign PDF",
    description:
      "Sign a document and request signatures. Draw your signature or sign PDF files with a certificate-based digital ID.",
    link: "/tools/sign-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  pdfToImage: {
    icon: <PdfToImageIcon style={iconStyle} />,
    title: "PDF to Image",
    description:
      "Convert each PDF page into a JPG or extract all images contained in a PDF.",
    link: "/tools/pdf-to-image",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  imageToPdf: {
    icon: <ImageToPdfIcon style={iconStyle} />, //wrong image
    title: "Image to PDF",
    description:
      "Convert images to PDF in seconds. Easily adjust orientation and margins.",
    link: "/tools/image-to-pdf",
    isMultipleAllowed: true,
    fileType: "image/*",
  },
  splitPdf: {
    icon: <SplitPdfIcon style={iconStyle} />,
    title: "Split PDF",
    description:
      "Separate one page or a whole set for easy conversion into independent PDF files.",
    link: "/tools/split-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  compressPdf: {
    icon: <CompressPdfIcon style={iconStyle} />,
    title: "Compress PDF",
    description: "Reduce file size while optimizing for maximal PDF quality.",
    link: "/tools/compress-pdf",
    isMultipleAllowed: true,
    fileType: "application/pdf",
  },
  pdfPagination: {
    icon: <PdfPaginationIcon style={iconStyle} />,
    title: "Page Numbers",
    description:
      "Add page numbers into PDFs with ease. Choose your positions, dimensions, typography.",
    link: "/tools/pagination-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  watermarkPdf: {
    icon: <WatermarkPdfIcon style={iconStyle} />,
    title: "Watermark",
    description:
      "Stamp an image or text over your PDF in seconds. Choose the typography, transparency and position.",
    link: "/tools/watermark-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  organizePdf: {
    icon: <OrganizePdfIcon style={iconStyle} />,
    title: "Organize PDF",
    description:
      "Sort pages of your PDF file however you like. Delete PDF pages or add PDF pages to your document at your convenience.",
    link: "/tools/organize-pdf",
    isMultipleAllowed: true,
    fileType: "application/pdf",
  },
  unlockPdf: {
    icon: <UnlockPdfIcon style={iconStyle} />,
    title: "Unlock PDF",
    description:
      "Remove PDF password security, giving you the freedom to use your PDFs as you want.",
    link: "/tools/unlock-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
  rotatePdf: {
    icon: <RotatePdfIcon style={iconStyle} />,
    title: "Rotate PDF",
    description:
      "Rotate your PDFs the way you need them. You can even rotate multiple PDFs at once!",
    link: "/tools/rotate-pdf",
    isMultipleAllowed: true,
    fileType: "application/pdf",
  },
  protectPdf: {
    icon: <ProtectPdfIcon style={iconStyle} />,
    title: "Protect PDF",
    description:
      "Protect PDF files with a password. Encrypt PDF documents to prevent unauthorized access. ",
    link: "/tools/protect-pdf",
    isMultipleAllowed: false,
    fileType: "application/pdf",
  },
};

export default ToolInfos;
