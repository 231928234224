import { useDrop } from "react-dnd";
import { useRef } from "react";
import RenderPdf from "../UI/Canvas/RenderPdf";
import { Box } from "@mui/system";
import TextComponent from "../Tools/SignPdf/TextComponent";
import ResizableSignImageComponent from "../Tools/SignPdf/ResizeSignImageComponent";

const DroppableComponent = ({
  index,
  page,
  canvasRefs,
  containerRef,
  sign,
  signImageComponents,
  textComponents,
  onSignImageUpdate,
  onDeleteSignImage,
  onTextPositionUpdate,
  onTextUpdate,
  activeSignType,
  onDropText,
  onDeleteText,
}) => {
  canvasRefs.current[index] = useRef(null);

  const [, drop = []] = useDrop({
    accept: ["signImage", "signText", "text"],
    drop: (item, monitor) => ({
      name: {
        name: "pdf",
        index: index,
        x:
          monitor.getSourceClientOffset()["x"] -
          canvasRefs.current[index].current.getBoundingClientRect()["x"],
        y:
          monitor.getSourceClientOffset()["y"] -
          canvasRefs.current[index].current.getBoundingClientRect()["y"],
      },
    }),
    hover: (item, monitor) => {
      // console.log("hover");
    },
  });
  drop(canvasRefs.current[index]);

  return (
    <>
      <Box
        onClick={(event) => {
          if (activeSignType === 0) {
            const offsetX =
              event.clientX -
              canvasRefs.current[index].current.getBoundingClientRect()["x"];
            const offsetY =
              event.clientY -
              canvasRefs.current[index].current.getBoundingClientRect()["y"];
            onDropText(index, {
              offsetX: offsetX,
              offsetY: offsetY,
              text: "Type here",
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: 20,
              fontFamily: "Roboto",
              color: "black",
            });
          }
        }}
      >
        <RenderPdf
          page={page}
          height="900"
          width="700"
          keepOriginalSize
          rotation={0}
          canvasRef={canvasRefs.current[index]}
        />
      </Box>

      {signImageComponents?.length > 0 &&
        canvasRefs.current[index] &&
        signImageComponents.map((e, signIndex) => (
          <ResizableSignImageComponent
            key={signIndex}
            pageIndex={index}
            signIndex={signIndex}
            sign={sign}
            signImageObject={e}
            onSignImageUpdate={onSignImageUpdate}
            onDeleteSignImage={onDeleteSignImage}
            canvasRef={canvasRefs.current[index]}
            containerRef={containerRef}
          />
        ))}

      {textComponents?.length > 0 &&
        canvasRefs.current[index] &&
        textComponents.map((e, textIndex) => (
          <TextComponent
            key={textIndex}
            pageIndex={index}
            textIndex={textIndex}
            textObject={e}
            onTextPositionUpdate={onTextPositionUpdate}
            onTextUpdate={onTextUpdate}
            canvasRef={canvasRefs.current[index]}
            containerRef={containerRef}
            onDeleteText={onDeleteText}
          />
        ))}
    </>
  );
};

export default DroppableComponent;
