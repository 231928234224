import { useEffect, useRef, useState } from "react";

const RenderImage = ({
  image,
  height,
  width,
  rotation,
  canvasRef,
  keepOriginalSize,
  cursor,
}) => {
  const [dimension, setDimension] = useState(null);
  const newRef = useRef(null);
  const cnvRef = canvasRef || newRef;
  useEffect(() => {
    const canvas = cnvRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const imageW = image.naturalWidth;
    const imageH = image.naturalHeight;

    if (rotation === 0 || rotation === 2) {
      canvas.height = imageH;
      canvas.width = imageW;
    } else {
      canvas.width = imageH;
      canvas.height = imageW;
    }

    if (rotation !== 0) {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * 90 * Math.PI) / 180);
      if (rotation === 2) ctx.translate(-canvas.width / 2, -canvas.height / 2);
      else ctx.translate(-canvas.height / 2, -canvas.width / 2);
    }

    const ratio = keepOriginalSize
      ? 1
      : Math.min(height / canvas.height, width / canvas.width);

    setDimension({
      height: canvas.height * ratio + "px",
      width: canvas.width * ratio + "px",
    });
    ctx.drawImage(image, 0, 0);
    canvas.style.height = canvas.height * ratio + "px";
    canvas.style.width = canvas.width * ratio + "px";
  }, [image, rotation, height, width]);

  return (
    <div
      style={{
        position: "relative",
        height: dimension ? dimension.height : height,
        width: dimension ? dimension.width : width,
        boxShadow: "0 0 8px 0px rgb(0 0 0 / 20%)",
      }}
    >
      <canvas ref={cnvRef} style={{ background: "white" }} />
    </div>
  );
};

export default RenderImage;
