import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import useMediaQuery from "@mui/material/useMediaQuery";

const PdfRangeViewer = ({ index, range, pages }) => {
  const smallDevice = useMediaQuery("(max-width:600px)");
  if (!range) return null;
  return (
    <Box
      sx={{
        height: { xs: "250px", sm: "290px" },
        width: {
          xs: `${range[0] === range[1] ? "140px" : "300px"}`,
          sm: `${range[0] === range[1] ? "200px" : "380px"}`,
        },
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Typography
        variant="p"
        fontSize="14px"
        lineHeight="16px"
        sx={{
          color: "#FF6429",
        }}
      >{`Range ${index + 1}`}</Typography>
      <Box
        sx={{
          height: `calc(100% - 30px)`,
          width: { xs: `calc(100% - 0px)`, sm: `calc(100% - 10px)` },
          padding: { xs: "2px", sm: "12px" }, //5 or 15
          boxSizing: "border-box",
          border: "1px dashed #4A4A4A",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <PdfCard
          height={`calc(100% - 4px)`}
          width={
            smallDevice
              ? range[0] !== range[1]
                ? `calc(50% - 0px)`
                : `calc(100% - 0px)`
              : range[0] !== range[1]
              ? `calc(50% - 12px)`
              : `calc(100% - 4px)`
          }
        >
          <PdfViewer
            page={pages[range[0] - 1]}
            pageInfo={`page ${range[0]}`}
            canvasHeight={smallDevice ? "150" : "170"}
            canvasWidth="150"
          />
        </PdfCard>
        {range[0] !== range[1] && (
          <>
            <MoreHorizIcon fontSize="small" sx={{ color: "#4A4A4A" }} />
            <PdfCard
              height={`calc(100% - 4px)`}
              width={smallDevice ? `calc(50% - 0px)` : `calc(50% - 12px)`}
            >
              <PdfViewer
                page={pages[range[1] - 1]}
                pageInfo={`page ${range[1]}`}
                canvasHeight={smallDevice ? "150" : "170"}
                canvasWidth="150"
              />
            </PdfCard>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PdfRangeViewer;
