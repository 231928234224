import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import styled from "styled-components";

const Container = ({
  pdfNames,
  pages,
  rotations,
  order,
  onRotate,
  onDelete,
}) => {
  return (
    <ContainerStyle>
      {order.map((pos, index) => (
        <PdfCard height="300px" width="250px" cursor="auto" key={index}>
          <PdfViewer
            pdfIndex={pos}
            pageIndex={0}
            orderIndex={index}
            page={pages[pos][0]}
            pageInfo={pdfNames[pos]}
            canvasHeight="230"
            canvasWidth="200"
            rotation={rotations[pos]}
            rotateLeft
            rotateRight
            rotateHandler={onRotate}
            deleteHandler={onDelete}
          />
        </PdfCard>
      ))}
    </ContainerStyle>
  );
};

export default Container;

const ContainerStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
