import classes from "./SignList.module.css";
import DraggableSignImageComponent from "../../common/DraggableSignImageComponent";
import ClearIcon from "@mui/icons-material/Clear";
const SignList = ({ sign, onHideSign, onDropSignImage }) => {
  return (
    <div className={classes.signList}>
      {sign?.map(
        (sgn, index) =>
          sgn.visible && (
            <div className={classes.signItem} key={index}>
              {sgn.type === "signImage" && (
                <DraggableSignImageComponent
                  index={index}
                  src={sgn.file}
                  height="70"
                  width="120"
                  type={sgn.type}
                  rotation={0}
                  onDropSignImage={onDropSignImage}
                />
              )}
              {sgn.type === "signText" && (
                <DraggableSignImageComponent
                  index={index}
                  src={sgn.file}
                  height="70"
                  width="120"
                  type={sgn.type}
                  rotation={0}
                  onDropSignImage={onDropSignImage}
                />
              )}

              <ClearIcon
                fontSize="medium"
                sx={{
                  color: "#ccc",
                  marginRight: "15px",
                  cursor: "pointer",
                  "&:hover": { color: "#0282e5" },
                }}
                onClick={() => onHideSign(index)}
              />
            </div>
          )
      )}
    </div>
  );
};

export default SignList;
