import PdfViewer from "../../common/PdfViewer";
import SortableHocElememt from "../../common/SortableHocElement";
import PdfCard from "../../UI/Card/PdfCard";
import styled from "styled-components";
import { SortableContainer } from "react-sortable-hoc";

const Container = SortableContainer(({ pdfNames, pages, order, onDelete }) => {
  return (
    <ContainerWrapperStyle>
      {order.map((pos, index) => (
        <SortableHocElememt key={pos} index={index}>
          <PdfCard height="300px" width="250px" cursor="move">
            <PdfViewer
              pdfIndex={pos}
              pageIndex={0}
              orderIndex={index}
              page={pages[pos][0]}
              pageInfo={pdfNames[pos]}
              canvasHeight="230"
              canvasWidth="200"
              deleteHandler={onDelete}
            />
          </PdfCard>
        </SortableHocElememt>
      ))}
    </ContainerWrapperStyle>
  );
});

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
