import classes from "./ImageViewer.module.css";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ClearIcon from "@mui/icons-material/Clear";
import RenderImage from "../UI/Canvas/RenderImage";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";

const ImageViewer = ({
  image,
  imageIndex,
  orderIndex,
  imageInfo,
  rotation,
  canvasHeight,
  canvasWidth,
  rotationHandler,
  deleteHandler,
}) => {
  const [hover, setHover] = useState(false);
  const hasButton = rotationHandler || deleteHandler;

  const onRotateRightBtnClick = () => {
    rotationHandler(imageIndex, 1);
  };
  const onRotateLeftBtnClick = () => {
    rotationHandler(imageIndex, -1);
  };
  const onDeleteBtnClick = () => {
    deleteHandler(imageIndex, orderIndex);
  };

  return (
    <div
      className={classes.imageViewer}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {hasButton && hover && (
        <div className={classes.imageViewer__buttons}>
          {rotationHandler && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
                "&:hover": { backgroundColor: "red", color: "white" },
              }}
              onClick={onRotateLeftBtnClick}
            >
              <RotateLeftIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
          {rotationHandler && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                "&:hover": { backgroundColor: "red", color: "white" },
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
              }}
              onClick={onRotateRightBtnClick}
            >
              <RotateRightIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
          {deleteHandler && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                "&:hover": { backgroundColor: "red", color: "white" },
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
              }}
              onClick={onDeleteBtnClick}
            >
              <ClearIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
        </div>
      )}
      <div className={classes.imageViewer__canvas}>
        <RenderImage
          image={image}
          height={canvasHeight ? canvasHeight : 250}
          width={canvasWidth ? canvasWidth : 200}
          rotation={rotation ? rotation : 0}
        />
      </div>

      {imageInfo != null && (
        <div className={classes.imageViewer__imageInfo}>
          <Typography
            variant="body2"
            fontSize=".7rem"
            sx={{
              width: "180px",
              fontSize: "12px",
              letterSpacing: ".3px",
              color: "#474847",
              textAlign: "center !important",
            }}
          >
            {imageInfo}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ImageViewer;
