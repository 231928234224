import styled from "styled-components";
import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import PdfRangeViewer from "./PdfRangeViewer";

const Container = ({ pdfNames, pages, splitMode, list }) => {
  let ranges = [];
  let extraction_list = new Array(pages.length).fill(false);

  if (splitMode === "custom_range") {
    ranges = list;
  } else if (splitMode === "fixed_range") {
    for (let left = 1; left <= pages.length; left += list) {
      let right = Math.min(pages.length, left + list - 1);
      ranges.push([left, right]);
    }
  } else if (splitMode === "custom_page") {
    const parts = list.split(",");
    for (const part of parts) {
      const range = part.split("-");
      if (
        range.length === 1 &&
        parseInt(range[0]) >= 1 &&
        parseInt(range[0]) <= pages.length
      )
        extraction_list[parseInt(range[0]) - 1] = true;
      else {
        for (
          let index = range[0];
          index <= parseInt(range[1]) && index >= 1 && index <= pages.length;
          index++
        ) {
          extraction_list[index - 1] = true;
        }
      }
    }
  } else {
    for (let index = 1; index <= pages.length; index++)
      extraction_list[index - 1] = true;
  }
  return (
    <ContainerWrapperStyle>
      {(splitMode === "custom_range" || splitMode === "fixed_range") &&
        ranges?.map((range, index) => (
          <PdfRangeViewer
            key={index}
            index={index}
            range={range}
            pages={pages}
          />
        ))}
      {(splitMode === "custom_page" || splitMode === "all_page") &&
        extraction_list.map((selected, index) => (
          <PdfCard height="300px" width="250px" cursor="auto">
            <PdfViewer
              page={pages[index]}
              pageInfo={`page ${index + 1}`}
              canvasHeight="230"
              canvasWidth="200"
              selected={selected}
            />
          </PdfCard>
        ))}
    </ContainerWrapperStyle>
  );
};

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  /* display: grid; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
