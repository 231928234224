import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import DownloadButton from "../../DownloadSection/DownloadButton";
import Container from "./Container";
import RightSection from "./RightSection";

const PdfPagination = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const [order, setOrder] = useState(null); //number
  const [pageMode, setPageMode] = useState(0);
  const [hasCoverPage, setHasCoverpage] = useState(true);
  const [position, setPosition] = useState(0);
  const [marginType, setMarginType] = useState(0);
  const [lowPage, setLowPage] = useState(1);
  const [highPage, setHighPage] = useState(1);
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      let pdfName;
      let pages;
      let doc;
      try {
        pdfName = await getFileName(files[0]);
        doc = await PdfjsDocument(files[0]);
        pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );
      } catch (err) {
        setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
      uploadedFiles.current.push(files[0]);
      pdfNames.current.push(pdfName);
      pdfPages.current.push(...pages);
      setHighPage(pdfPages.current.length);
      setOrder([...Array(doc.numPages).keys()].map((e) => e));
    };
    initializeStates();
  }, [files]);

  const downloadHandler = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Changes in progress";
    notificationType.current = "info";
    setShowNotification(true);

    let top = null,
      bottom = null,
      left = null,
      right = null;
    if (position >= 0 && position <= 2) {
      if (marginType === 0) top = 20;
      else if (marginType === 1) top = 30;
      else top = 40;
    } else if (position >= 6 && position <= 8) {
      if (marginType === 0) bottom = 10;
      else if (marginType === 1) bottom = 20;
      else bottom = 30;
    } else {
      top = "50%";
    }

    if (position % 3 === 0) {
      if (marginType === 0) left = 10;
      else if (marginType === 1) left = 20;
      else left = 30;
    } else if (position % 3 === 2) {
      if (marginType === 0) right = 20;
      else if (marginType === 1) right = 30;
      else right = 40;
    } else {
      left = "50%";
    }
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees, StandardFonts, rgb } = PDFLib;
    const pdfDoc = await PDFDocument.create();
    const doc = await PdfLibDocument(uploadedFiles.current[0]);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());
    let numberToAdd = 1;
    for (let index = 0; index < copiedPages.length; index++) {
      const page = copiedPages[index];

      const { width, height } = page.getSize();

      let offsetX = left ? left : width - right;
      if (left === "50%") offsetX = width / 2 - 2;
      let offsetY = bottom ? bottom : height - top;
      if (top === "50%") offsetY = height / 2;

      if (
        index + 1 >= lowPage &&
        index + 1 <= highPage &&
        !(hasCoverPage && index + 1 === 1)
      ) {
        page.drawText(`${numberToAdd}`, {
          x: offsetX,
          y: offsetY,
        });
        numberToAdd += 1;
      }

      pdfDoc.addPage(page);
    }
    setShowNotification(false);
    notificationMessage.current = "file will be downloaded soon";
    notificationType.current = "info";
    setShowNotification(true);

    const signedPDFFile = await pdfDoc.save();
    downloadDocument(
      signedPDFFile,
      "PdfPagination.pdf",
      "application/pdf",
      aRef
    );
    setDisableDownloadButton(false);
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
  };

  return (
    <>
      <PdfPaginationStyle>
        {order !== null && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            order={order}
            hasCoverPage={hasCoverPage}
            position={position}
            marginType={marginType}
            lowPage={lowPage}
            highPage={highPage}
          />
        )}
      </PdfPaginationStyle>
      <RightSection
        pageMode={pageMode}
        changeMode={setPageMode}
        coverPage={hasCoverPage}
        setCoverPage={setHasCoverpage}
        position={position}
        setPosition={setPosition}
        marginType={marginType}
        setMarginType={setMarginType}
        lowPage={lowPage}
        setLowPage={setLowPage}
        highPage={highPage}
        setHighPage={setHighPage}
        pageCount={pdfPages.current.length}
        hasCoverPage={hasCoverPage}
      />
      <DownloadButton
        onDownloadClick={downloadHandler}
        text="Continue"
        buttonPosition="dynamic_bottom"
      />
      <a ref={aRef} />
    </>
  );
};

export default PdfPagination;

const PdfPaginationStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
