import styled from "styled-components";
import { SortableContainer } from "react-sortable-hoc";
import SortableHocElememt from "../../common/SortableHocElement";
import { IconButton, Typography } from "@mui/material";
import PanToolIcon from "@mui/icons-material/PanTool";
import CloseIcon from "@mui/icons-material/Close";

const FileListViewer = SortableContainer(({ pdfNames, pdfOrder, onDelete }) => {
  return (
    <FileListViewerStyle>
      {pdfOrder.map((order, index) => (
        <SortableHocElememt key={order} index={index}>
          <NameCard>
            <PanToolIcon
              size="small"
              sx={{
                width: "20px",
              }}
            />
            <Typography
              variant="p"
              textAlign="center"
              sx={{
                fontSize: ".8rem",
                fontWeight: "300",
                overflow: "hidden",
              }}
            >
              {pdfNames[order]}
            </Typography>
            <IconButton
              className="icon-btn"
              disableFocusRipple
              disableRipple
              size="small"
              sx={{
                position: "absolute",
                right: "30px",
                display: "none",
              }}
              onClick={() => onDelete(order, index)}
            >
              <CloseIcon sx={{ color: "#FF6429" }} />
            </IconButton>
          </NameCard>
        </SortableHocElememt>
      ))}
    </FileListViewerStyle>
  );
});

export default FileListViewer;

const FileListViewerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const NameCard = styled.div`
  width: 90%;
  height: 60px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 30px;
  background: #e1ecf5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: grabbing;

  &:hover .icon-btn {
    display: block;
  }
  &:hover {
    color: #ff6429;
  }
`;
