import { Box } from "@mui/material";
import ToolInfos from "../Tools/ToolInfos";
import Tools from "./Tools";
const Home = () => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignContent: "center",
        gap: "25px",
      }}
    >
      {[...Object.keys(ToolInfos)].map((key, index) => (
        <Tools
          key={index}
          icon={ToolInfos[key].icon}
          title={ToolInfos[key].title}
          description={ToolInfos[key].description}
          link={ToolInfos[key].link}
        />
      ))}
    </Box>
  );
};

export default Home;
