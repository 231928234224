import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import CustomRangeViewer from "./CustomRangeViewer";
import FixedRangeViewer from "./FixedRangeViewer";
import SelectPageViewer from "./SelectPageViewer";
import { ReactComponent as SplitByRangeIcon } from "../../../assets/images/splitByRange.svg";
import { ReactComponent as ExtractPages } from "../../../assets/images/extractPages.svg";
import { ReactComponent as SelectedIcon } from "../../../assets/images/selectedIcon.svg";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const RightSection = (props) => {
  const [splitPdfOpen, setSplitPdfOpen] = useState(false);

  const handleDrawerToggle = () => {
    setSplitPdfOpen(!splitPdfOpen);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>
      <RightSectionStyle right={splitPdfOpen}>
        <Box
          sx={{
            height: "25px",
            width: "100%",
            display: "none",
            alignItems: "flex-start",
            transition: "0.3s ease-in-out",
            "@media(max-width: 900px)": { display: "inline-block" },
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon
            sx={{
              width: "25px",
              p: "8px",
              ml: 1,
              mt: 1,
              cursor: "pointer",
              borderRadius: "50%",
              "&:hover": {
                background: "#dedad9",
              },
            }}
          />
        </Box>
        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          Split PDF
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />
        <SplitTypeStyle>
          <SplitButtonStyle>
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              onClick={props.splitByRangehandler}
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                backgroundColor: "#E1ECF5",
                borderRadius: "8px",
                boxShadow: `${
                  props.selectRange ? "9px 10px 12px -4px #BFD5E9" : "none"
                }`,

                "&:hover": {
                  backgroundColor: "#E1ECF5",
                  boxShadow: `${
                    props.selectRange ? "9px 10px 12px -4px #BFD5E9" : "none"
                  }`,
                },
                zIndex: `${props.selectRange ? "10" : "0"}`,
              }}
            >
              <SplitByRangeIcon
                fill={`${props.selectRange ? "#FF6429" : "#818181"}`}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  width: { xs: "20px", lg: "25px" },
                }}
              >
                <SelectedIcon
                  style={{
                    width: "100%",
                    height: "100%",
                    display: `${props.selectRange ? "block" : "none"}`,
                  }}
                  fill="url(#paint0_linear_428_157)"
                />
              </Box>
            </Button>
          </SplitButtonStyle>
          <SplitButtonStyle>
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              onClick={props.splitByPageHandler}
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                backgroundColor: "#E1ECF5",
                borderRadius: "8px",
                boxShadow: `${
                  props.selectPage ? "-9px 10px 12px -4px #BFD5E9" : "none"
                }`,

                "&:hover": {
                  backgroundColor: "#E1ECF5",
                  boxShadow: `${
                    props.selectPage ? "-9px 10px 12px -4px #BFD5E9" : "none"
                  }`,
                },
              }}
            >
              <ExtractPages
                fill={`${props.selectPage ? "#FF6429" : "#818181"}`}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  width: { xs: "20px", lg: "25px" },
                }}
              >
                <SelectedIcon
                  style={{
                    width: "100%",
                    height: "100%",
                    display: `${props.selectPage ? "block" : "none"}`,
                  }}
                  fill="red"
                />
              </Box>
            </Button>
          </SplitButtonStyle>
        </SplitTypeStyle>

        <ModeStyle>
          <Typography
            variant="p"
            sx={{
              fontSize: "16px",
              lineHeight: "19px",
              color: "#FF6429",
            }}
          >
            {props.selectRange ? "Range mode" : "Extract mode "}
          </Typography>
          {props.selectRange && (
            <ModeRowStyle>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={props.customRangeHandler}
                sx={{
                  width: { xs: "125px", lg: "130px", xl: "140px" },
                  height: "40px",
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    props.selectCustomRange
                      ? "0px 10px 7px -3px #BFD5E9"
                      : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      props.selectCustomRange
                        ? "0px 10px 7px -3px #BFD5E9"
                        : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${props.selectCustomRange ? "#FF6429" : "#818181"}`,
                  }}
                >
                  Custom ranges
                </Typography>
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={props.fixedRangeHandler}
                sx={{
                  width: { xs: "125px", lg: "130px", xl: "140px" },
                  height: "40px",
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    props.selectFixedRange
                      ? "0px 10px 7px -3px #BFD5E9"
                      : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      props.selectFixedRange
                        ? "0px 10px 7px -3px #BFD5E9"
                        : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${props.selectFixedRange ? "#FF6429" : "#818181"}`,
                  }}
                >
                  Fixed ranges
                </Typography>
              </Button>
            </ModeRowStyle>
          )}
          {props.selectPage && (
            <ModeRowStyle>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={props.allPageHandler}
                sx={{
                  width: { xs: "130px", lg: "130px", xl: "140px" },
                  height: "40px",
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    props.selectAllPage ? "0px 10px 7px -3px #BFD5E9" : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      props.selectAllPage ? "0px 10px 7px -3px #BFD5E9" : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${props.selectAllPage ? "#FF6429" : "#818181"}`,
                  }}
                >
                  Extract all pages
                </Typography>
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={props.customPageHandler}
                sx={{
                  width: { xs: "125px", lg: "130px", xl: "140px" },
                  height: "40px",
                  backgroundColor: "#e1ecf5",
                  boxShadow: `${
                    props.selectCustomPage
                      ? "0px 10px 7px -3px #BFD5E9"
                      : "none"
                  }`,
                  "&:hover": {
                    boxShadow: `${
                      props.selectCustomPage
                        ? "0px 10px 7px -3px #BFD5E9"
                        : "none"
                    }`,
                  },
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  lineHeight="16px"
                  textTransform="none"
                  sx={{
                    color: `${props.selectCustomPage ? "#FF6429" : "#818181"}`,
                  }}
                >
                  Select pages
                </Typography>
              </Button>
            </ModeRowStyle>
          )}
        </ModeStyle>

        <CustomRangeViewer
          open={props.selectRange && props.selectCustomRange}
          list={props.customRangeList}
          updateList={props.setCustomRangeListHandler}
          maxPage={props.numPages}
        />
        <FixedRangeViewer
          open={props.selectRange && props.selectFixedRange}
          list={props.fixedRangeList}
          updateList={props.setFixedRangeListHandler}
          maxPage={props.numPages}
        />
        {props.selectPage && props.selectAllPage && (
          <>
            <Divider
              sx={{
                width: "100%",
                border: "1px solid #87C7FF",
                marginTop: "30px",
              }}
            />
            <Box
              sx={{
                width: "80%",
                marginTop: "30px",
                backgroundColor: "#E1ECF5",
                padding: "15px",
              }}
            >
              <Typography
                variant="p"
                fontSize="14px"
                lineHeight="16px"
                sx={{
                  color: "#4A4A4A",
                  textAlign: "center !important",
                  textAlignLast: "center",
                }}
              >
                {`Every selected page of this PDF file will be converted in one
              separated PDF file. ${props.numPages} PDF will be created.`}
              </Typography>
            </Box>
          </>
        )}
        <SelectPageViewer
          open={props.selectPage && props.selectCustomPage}
          list={props.extractPageList}
          updateList={props.setExtractPageListHandler}
          maxPage={props.numPages}
        />
      </RightSectionStyle>
    </>
  );
};

export default RightSection;

const RightSectionStyle = styled.div`
  height: calc(100% - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;

  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }

  padding: 0px 0px 120px 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const SplitTypeStyle = styled.div`
  box-sizing: border-box;
  padding: 10px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SplitButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 140px;
  @media screen and (max-width: 1535px) {
    width: 145px;
    height: 135px;
  }
  @media screen and (max-width: 1199px) {
    width: 130px;
    height: 120px;
  }
  @media screen and (max-width: 899px) {
    width: 130px;
    height: 120px;
  }
`;

const ModeStyle = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
`;
const ModeRowStyle = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;
