import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Popover } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import ToolInfos from "../Tools/ToolInfos";
import { AuthContext } from "../../hooks/useAuth";
import { useContext } from "react";

const pages = [
  {
    name: "Merge PDF",
    route: "/tools/merge-pdf",
  },
  {
    name: "Split PDF",
    route: "/tools/split-pdf",
  },
  {
    name: "Sign PDF",
    route: "/tools/sign-pdf",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "#EBF3FA",
        color: "#4A4A4A",
        height: { xs: "50px", md: "70px" },
      }}
    >
      <Box sx={{ width: "90%", mx: "auto" }}>
        <Toolbar
          disableGutters
          sx={{
            height: { xs: "50px", md: "70px" },
            minHeight: "50px !important",
          }}
        >
          <Box>
            <Link to="/">
              <Box
                component="img"
                src="/assets/images/logo.svg"
                alt="LETS EDIT PDF"
                sx={{
                  width: "70px",
                  mt: "5px",
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  cursor: "pointer",
                }}
              />
            </Link>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon
                sx={{
                  fontSize: { xs: "large" },
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {[...Object.keys(ToolInfos)].map((key, index) => (
                <NavLink
                  key={index}
                  to={ToolInfos[key].link}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: `rgba(255, 100, 41, 1)`,
                        }
                      : {
                          color: "#4A4A4A",
                        }
                  }
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      color="inherit"
                      textAlign="center"
                      sx={{
                        margin: 0,
                        "&:hover": {
                          background: "hsl(0deg 0% 73%)",
                          color: "#FB5618",
                        },
                      }}
                    >
                      {ToolInfos[key].title}
                    </Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
          <Box noWrap sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Link to="/">
              <Box
                component="img"
                src="/assets/images/logo.svg"
                alt="LETS EDIT PDF"
                sx={{
                  width: { xs: "45px", md: "60px" },
                  mt: "5px",
                  display: { xs: "block", md: "none" },
                  cursor: "pointer",
                }}
              />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: 6,
              alignItems: "center",
            }}
          >
            {pages.map((page) => (
              <NavLink
                key={page.name}
                to={page.route}
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: `rgba(255, 100, 41, 1)`,
                      }
                    : {
                        color: "#4A4A4A",
                      }
                }
              >
                <Button
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "inherit",
                    display: "block",
                    margin: 0,
                    "&:hover": {
                      background: "hsl(0deg 0% 73%)",
                      color: "#FB5618",
                    },
                  }}
                >
                  {page.name}
                </Button>
              </NavLink>
            ))}
            <Button
              disableElevation
              disableRipple
              disableTouchRipple
              disableFocusRipple
              sx={{
                my: 2,
                color: "#4A4A4A",
                "&:hover": {
                  background: "hsl(0deg 0% 73%)",
                  color: "#FB5618",
                },
              }}
              endIcon={<ArrowDropDownIcon />}
              aria-describedby={id}
              onClick={handleClick}
            >
              All Items
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ ".MuiPaper-root": { px: 2, py: 1 } }}
            >
              {[...Object.keys(ToolInfos)].map((key, index) => (
                <NavLink
                  key={ToolInfos[key].title}
                  to={ToolInfos[key].link}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: `rgba(255, 100, 41, 1)`,
                        }
                      : {
                          color: "#4A4A4A",
                        }
                  }
                >
                  <Box>
                    <Button
                      disableElevation
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      sx={{
                        // display: "block",
                        color: "inherit",
                        p: "6px 12px",
                        margin: 0,
                        "&:hover": {
                          background: "hsl(0deg 0% 83%)",
                          color: "#FB5618",
                        },
                      }}
                      startIcon={
                        <Box
                          sx={{
                            height: "15px",
                            width: "15px",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          {ToolInfos[key].icon}
                        </Box>
                      }
                    >
                      {ToolInfos[key].title}
                    </Button>
                  </Box>
                </NavLink>
              ))}
            </Popover>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {auth.hasSession ? (
              <LoginButton variant="outlined" onClick={() => auth.signout({})}>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", md: "1rem" },
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Log out
                </Typography>
              </LoginButton>
            ) : (
              <>
                <Link to="/account/login">
                  <LoginButton variant="outlined">
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", md: "1rem" },
                        textTransform: "none",
                        fontWeight: "500",
                      }}
                    >
                      Login
                    </Typography>
                  </LoginButton>
                </Link>
                <Link to="/account/signup">
                  <LoginButton variant="outlined" sx={{ ml: 2 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", md: "1rem" },
                        textTransform: "none",
                        fontWeight: "500",
                      }}
                    >
                      Sign Up
                    </Typography>
                  </LoginButton>
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default ResponsiveAppBar;

const LoginButton = styled(Button)({
  padding: "2px 8px",
  background: "linear-gradient(90.07deg, #ee0979 5.77%, #ff6a00 106.28%)",
  backgroundClip: "text",
  color: "transparent",
  border: "1px solid #ee0979",
  "&:hover": {
    border: "1px solid #ee0979",
  },
});
