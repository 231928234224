import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import DownloadButton from "../../DownloadSection/DownloadButton";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import Container from "./Container";
import RightSection from "./RightSection";
import arrayMove from "array-move";
import { NotificationContext } from "../../../context/NotificationContext";

let aRef;

const OrganizePdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //2d array
  const [order, setOrder] = useState([]); //array of tuple
  const [rotations, setRotations] = useState([]); //array of array
  const [pdfOrder, setPdfOrder] = useState([]);
  aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const updateStates = async () => {
      const newFiles = [];
      const newNames = [];
      const newPages = [];
      const newOrder = [];
      const newRotations = [];
      const newPdfOrder = [];
      for (const file of files) {
        try {
          const pdfName = await getFileName(file);
          const doc = await PdfjsDocument(file);
          const pages = await pdfjsExtractPages(
            doc,
            [...Array(doc.numPages).keys()].map((e) => e + 1)
          );
          newFiles.push(file);
          newNames.push(pdfName);
          newPages.push(pages);

          newPdfOrder.push(uploadedFiles.current.length + newFiles.length - 1);
          newRotations.push([...Array(doc.numPages).keys()].map((e) => 0));
          newOrder.push(
            ...[...Array(doc.numPages).keys()].map((index) => [
              uploadedFiles.current.length + newFiles.length - 1,
              index,
            ])
          );
        } catch (err) {}
      }
      uploadedFiles.current.push(...newFiles);
      pdfNames.current.push(...newNames);
      pdfPages.current.push(...newPages);
      setRotations((prev) => [...prev, ...newRotations]);
      setPdfOrder((prev) => [...prev, ...newPdfOrder]);
      setOrder((prev) => [...prev, ...newOrder]);
    };
    updateStates();
  }, [files]);

  const resetHandler = async () => {
    const newOrder = [];
    const newRotations = rotations;
    const newPdfOrder = [];
    for (let index = 0; index < uploadedFiles.current.length; index++) {
      const file = uploadedFiles.current[index];
      if (file !== null) {
        const doc = await PdfjsDocument(file);
        const pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );
        newOrder.push(
          ...[...Array(doc.numPages).keys()].map((pageIndex) => [
            index,
            pageIndex,
          ])
        );
        rotations.splice(
          index,
          1,
          [...Array(doc.numPages).keys()].map((e) => 0)
        );
        newPdfOrder.push(index);
        pdfPages.current[index] = pages;
      }
    }
    setPdfOrder(newPdfOrder);
    setRotations(newRotations);
    setOrder(newOrder);
  };

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder((oldOrder) => arrayMove(oldOrder, oldIndex, newIndex));
  };

  const pdfSortEndHandler = ({ oldIndex, newIndex }) => {
    setPdfOrder((oldOrder) => arrayMove(oldOrder, oldIndex, newIndex));
    setOrder((prev) => {
      const old = [...prev];
      const newPdfOrder = [];
      for (const order of arrayMove(pdfOrder, oldIndex, newIndex)) {
        const target = old.filter((e) => e[0] === order);
        newPdfOrder.push(...target);
      }
      return newPdfOrder;
    });
  };

  const deletePageHandler = (pdfIndex, pageIndex, orderIndex) => {
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
    const prev = rotations[pdfIndex];
    const count = prev.filter((rot) => rot !== null).length;
    setRotations((prev) => {
      const oldRotations = [...prev];
      const target = oldRotations[pdfIndex];
      target.splice(pageIndex, 1, null);
      oldRotations.splice(pdfIndex, 1, target);
      if (count === 1) oldRotations.splice(pdfIndex, 1, null);
      else oldRotations.splice(pdfIndex, 1, target);
      return oldRotations;
    });

    if (count === 1) {
      setPdfOrder((prev) => prev.filter((order) => order !== pdfIndex));
      uploadedFiles.current.splice(pdfIndex, 1, null);
      pdfNames.current.splice(pdfIndex, 1, null);
      pdfPages.current.splice(pdfIndex, 1, null);
    }
  };
  const deletePdfHandler = (pdfIndex, orderIndex) => {
    setOrder((prev) => prev.filter((order) => order[0] !== pdfIndex));
    setPdfOrder((prev) => prev.filter((order) => order !== pdfIndex));
    setRotations((prev) => {
      const old = [...prev];
      old[pdfIndex] = null;
      return old;
    });
    uploadedFiles.current.splice(pdfIndex, 1, null);
    pdfNames.current.splice(pdfIndex, 1, null);
    pdfPages.current.splice(pdfIndex, 1, null);
  };

  const rotateHandler = (pdfIndex, pageIndex, delta) => {
    setRotations((prev) => {
      const rot = [...prev];
      const target = rot[pdfIndex];
      const newVal = (((target[pageIndex] + delta) % 4) + 4) % 4;
      target.splice(pageIndex, 1, newVal);
      rot.splice(pdfIndex, 1, target);
      return rot;
    });
  };

  const onDownload = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Organizing files in progress";
    notificationType.current = "info";
    setShowNotification(true);

    //eslint-disable-next-line no-undef
    const { PDFDocument, degrees } = PDFLib;
    const pdfDoc = await PDFDocument.create();
    const pageList = [
      ...[...Array(pdfNames.current.length).keys()].map((e) => []),
    ];

    for (let index = 0; index < pdfNames.current.length; index++) {
      if (pdfNames.current[index]) {
        const doc = await PdfLibDocument(uploadedFiles.current[index]);
        pageList[index].push(
          ...(await pdfDoc.copyPages(doc, doc.getPageIndices()))
        );
      }
    }
    for (const ord of order) {
      const page = pageList[ord[0]][ord[1]];
      const angle = rotations[ord[0]][ord[1]];
      page.setRotation(degrees(parseInt(angle) * 90));
      pdfDoc.addPage(page);
    }
    const pdfData = await pdfDoc.save();

    setShowNotification(false);
    notificationMessage.current = "file will be downloaded soon";
    notificationType.current = "info";
    setShowNotification(true);

    downloadDocument(pdfData, "organizedPDF.pdf", "application/pdf", aRef);
    setDisableDownloadButton(false);
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
  };

  return (
    <>
      <OrganizePdfStyle>
        {order?.length > 0 && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            rotations={rotations}
            order={order}
            onSortEnd={sortEndHandler}
            onDelete={deletePageHandler}
            onRotate={rotateHandler}
            axis={"xy"}
            distance={5}
          />
        )}
      </OrganizePdfStyle>
      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />

      <RightSection
        pdfNames={pdfNames.current}
        pdfOrder={pdfOrder}
        onPdfSortEnd={pdfSortEndHandler}
        onReset={resetHandler}
        onDelete={deletePdfHandler}
      />
      <DownloadButton
        onDownloadClick={onDownload}
        text="Organize PDF"
        buttonPosition="bottom"
      />
      <a ref={aRef} />
    </>
  );
};

export default OrganizePdf;

const OrganizePdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
