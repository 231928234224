import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <Box
        sx={{
          height: "40px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },

          justifyContent: { xs: "space-evenly", sm: "space-between" },
          alignItems: "center",
          width: { xs: "100%", sm: "80%" },
          mx: "auto",
          color: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            fontSize: { xs: "0.65rem", sm: "0.875rem" },
          }}
        >
          <Link to="/privacy-policy">
            <Typography
              variant="body2"
              fontSize="inherit"
              sx={{ cursor: "pointer", color: "white" }}
            >
              Privacy Policy
            </Typography>
          </Link>
          <a href="https://inverseai.com/" target="_blank" rel="noreferrer">
            <Typography
              variant="body2"
              fontSize="inherit"
              sx={{ cursor: "pointer", color: "white" }}
            >
              About Us
            </Typography>
          </a>
        </Box>
        <Typography
          variant="body2"
          fontSize={{ xs: "0.55rem", sm: "0.875rem" }}
        >
          © 2021, developed by Inverse.AI
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
