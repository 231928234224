import {
  Button,
  ClickAwayListener,
  Divider,
  Popper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddSignModal from "../AddSignModal";
import SignList from "../SignList";
import { ReactComponent as SignIcon } from "../../../../assets/images/sign.svg";
import CreatePortal from "../../../UI/CreatePortal";

const SignButton = ({
  activeSignType,
  onChangeActiveSignType,
  sign,
  onSignUpload,
  onDropSignImage,
  onHideSign,
}) => {
  const [buttonEl, setButtonEl] = useState(null);
  const [showAddSign, setShowAddSign] = useState(null);
  const open = Boolean(buttonEl);
  const handleClick = (event) => {
    setButtonEl(event.currentTarget);
  };
  const handleClose = () => {
    setButtonEl(null);
  };
  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <div>
        <Button
          variant="outlined"
          disableElevation
          disableFocusRipple
          disableRipple
          sx={{
            height: "35px",
            width: "90px",
            borderRadius: "8px",
            borderColor: activeSignType === 1 ? "#FF6429" : "#818181",
            color: activeSignType === 1 ? "#FF6429" : "#818181",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
              borderColor: activeSignType === 1 ? "#FF6429" : "#818181",
            },
          }}
          onClick={(event) => {
            if (activeSignType === 0) onChangeActiveSignType(1);
            handleClick(event);
          }}
        >
          <SignIcon fill={`${activeSignType === 1 ? "#FF6429" : "#818181"}`} />
        </Button>
        <Popper
          id="basic-menu"
          anchorEl={buttonEl}
          open={open}
          disablePortal
          style={{
            background: "white",
            width: "220px",
            maxHeight: "180px",
            boxShadow: "0px 10px 20px 4px rgba(0,0,0,0.48)",
            padding: "0px 0px 10px 0px",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "5px",
            borderRadius: "6px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <SignList
            sign={sign}
            onDropSignImage={onDropSignImage}
            onHideSign={onHideSign}
          />
          <Divider sx={{ height: "1px", width: "100%" }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setShowAddSign(true);
            }}
          >
            Add Sign
          </Button>
          <Typography variant="p" fontSize="12px" color="#4d4f4d">
            Drag a sign and place it on pdf page
          </Typography>
        </Popper>
        <CreatePortal>
          {showAddSign && (
            <AddSignModal
              onSignUpload={onSignUpload}
              onClose={() => {
                setShowAddSign(false);
              }}
            />
          )}
        </CreatePortal>
      </div>
    </ClickAwayListener>
  );
};

export default SignButton;
