import styled from "styled-components";
import JSZip from "jszip";
import { useEffect, useRef, useState } from "react";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
} from "../../../helpers/utils";
import Container from "./Container";
import RightSection from "./RightSection";
import DownloadButton from "../../DownloadSection/DownloadButton";

import { useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";

const PdfToImage = ({ files, setUploadedFiles }) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const [order, setOrder] = useState(null); //number
  const [imageType, setImageType] = useState("JPEG");
  const [imageQuality, setImageQuality] = useState(0.75);
  const hiddenCanvasRef = useRef(null);
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      try {
        const pdfName = await getFileName(files[0]);
        const doc = await PdfjsDocument(files[0]);
        const pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );

        uploadedFiles.current.push(files[0]);
        pdfNames.current.push(pdfName);
        pdfPages.current.push(...pages);
        setOrder(uploadedFiles.current.length - 1);
      } catch (err) {
        setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
      }
    };
    initializeStates();
  }, [files]);

  const handleChangeImageType = (value) => {
    setImageType(value);
  };
  const handleChangeImageQuality = (value) => {
    setImageQuality(value);
  };

  const onDownloadHandler = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Image creation is in progress";
    notificationType.current = "info";
    setShowNotification(true);
    const zip = new JSZip();
    const img = zip.folder("images");
    const promises = [];
    const format = imageType === "JPEG" ? "jpg" : "png";
    for (let i = 0; i < pdfPages.current.length; i++) {
      const page = pdfPages.current[i];
      const canvas = hiddenCanvasRef.current;
      const ctx = canvas.getContext("2d");

      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      await page.render(renderContext).then(() => {
        const promise = new Promise((resolve, reject) => {
          canvas.toBlob(
            function (blob) {
              resolve(
                img.file(`image-${i + 1}.${format}`, blob, {
                  base64: true,
                })
              );
            },
            `image/${imageType}`,
            imageQuality
          );
        });
        promises.push(promise);
      });
    }
    Promise.all(promises).then((imgs) => {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        setShowNotification(false);
        notificationMessage.current = "file will be downloaded soon";
        notificationType.current = "info";
        setShowNotification(true);
        downloadDocument(content, "Pdf-to-Image.zip", "application/zip", aRef);
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = "downloaded!";
        notificationType.current = "success";
        setShowNotification(true);
      });
    });
  };

  return (
    <>
      <PdfToImageStyle>
        {order !== null && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            order={order}
          />
        )}
      </PdfToImageStyle>
      <RightSection
        imageType={imageType}
        changeImageType={handleChangeImageType}
        imageQuality={imageQuality}
        changeImageQuality={handleChangeImageQuality}
      />
      <DownloadButton
        onDownloadClick={onDownloadHandler}
        text={`Convert`}
        buttonPosition="dynamic-bottom"
      />
      <canvas style={{ display: "none" }} ref={hiddenCanvasRef} />
      <a ref={aRef} />
    </>
  );
};

export default PdfToImage;

const PdfToImageStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
