import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";

import { Button, Typography } from "@mui/material";
const DownloadButton = ({ onDownloadClick, text, buttonPosition, formik }) => {
  const { notificationContext } = useContext(NotificationContext);

  const { disableDownloadButton } = notificationContext;

  const isDisabled =
    (formik && (!formik?.isValid || !formik?.dirty)) || disableDownloadButton
      ? true
      : false;

  return (
    <Button
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      sx={{
        width: {
          xs: "153px",
          sm: "180px",
          md: "180px",
          lg: "193px",
          xl: "210px",
        },
        height: { xs: "50px", sm: "55px", md: "55px", lg: "60px", xl: "65px" },

        position: "fixed",
        top: { md: buttonPosition === "top" ? "120px" : "auto" },

        bottom: "10px",
        right:
          buttonPosition === "fixed-bottom"
            ? {
                xs: `calc(50% - 75px)`,
                sm: `calc(50% - 90px)`,
                md: `calc(50% - 90px)`,
                lg: `calc(50% - 95px)`,
                xl: `calc(50% - 105px)`,
              }
            : {
                xs: `calc(50% - 75px)`,
                sm: `calc(50% - 90px)`,
                md: "55px",
                lg: "80px",
                xl: "95px",
              },
        // right: { xs: `calc(50% - 100px)`, md: "40px", lg: "50px", xl: "75px" },
        "&:hover": {
          boxShadow: "0 0 8px 8px rgb(0 0 0 / 20%)",
          // background: "rgb(68, 65, 65)",
        },
        "&:disabled": {
          cursor: "not-allowed !important",
          // background: "rgb(68, 65, 65)" ,
        },
        boxShadow: "0 0 8px 2px rgb(0 0 0 / 20%)",
        background: "linear-gradient(90.07deg, #EE0979 5.77%, #FF6A00 106.28%)",
        borderRadius: "16px",
        textTransform: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
        zIndex: 10,
      }}
      onClick={!isDisabled ? onDownloadClick : undefined}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: {
            xs: "20px",
            sm: "24px",
            md: "24px",
            lg: "26px",
            xl: "27px",
          },
          lineHeight: "35px",
          textAlign: "center",
          color: "#EBF3FA",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default DownloadButton;
