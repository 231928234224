import styled from "styled-components";

const Modal = ({ open, scroll, children }) => {
  if (!open) return null;
  return (
    <>
      <Overlay />
      <ModalContainer scroll={scroll}>{children}</ModalContainer>
    </>
  );
};

export default Modal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow-y: ${(props) => props.scroll && "scroll"};
  z-index: 1000;
  border-radius: 5px;

  &::-webkit-scrollbar {
    width: "0.5vw";
  }
  &::-webkit-scrollbar-thumb {
    background: #099fff;
  }
`;
