import { Box } from "@mui/material";
const Card = ({ children }) => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        padding: "20px",
        height: "200px",
        width: "270px",
        backgroundColor: "#ebf3fa",
        borderRadius: "24px",
        "&:hover": {
          boxShadow: "0px 33px 56px -43px #93b8d9",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
