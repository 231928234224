import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import ImageIcon from "@mui/icons-material/Image";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import { Box } from "@mui/system";
import { createImageBuffer } from "../../../helpers/utils";
import RenderImage from "../../UI/Canvas/RenderImage";
import { ReactComponent as SelectedIcon } from "../../../assets/images/selectedIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { NotificationContext } from "../../../context/NotificationContext";

const HiddenInput = styled("input")({
  display: "none",
});

const RightSection = ({
  mode,
  modeHandler,
  text,
  textHandler,
  image,
  imageHandler,
  position,
  mosaic,
  mosaicHandler,
  positionHandler,
  lowPage,
  setLowPage,
  highPage,
  setHighPage,
  pageCount,
  transparency,
  transparencyHandler,
}) => {
  const [waterMarkOpen, setWaterMarkOpen] = useState(false);

  const handleDrawerToggle = () => {
    setWaterMarkOpen(!waterMarkOpen);
  };

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>

      <RightSectionStyle right={waterMarkOpen}>
        <Box
          sx={{
            height: "25px",
            width: "25px",
            mt: 1,
            display: "none",
            cursor: "pointer",
            ml: 1,
            p: "8px",
            borderRadius: "50%",
            transition: "0.3s ease-in-out",
            "&:hover": {
              background: "#dedad9",
            },
            "@media(max-width: 900px)": { display: "inline-block" },
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </Box>
        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          Watermark PDF
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />

        <WatermarkTypeStyle>
          <WatermarkButtonStyle>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#E1ECF5",
                borderRadius: "8px",
                color: mode === 0 ? "#FF6429" : "#818181",
                boxShadow: `${
                  mode === 0 ? "9px 10px 12px -4px #BFD5E9" : "none"
                }`,

                "&:hover": {
                  backgroundColor: "#E1ECF5",
                  boxShadow: `${
                    mode === 0 ? "9px 10px 12px -4px #BFD5E9" : "none"
                  }`,
                },
                cursor: "pointer",
                zIndex: `${mode === 0 ? "10" : "0"}`,
              }}
              onClick={() => {
                modeHandler(0);
              }}
            >
              <TextFormatIcon
                sx={{
                  width: "65px",
                  height: "55px",
                }}
              />
              <Typography variant="p">Place text</Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                left: "5px",
                width: { xs: "20px", lg: "25px" },
              }}
            >
              <SelectedIcon
                style={{
                  width: "100%",
                  height: "100%",
                  display: `${mode === 0 ? "block" : "none"}`,
                }}
                fill="url(#paint0_linear_428_157)"
              />
            </Box>
          </WatermarkButtonStyle>
          <WatermarkButtonStyle>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#E1ECF5",
                borderRadius: "8px",
                color: mode === 1 ? "#FF6429" : "#818181",
                boxShadow: `${
                  mode === 1 ? "-9px 10px 12px -4px #BFD5E9" : "none"
                }`,

                "&:hover": {
                  backgroundColor: "#E1ECF5",
                  boxShadow: `${
                    mode === 1 ? "-9px 10px 12px -4px #BFD5E9" : "none"
                  }`,
                },
                cursor: "pointer",
                // zIndex: `${mode === 0 ? "10" : "0"}`,
              }}
              onClick={() => {
                modeHandler(1);
              }}
            >
              <ImageIcon
                sx={{
                  width: "55px",
                  height: "45px",
                }}
              />
              <Typography variant="p">Place image</Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                left: "5px",
                width: { xs: "20px", lg: "25px" },
              }}
            >
              <SelectedIcon
                style={{
                  width: "100%",
                  height: "100%",
                  display: `${mode === 1 ? "block" : "none"}`,
                }}
                fill="url(#paint0_linear_428_157)"
              />
            </Box>
          </WatermarkButtonStyle>
        </WatermarkTypeStyle>
        <OptionWrapperStyle>
          {mode === 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "5px",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#e5322d",
                  letterSpacing: ".3px",
                  lineHeight: "24px",
                }}
              >
                TEXT
              </Typography>
              <TextField
                id="text-field"
                variant="outlined"
                size="small"
                placeholder="Type text here"
                value={text}
                onChange={(event) => textHandler(event.target.value)}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    boxShadow: "inset 1px 1px 3px 2px hsl(210deg 2% 49% / 20%)",
                    fontSize: "15px",
                    fontWeight: "300",
                    color: "#333",
                    "&:hover fieldset": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                      borderWidth: "2px",
                    },
                  },
                }}
              />
            </Box>
          )}
          {mode === 1 && (
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <label htmlFor="file-upload-watermark">
                <HiddenInput
                  accept="image/*"
                  id="file-upload-watermark"
                  type="file"
                  sx={{ visibility: "hidden" }}
                  onChange={async (event) => {
                    try {
                      const buffer = await createImageBuffer(
                        event.target.files
                      );
                      imageHandler(buffer[0]);
                    } catch (err) {
                      setShowNotification(false);
                      notificationMessage.current =
                        err?.message || "file upload failed!";
                      notificationType.current = "error";
                      setShowNotification(true);
                    }
                  }}
                />
                <Button
                  component="span"
                  disableRipple
                  startIcon={
                    image ? (
                      <RenderImage
                        image={image}
                        height="50"
                        width="80"
                        rotation={0}
                      />
                    ) : (
                      <PhotoSizeSelectActualIcon />
                    )
                  }
                  sx={{
                    height: "50px",
                    width: "200px",
                    backgroundColor: "#f6f6f8",
                    color: "black",
                    fontWeight: "400",
                    boxShadow: "0 1px 6px 0 rgb(0 0 0 / 14%)",
                    "&:hover": {
                      backgroundColor: "#e1e1e1",
                      color: "#e5322d",
                      boxShadow: "0 2px 8px 2px rgb(0 0 0 / 20%)",
                    },
                  }}
                >
                  {image ? "change image" : "add image"}
                </Button>
              </label>
            </Box>
          )}

          <SelectPositionStyle>
            <Typography
              variant="p"
              sx={{
                color: "#FF6429",
              }}
            >
              POSITION
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  height: "75px",
                  width: "75px",
                  border: "1px solid black",
                  marginTop: "5px",
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {[...Array(9).keys()].map((index) => (
                  <BoxStyle
                    key={index}
                    index={index}
                    position={position}
                    mosaic={mosaic}
                    onClick={() => {
                      positionHandler(index);
                    }}
                  />
                ))}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mosaic}
                    onChange={(event) => mosaicHandler(!!event.target.checked)}
                  />
                }
                label="mosaic"
              />
            </Box>
          </SelectPositionStyle>
          <TransparencyStyle>
            <Typography
              variant="p"
              sx={{
                color: "#FF6429",
              }}
            >
              TRANSPARENCY
            </Typography>
            <TextField
              select
              value={transparency}
              onChange={(event) =>
                transparencyHandler(parseInt(event.target.value))
              }
              sx={{
                width: "170px",
                "& .MuiInputBase-input": { padding: "6px" },
              }}
            >
              <MenuItem key={0} value={0}>
                No Transparency
              </MenuItem>
              <MenuItem key={1} value={1}>
                25%
              </MenuItem>
              <MenuItem key={2} value={2}>
                50%
              </MenuItem>
              <MenuItem key={3} value={3}>
                75%
              </MenuItem>
            </TextField>
          </TransparencyStyle>
          <PagesInputStyle>
            <Typography variant="p" sx={{ color: "#FF6429" }}>
              Pages
            </Typography>
            <InputSection>
              <Box
                sx={{
                  height: "44px",
                  width: "150px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  from page
                </Typography>
                <Input
                  type="number"
                  value={lowPage}
                  min={1}
                  max={pageCount}
                  size="small"
                  onChange={(event) => {
                    const newVal = parseInt(event.target.value);
                    if (newVal > 0 && newVal <= pageCount && newVal <= highPage)
                      setLowPage(newVal);
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  height: "44px",
                  width: "90px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  to
                </Typography>
                <Input
                  type="number"
                  value={highPage}
                  min={1}
                  max={pageCount}
                  defaultValue={1}
                  size="small"
                  onChange={(event) => {
                    const newVal = parseInt(event.target.value);
                    if (newVal > 0 && newVal <= highPage && newVal >= lowPage)
                      setHighPage(newVal);
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                ></Input>
              </Box>
            </InputSection>
          </PagesInputStyle>
        </OptionWrapperStyle>
      </RightSectionStyle>
    </>
  );
};

export default RightSection;

const RightSectionStyle = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;

  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }
`;

const WatermarkTypeStyle = styled.div`
  box-sizing: border-box;
  padding: 10px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const WatermarkButtonStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 140px;
  @media screen and (max-width: 1535px) {
    width: 145px;
    height: 135px;
  }
  @media screen and (max-width: 1199px) {
    width: 130px;
    height: 120px;
  }
  @media screen and (max-width: 899px) {
    width: 130px;
    height: 120px;
  }
`;

const OptionWrapperStyle = styled.div`
  box-sizing: border-box;
  padding: 0px 25px;
`;

const SelectPositionStyle = styled.div`
  margin-top: 20px;
`;

const BoxStyle = styled.div`
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.index < 6 ? "1px dashed #818181" : "none"};
  border-right: ${(props) =>
    props.index % 3 !== 2 ? "1px dashed #818181" : "none"};
  background-color: ${(props) =>
    props.mosaic || props.position === props.index
      ? "red"
      : "none"}; //use map here
  &:hover {
    background-color: ${(props) =>
      !props.mosaic && props.position !== props.index
        ? "rgba(0, 0, 0, 0.1)"
        : "none"};
  }
`;

const TransparencyStyle = styled.div`
  margin-top: 25px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 5px;
`;

const PagesInputStyle = styled.div`
  margin-top: 25px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 5px;
`;

const InputSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
`;
