import { Box, Divider, Input, Typography } from "@mui/material";
import styled from "styled-components";

const FixedRangeViewer = ({ open, list, updateList, maxPage }) => {
  if (!open) return null;

  const updateRangeHandler = (newVal) => {
    if (newVal > 0 && newVal <= maxPage) updateList((prev) => newVal);
  };

  return (
    <>
      <FixedRangeViewerWrapper>
        <Typography
          variant="p"
          sx={{
            fontSize: "16px",
            lineHeight: "19px",
            color: "#4A4A4A",
            marginRight: "15px",
          }}
        >
          Split in page range of
        </Typography>
        <Input
          type="number"
          value={list}
          size="small"
          onChange={(event) => {
            updateRangeHandler(parseInt(event.target.value));
          }}
          sx={{
            width: "75px",
            height: "36px",
            backgroundColor: "#E1ECF5",
            border: "1px solid #4A4A4A",
            borderRadius: "4px",
            boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
            "&:before": {
              borderBottom: "none",
            },
            "&:after": {
              borderBottom: "none",
            },

            "& .MuiInput-input": {
              textAlign: "end",
              padding: "0px",
            },

            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
          }}
        />
      </FixedRangeViewerWrapper>
      <Divider
        sx={{
          width: "100%",
          border: "1px solid #87C7FF",
        }}
      />
      <Box
        sx={{
          width: "80%",
          marginTop: "25px",
          backgroundColor: "#E1ECF5",
          padding: "15px",
        }}
      >
        <Typography
          variant="p"
          fontSize="14px"
          lineHeight="16px"
          sx={{
            color: "#4A4A4A",
          }}
        >
          {`Copy This PDF will be split in files of ${list} Pages. ${Math.ceil(
            maxPage / list
          )} PDF will be created.`}
        </Typography>
      </Box>
    </>
  );
};

export default FixedRangeViewer;

const FixedRangeViewerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;
