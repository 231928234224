import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const PasswordForm = ({ formik }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <form
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <InputSection>
        <Typography
          variant="p"
          lineHeight="19px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#4A4A4A"
          sx={{
            fontSize: { xs: "13px", lg: "16px" },
            marginBottom: "5px",
          }}
        >
          Enter a password to unlock your pdf
        </Typography>
        <Box
          sx={{
            height: "44px",
            width: "100%",
            backgroundColor: "#E1ECF5",
            border: "1px solid #4A4A4A",
            boxSizing: "border-box",
            overflow: "hidden",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={"Type a password"}
            sx={{
              width: "100%",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    onMouseDown={() => setShowPassword((prev) => !prev)}
                    sx={{
                      paddingRight: "5px",
                    }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {formik.touched.password && formik.errors.password ? (
          <ErrorMessage>{formik.errors.password}</ErrorMessage>
        ) : null}
      </InputSection>
    </form>
  );
};

export default PasswordForm;

const InputSection = styled.div`
  width: 85%;
  margin: 30px 0px 0px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: smaller;
`;
