import {
  ClickAwayListener,
  IconButton,
  Popper,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import BoldButton from "./Button/BoldButton";
import ItalicButton from "./Button/ItalicButton";
import FontSizeButton from "./Button/FontSizeButton";
import FontFamilyButton from "./Button/FontFamilyButton";
import FontColorButton from "./Button/FontColorButton";
import { Box } from "@mui/system";

const TextComponent = ({
  pageIndex,
  textIndex,
  textObject,
  onTextPositionUpdate,
  onTextUpdate,
  canvasRef,
  containerRef,
  onDeleteText,
}) => {
  const [textEl, setTextEl] = useState(null);
  const open = Boolean(textEl);
  const [drag, setDrag] = useState(false);
  const textRef = useRef(null);

  const handleOpen = (event) => {
    setTextEl(event.currentTarget);
  };

  const offsetX =
    canvasRef.current.getBoundingClientRect()["x"] -
    containerRef.current.getBoundingClientRect()["x"];
  const offsetY =
    canvasRef.current.getBoundingClientRect()["y"] -
    containerRef.current.getBoundingClientRect()["y"];

  const onUpdate = (update) => {
    onTextUpdate(pageIndex, textIndex, {
      ...textObject,
      ...update,
    });
  };

  const setDisableDragging = (val) => {
    setDrag(val);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setTextEl(null);
        setDisableDragging(false);
      }}
    >
      <Rnd
        style={{ zIndex: 1 }}
        enableResizing={{
          top: false,
          bottom: false,
          left: false,
          right: false,
        }}
        position={{
          x: textObject.offsetX + offsetX,
          y: textObject.offsetY + offsetY,
        }}
        bounds="parent"
        disableDragging={drag}
        onDragStop={(e, d) => {
          const nextX = d.x;
          const nextY = d.y;
          onTextPositionUpdate(pageIndex, textIndex, nextX, nextY, textRef);
        }}
      >
        <Box
          onClick={(event) => {
            handleOpen(event);
            setDisableDragging(true);
          }}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          {drag && (
            <TextField
              color="success"
              value={textObject.text}
              onChange={(event) => {
                onUpdate({ text: event.target.value });
              }}
              sx={{
                display: "block",
                "& .MuiInputBase-input": {
                  padding: "0px",
                  fontWeight: textObject.fontWeight,
                  fontStyle: textObject.fontStyle,
                  fontSize: textObject.fontSize,
                  fontFamily: textObject.fontFamily,
                  color: textObject.color,
                  width: `${
                    textRef.current.getBoundingClientRect().width + 30
                  }px`,
                },
              }}
            ></TextField>
          )}
          <Typography
            ref={textRef}
            variant="p"
            {...textObject}
            sx={{
              border: "1px solid transparent",
              "&:hover": {
                border: "1px dashed red",
              },
              cursor: "move",
              visibility: !drag ? "block" : "hidden",
              width: "auto",
            }}
          >
            {textObject.text}
          </Typography>
          <Popper open={open} anchorEl={textEl} placement="top-start">
            <TextButtonStyle>
              <BoldButton
                fontWeight={textObject.fontWeight}
                onUpdate={onUpdate}
              />
              <ItalicButton
                fontStyle={textObject.fontStyle}
                onUpdate={onUpdate}
              />
              <FontSizeButton
                fontSize={textObject.fontSize}
                onUpdate={onUpdate}
              />
              <FontFamilyButton
                fontFamily={textObject.fontFamily}
                onUpdate={onUpdate}
              />
              <FontColorButton color={textObject.color} onUpdate={onUpdate} />
              <Button
                variant="outlined"
                sx={{
                  padding: "3px",
                  minWidth: "auto",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#d9f0fa",
                  },
                }}
                onClick={() => onDeleteText(pageIndex, textIndex)}
              >
                <IconButton sx={{ padding: 0, minWidth: "auto" }}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </Button>
            </TextButtonStyle>
          </Popper>
        </Box>
      </Rnd>
    </ClickAwayListener>
  );
};

export default TextComponent;

const TextButtonStyle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;
