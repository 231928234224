import { SortableContainer } from "react-sortable-hoc";
import styled from "styled-components";
import SortableHocElememt from "../../common/SortableHocElement";
import PdfCard from "../../UI/Card/PdfCard";
import PdfViewer from "../../common/PdfViewer";

const Container = SortableContainer(
  ({ pdfNames, pages, rotations, order, onDelete, onRotate }) => {
    return (
      <ContainerWrapperStyle>
        {order.map((tuple, pdfIndex) => (
          <SortableHocElememt key={tuple} index={pdfIndex}>
            <PdfCard height="300px" width="250px" cursor="move">
              <PdfViewer
                pdfIndex={tuple[0]}
                pageIndex={tuple[1]}
                orderIndex={pdfIndex}
                page={pages[tuple[0]][tuple[1]]}
                pageInfo={pdfNames[tuple[0]]}
                canvasHeight="230"
                canvasWidth="200"
                rotation={rotations[tuple[0]][tuple[1]]}
                rotateLeft
                rotateRight
                rotateHandler={onRotate}
                deleteHandler={onDelete}
              />
            </PdfCard>
          </SortableHocElememt>
        ))}
      </ContainerWrapperStyle>
    );
  }
);

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
