import classes from "./Form.module.css";
import useForm from "../../../hooks/useForm";
import { useContext } from "react";
import { AuthContext } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    value: enteredEmail,
    isValid: isEmailValid,
    hasError: hasInputEmailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useForm((value) => value.includes("@"));

  const {
    value: enteredPassword,
    isValid: isPasswordValid,
    hasError: hasInputPasswordError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordReset,
  } = useForm((value) => value.trim().length >= 1);

  // eslint-disable-next-line no-unused-vars
  const emailInputClasses = !hasInputEmailError
    ? classes.formEmailInput
    : `${classes.formEmailInput} ${classes.error}`;
  // eslint-disable-next-line no-unused-vars
  const passwordInputClasses = !hasInputPasswordError
    ? classes.formPasswordInput
    : `${classes.formPasswordInput} ${classes.error}`;

  let formIsValid = false;
  if (isEmailValid && isPasswordValid) formIsValid = true;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    // console.log(enteredEmail, enteredPassword);

    fetch(`${process.env.REACT_APP_REST_API_ENDPOINT}/dj-rest-auth/login/`, {
      method: "POST",
      body: JSON.stringify({
        email: enteredEmail,
        password: enteredPassword,
        // username: "a",
        // password: "a",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log("json", json);
        if (json.key) {
          // console.log("json.key", json.key);
          fetch(
            `${process.env.REACT_APP_REST_API_ENDPOINT}/dj-rest-auth/user`,
            {
              method: "GET",
              headers: {
                Authorization: `Token ${json.key}`,
              },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              // console.log(response);
              if (response.username) {
                emailReset();
                passwordReset();

                auth.signin(response.username, () => {
                  navigate(-1);
                });
              } else {
                // console.log("account not found");
              }
            });
        } else {
          // console.log("invalid email");
        }
      });
  };
  return (
    <form className={classes.form} onSubmit={formSubmitHandler}>
      <div className={emailInputClasses}>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          placeholder="mail@example.com"
          value={enteredEmail}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
        {hasInputEmailError && (
          <div className={classes.emailErrorText}>Enter valid email</div>
        )}
      </div>
      <div className={passwordInputClasses}>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Min. 8 charecter"
          value={enteredPassword}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
        ></input>
        {hasInputPasswordError && (
          <div className={classes.passwordErrorText}>
            password must be 8 character long
          </div>
        )}
      </div>
      <div className={classes.formFooter}>
        <div className={classes.checkbox}>
          <input type="checkbox" id="rememberMe"></input>
          <p>Remember me</p>
        </div>
        <div className={classes.forgotPassword}>
          <p>Forgot password</p>
        </div>
      </div>
      <div className={classes.formSubmit}>
        <button
          disabled={!formIsValid}
          style={{
            cursor: formIsValid ? "pointer" : "default",
          }}
        >
          <p>Submit</p>
        </button>
      </div>
    </form>
  );
};

export default Form;
