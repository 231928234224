import { useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const RenderPdf = ({
  page,
  height,
  width,
  keepOriginalSize,
  rotation,
  canvasRef,
  pageNumberPosition,
}) => {
  const [dimension, setDimension] = useState(null);
  const newRef = useRef(null);
  let cnvRef = canvasRef || newRef;
  useEffect(() => {
    const canvas = cnvRef.current;
    const ctx = canvas.getContext("2d");
    const viewport = page.getViewport({ scale: 1 });
    if (rotation === 0 || rotation === 2) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;
    } else {
      canvas.width = viewport.height;
      canvas.height = viewport.width;
    }

    const renderContext = {
      canvasContext: ctx,
      viewport: viewport,
    };
    if (rotation !== 0) {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * 90 * Math.PI) / 180);
      if (rotation === 2) ctx.translate(-canvas.width / 2, -canvas.height / 2);
      else ctx.translate(-canvas.height / 2, -canvas.width / 2);
    }
    const ratio = keepOriginalSize
      ? 1
      : Math.min(height / canvas.height, width / canvas.width);

    setDimension({
      height: canvas.height * ratio + "px",
      width: canvas.width * ratio + "px",
    });
    page.render(renderContext);
    canvas.style.height = canvas.height * ratio + "px";
    canvas.style.width = canvas.width * ratio + "px";
  }, [page, rotation, height, width]);

  return (
    <div
      style={{
        position: "relative",
        height: dimension ? dimension.height : height,
        width: dimension ? dimension.width : width,
        boxShadow: "0 0 8px 0px rgb(0 0 0 / 20%)",
      }}
    >
      {pageNumberPosition?.map((pos, index) => (
        <FiberManualRecordIcon
          key={index}
          color="primary"
          size="small"
          sx={{
            position: "absolute",
            ...pos,
          }}
        />
      ))}
      <canvas style={{ background: "white" }} ref={cnvRef} />
    </div>
  );
};

export default RenderPdf;
