import { Box, Button, Menu, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { Colors } from "../../../../helpers/statics";

const FontColorButton = ({ color, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="outlined"
        disableElevation
        disableRipple
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          padding: "1.5px",
          margin: 0,
          minWidth: "auto",
          borderRadius: "0px",
          backgroundColor: `${anchorEl ? "#d9f0fa" : "white"}`,
          "&:hover": {
            backgroundColor: `${anchorEl ? "#d9f0fa" : "white"}`,
          },
          "& .MuiButton-endIcon": {
            margin: "0px",
          },
        }}
        onClick={handleOpen}
      >
        <Typography variant="p" textTransform="none">
          Color
        </Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        id="basic-menu"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexFlow: "column nowrap",
            maxHeight: "150px",
            width: "150px",
          }}
        >
          {Colors.map((color, index) => (
            <Typography
              key={index}
              variant="body2"
              align="left"
              gutterBottom
              sx={{
                cursor: "pointer",
                "&:hover": {
                  background: "#d9f0fa",
                },
              }}
              onClick={() => onUpdate({ color: color })}
            >
              {color}
            </Typography>
          ))}
        </Box>
      </Menu>
    </>
  );
};

export default FontColorButton;
