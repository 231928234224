import classes from "./PdfViewer.module.css";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ClearIcon from "@mui/icons-material/Clear";
import RenderPdf from "../UI/Canvas/RenderPdf";
import { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { ReactComponent as SelectedIcon } from "../../assets/images/selectedIcon.svg";

const PdfViewer = ({
  pdfIndex,
  page,
  pageIndex,
  orderIndex,
  pageInfo,
  rotation,
  canvasHeight,
  canvasWidth,
  selected,
  rotateLeft,
  rotateRight,
  rotateHandler,
  deleteHandler,
  canvasRef,
  pageNumberPosition,
}) => {
  const [hover, setHover] = useState(false);
  const hasButton = rotateLeft || rotateRight || deleteHandler;

  const onRotateRightBtnClick = () => {
    rotateHandler(pdfIndex, pageIndex, 1);
  };
  const onRotateLeftBtnClick = () => {
    rotateHandler(pdfIndex, pageIndex, -1);
  };
  const onDeleteBtnClick = () => {
    deleteHandler(pdfIndex, pageIndex, orderIndex);
  };

  return (
    <div
      className={classes.pdfViewer}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {selected && (
        <SelectedIcon
          style={{
            position: "absolute",
            top: "5px",
            left: "5px",
            width: "17px",
            height: "17px",
          }}
          fill="url(#paint0_linear_428_157)"
        />
      )}
      {hasButton && hover && (
        <div className={classes.pdfViewer__buttons}>
          {rotateLeft && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
                "&:hover": { backgroundColor: "red", color: "white" },
              }}
              onClick={onRotateLeftBtnClick}
            >
              <RotateLeftIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
          {rotateRight && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                "&:hover": { backgroundColor: "red", color: "white" },
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
              }}
              onClick={onRotateRightBtnClick}
            >
              <RotateRightIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
          {deleteHandler && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#d9edf7",
                "&:hover": { backgroundColor: "red", color: "white" },
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 60%)",
              }}
              onClick={onDeleteBtnClick}
            >
              <ClearIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
        </div>
      )}

      <div className={classes.pdfViewer__canvas}>
        <RenderPdf
          page={page}
          height={canvasHeight ? canvasHeight : 220}
          width={canvasWidth ? canvasWidth : 170}
          rotation={rotation ? rotation : 0}
          canvasRef={canvasRef}
          pageNumberPosition={pageNumberPosition}
        />
      </div>
      {pageInfo != null && (
        <div className={classes.pdfViewer__pageInfo}>
          <Typography
            variant="body2"
            fontSize=".7rem"
            sx={{
              fontSize: "12px",
              letterSpacing: ".3px",
              color: "#474847",
              textAlign: "center !important",
            }}
          >
            {pageInfo}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
