import { Button, Divider, IconButton, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";

const CustomRangeViewer = ({ open, list, updateList, maxPage }) => {
  const [hoverId, setHoverId] = useState();
  useEffect(() => {
    if (list.length === 0) updateList((prev) => [...prev, [1, 1]]);
  }, []);
  if (!open) return null;

  const updateRangeHandler = (pos, val) => {
    if (
      val[0] > 0 &&
      val[1] > 0 &&
      val[0] <= maxPage &&
      val[1] <= maxPage &&
      val[0] <= val[1]
    )
      updateList((prev) => {
        const list = [...prev];
        list.splice(pos, 1, val);
        return list;
      });
  };
  const deleteRangeHandler = (index) => {
    updateList((prev) => {
      const old = [...prev];
      old.splice(index, 1);
      return old;
    });
  };
  return (
    <>
      <CustomRangeViewerWrapper>
        {list.map((item, index) => (
          <RangeViewer
            key={index}
            onMouseEnter={() => setHoverId(index)}
            onMouseLeave={() => setHoverId(null)}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  padding: "10px 0px 0px 0px",
                  color: "#FF6429",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                {`Range ${index + 1}`}
              </Typography>
              <IconButton
                onClick={() => {
                  deleteRangeHandler(index);
                }}
                sx={{
                  color: "#FF6429",
                  visibility: `${hoverId === index ? "block" : "hidden"}`,
                  padding: "5px 5px",
                  "&:hover": { backgroundColor: "#EBF3FA" },
                }}
              >
                <CloseIcon size="small" />
              </IconButton>
            </Box>

            <InputSection>
              <Box
                sx={{
                  height: "44px",
                  width: "150px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  from page
                </Typography>
                <Input
                  type="number"
                  value={item[0]}
                  size="small"
                  onChange={(event) => {
                    updateRangeHandler(index, [
                      parseInt(event.target.value),
                      item[1],
                    ]);
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  height: "44px",
                  width: "90px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  to
                </Typography>
                <Input
                  type="number"
                  value={item[1]}
                  size="small"
                  onChange={(event) =>
                    updateRangeHandler(index, [
                      item[0],
                      parseInt(event.target.value),
                    ])
                  }
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Box>
            </InputSection>
          </RangeViewer>
        ))}
      </CustomRangeViewerWrapper>
      <Divider
        sx={{
          margin: "5px 0px 20px 0px",
          width: "100%",
          border: "1px solid #87C7FF",
        }}
      />
      <Button
        startIcon={
          <AddIcon
            sx={{
              color: "white",
            }}
          />
        }
        sx={{
          boxSizing: "border-box",
          width: "150px",
          minHeight: "40px",
          background: "#1D4970",
          boxShadow: "0px 10px 7px -3px #BFD5E9",
          borderRadius: "8px",
          "&:hover": {
            background: "#1D4970",
            boxShadow: "0px 10px 7px -3px #BFD5E9",
          },
        }}
        onClick={() => {
          updateList((prev) => [...prev, [1, 1]]);
        }}
      >
        <Typography
          variant="p"
          textTransform="none"
          fontSize="14px"
          lineHeight="16px"
          color="#EBF3FA"
        >
          Add range
        </Typography>
      </Button>
    </>
  );
};

export default CustomRangeViewer;

const CustomRangeViewerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const InputSection = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const RangeViewer = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;
