import { Typography } from "@mui/material";
import classes from "./LocalUpload.module.css";

const LocalUpload = ({
  setUploadedFiles,
  isMultipleAllowed,
  fileType,
  text1,
  text2,
}) => {
  const onChangeHandler = (event) => {
    event.preventDefault();
    setUploadedFiles(() => {
      return [...event.target.files];
    });
  };

  return (
    <div className={classes.localUpload}>
      <input
        type="file"
        name="file"
        id="file"
        className={classes.inputFile}
        multiple={isMultipleAllowed}
        accept={fileType}
        onChange={onChangeHandler}
      />
      <label htmlFor="file">
        <Typography
          fontWeight="500"
          lineHeight="22px"
          color="#ebf3fa"
          sx={{
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "22px",
              xl: "25px",
            },
          }}
        >
          {text1}
        </Typography>
        <Typography
          fontWeight="normal"
          lineHeight="18px"
          color="#ebf3fa"
          sx={{
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "13px",
              xl: "14px",
            },
          }}
        >
          {text2}
        </Typography>
      </label>
    </div>
  );
};

export default LocalUpload;
