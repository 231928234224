/* eslint-disable no-loop-func */
import { useEffect, useRef, useState } from "react";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import Container from "./Container";
import arrayMove from "array-move";
import styled from "styled-components";
import RightSection from "./RightSection";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import DownloadButton from "../../DownloadSection/DownloadButton";

import { useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";

const MergePdf = ({ files, isMultipleAllowed, fileType, setUploadedFiles }) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //2d array
  const [order, setOrder] = useState([]); //1d array
  const [rotations, setRotations] = useState([]); //array of array
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      const newFiles = [];
      const newNames = [];
      const newPages = [];
      const newOrder = [];
      const newRotations = [];
      for (const file of files) {
        try {
          const pdfName = await getFileName(file);
          const doc = await PdfjsDocument(file);
          const pages = await pdfjsExtractPages(doc, [1]);
          newFiles.push(file);
          newNames.push(pdfName);
          newPages.push(pages);
          newRotations.push([...Array(pages.length).keys()].map((e) => 0));
          newOrder.push(uploadedFiles.current.length + newFiles.length - 1);
        } catch (err) {
          setShowNotification(false);
          notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
          notificationType.current = "error";
          setShowNotification(true);
          return;
        }
      }
      for (const item of newFiles) {
        uploadedFiles.current.push(item);
      }
      for (const item of newNames) {
        pdfNames.current.push(item);
      }
      for (const item of newPages) {
        pdfPages.current.push(item);
      }
      setRotations((prev) => [...prev, ...newRotations]);
      setOrder((prev) => [...prev, ...newOrder]);
    };
    initializeStates();
  }, [files]);

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder((oldOrder) => arrayMove(oldOrder, oldIndex, newIndex));
  };
  const rotateHandler = (pdfIndex, pageIndex, delta) => {
    setRotations((prev) => {
      const cpy = [...prev];
      const target = cpy[pdfIndex];
      const newVal = (((target[pageIndex] + delta) % 4) + 4) % 4;
      target.splice(pageIndex, 1, newVal);
      cpy.splice(pdfIndex, 1, target);
      return cpy;
    });
  };
  const deleteHandler = (pdfIndex, pageIndex, orderIndex) => {
    uploadedFiles.current.splice(pdfIndex, 1, null);
    pdfNames.current.splice(pdfIndex, 1, null);
    pdfPages.current.splice(pdfIndex, 1, null);
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
    setRotations((prev) => {
      const oldRotations = [...prev];
      oldRotations.splice(pdfIndex, 1, null);
      return oldRotations;
    });
  };

  const onDownloadClick = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Merging in progress";
    notificationType.current = "info";
    setShowNotification(true);
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees } = PDFLib;
    const pdfDoc = await PDFDocument.create();
    for (const i of order) {
      const doc = await PdfLibDocument(uploadedFiles.current[i]);
      const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());
      copiedPages.forEach((page) => {
        // eslint-disable-next-line no-undef
        page.setRotation(degrees(rotations[i][0] * 90));
        pdfDoc.addPage(page);
      });
    }
    const mergedPDFFile = await pdfDoc.save();
    setShowNotification(false);
    notificationMessage.current = "file will be downloaded soon";
    notificationType.current = "info";
    setShowNotification(true);
    downloadDocument(mergedPDFFile, "mergedPDF.pdf", "application/pdf", aRef);
    setDisableDownloadButton(false);
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
  };
  return (
    <>
      <MergePdfStyle>
        {order?.length > 0 && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            rotations={rotations}
            order={order}
            onSortEnd={sortEndHandler}
            onRotate={rotateHandler}
            onDelete={deleteHandler}
            axis={"xy"}
            distance={5}
          />
        )}
      </MergePdfStyle>

      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />
      <RightSection />
      <DownloadButton
        onDownloadClick={onDownloadClick}
        text="Merge PDF"
        buttonPosition="bottom-dynamic"
      />
      <a ref={aRef} />
    </>
  );
};

export default MergePdf;

const MergePdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
