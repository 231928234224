import * as types from "./types";

const INITIAL_STATE = {
  signList: [],
};

const signPdfReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INSERT_SIGN: {
      const prevSignList = state.signList;
      prevSignList.push(action.payload);
      return { ...state, signList: prevSignList };
    }

    case types.DELETE_SIGN: {
      const prevSignList = state.signList;
      prevSignList.splice(action.payload, 1);
      return { ...state, signList: prevSignList };
    }
    default:
      return state;
  }
};

export default signPdfReducer;
