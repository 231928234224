import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ToolCard from "../UI/Card/ToolCard";
import { Box } from "@mui/material";
const Tools = ({ icon, title, description, link }) => {
  const location = useLocation();
  return (
    <Link to={{ pathname: link, params: location }}>
      <ToolCard>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              height: "32px",
              width: "32px",
            }}
          >
            {icon}
          </Box>
          <Typography
            fontStyle="normal"
            fontSize="26px"
            line-height="30px"
            color="#4a4a4a"
            fontWeight="500"
          >
            {title}
          </Typography>
          <Typography
            fontStyle="normal"
            fontSize="13px"
            lineHeight="15px"
            color="#818181"
            fontWeight="300"
          >
            {description}
          </Typography>
        </Box>
      </ToolCard>
    </Link>
  );
};

export default Tools;
