import classes from "./UploadManager.module.css";
import LocalUpload from "./LocalUpload";
import GoogleDriveUpload from "./GoogleDriveUpload";
import DropBoxUpload from "./DropBoxUpload";
const UploadManager = ({ setUploadedFiles, isMultipleAllowed, fileType }) => {
  let text1, text2;

  if (fileType === "application/pdf") {
    text1 = "Select PDF ";
    text2 = "or drop PDF";
  } else if (fileType === "image/*" || fileType === "image/jpeg") {
    text1 = "select Image ";
    text2 = "or drop Image";
  }
  if (isMultipleAllowed) {
    text1 += "files";
    text2 += "s here";
  } else {
    text1 += "file";
    text2 += " here";
  }

  return (
    <div className={classes.uploadManager}>
      <div className={classes.localUpload}>
        <LocalUpload
          setUploadedFiles={setUploadedFiles}
          isMultipleAllowed={isMultipleAllowed}
          fileType={fileType}
          text1={text1}
          text2={text2}
        />
      </div>
      {/* <div className={classes.onlineUpload}>
        <GoogleDriveUpload />
        <DropBoxUpload />
      </div> */}
    </div>
  );
};

export default UploadManager;
