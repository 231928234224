import React, { useState } from "react";
import { Box, Button, Divider, Typography, IconButton } from "@mui/material";
import styled from "styled-components";
import RotationIcon from "./RotationIcon";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const RightSection = ({
  onReset,
  selectedRotationType,
  setSelectedRotationTYpe,
  wholeRotationHandler,
  portraitRotationHandler,
  landscapeRotateHandler,
  numPdfs,
}) => {
  const [rotatePdfOpen, setRotatePdfOpen] = useState(false);

  const handleDrawerToggle = () => {
    setRotatePdfOpen(!rotatePdfOpen);
  };
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>
      <RightSectionStyle right={rotatePdfOpen}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              height: "25px",
              width: "25px",
              mt: 1,
              display: "none",
              cursor: "pointer",
              ml: 1,
              p: "8px",
              borderRadius: "50%",
              transition: "0.3s ease-in-out",
              "&:hover": {
                background: "#dedad9",
              },
              "@media(max-width: 900px)": { display: "inline-block" },
            }}
            onClick={handleDrawerToggle}
          >
            <CloseIcon />
          </Box>
        </Box>

        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          Rotate PDF
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />
        <RotationContainerStyle>
          {numPdfs > 1 && (
            <>
              <RotationHeadingStyle>
                <Typography
                  variant="p"
                  fontSize="16px"
                  lineHeight="19px"
                  sx={{
                    color: "#FF6429",
                  }}
                >
                  Select Files to Rotate
                </Typography>
                <Button
                  onClick={onReset}
                  sx={{
                    "&:hover": {
                      background: "#e0e0e0",
                    },
                  }}
                >
                  <Typography
                    variant="p"
                    textTransform="none"
                    fontSize="14px"
                    lineHeight="16px"
                    color="red"
                    sx={{
                      color: "#FF6429",
                      fontWeight: "400",
                    }}
                  >
                    Reset All
                  </Typography>
                </Button>
              </RotationHeadingStyle>
              <RotationOptionWrapperStyle>
                {[...Array(3)].map((pos, index) => (
                  <RotationIcon
                    key={index}
                    index={index}
                    selectedRotationType={selectedRotationType}
                    setSelectedRotationTYpe={setSelectedRotationTYpe}
                  />
                ))}
              </RotationOptionWrapperStyle>
              <Box
                sx={{
                  width: "90%",
                  marginTop: "30px",
                }}
              >
                <Typography
                  variant="p"
                  fontSize="16px"
                  lineHeight="19px"
                  sx={{
                    color: "#FF6429",
                  }}
                >
                  ROTATION
                </Typography>
                <RotationButtonWrapperStyle>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    sx={{
                      paddingLeft: "20px",
                      background: "#d9edf7",
                      width: "70%",
                      maxWidth: "200px",
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "#818181",
                      boxShadow: "0 2px 2px 0 rgba(147, 184, 217, 1)",
                      "&:hover": {
                        background: "#d9edf7",
                        color: "red",
                      },
                    }}
                    startIcon={<RotateRightIcon />}
                    onClick={() => {
                      if (selectedRotationType === 0)
                        portraitRotationHandler(1);
                      else if (selectedRotationType === 1)
                        landscapeRotateHandler(1);
                      else if (selectedRotationType === 2)
                        wholeRotationHandler(1);
                    }}
                  >
                    <Typography variant="button">RIGHT</Typography>
                  </Button>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    sx={{
                      paddingLeft: "20px",
                      background: "#d9edf7",
                      width: "70%",
                      maxWidth: "200px",
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "#818181",
                      boxShadow: "0 2px 2px 0 rgba(147, 184, 217, 1)",
                      "&:hover": {
                        background: "#d9edf7",
                        color: "red",
                      },
                    }}
                    startIcon={<RotateLeftIcon />}
                    onClick={() => {
                      if (selectedRotationType === 0)
                        portraitRotationHandler(-1);
                      else if (selectedRotationType === 1)
                        landscapeRotateHandler(-1);
                      else if (selectedRotationType === 2)
                        wholeRotationHandler(-1);
                    }}
                  >
                    LEFT
                  </Button>
                </RotationButtonWrapperStyle>
              </Box>
            </>
          )}
        </RotationContainerStyle>
        <Box
          sx={{
            width: "80%",
            marginTop: "40px",
            padding: "10px",
            background: "#E1ECF5",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "normal",
              textAlign: "center !important",
              fontSize: { xs: "13px", lg: "16px" },
              lineHeight: "19px",
              color: " #4A4A4A",
              textAlignLast: "center",
            }}
          >
            To rotate indivisual pdf, hover over a pdf card and you will see
            rotate button
          </Typography>
        </Box>
      </RightSectionStyle>
    </>
  );
};

export default RightSection;

const RightSectionStyle = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  padding-bottom: 100px;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }
`;

const RotationContainerStyle = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 90%;
`;

const RotationHeadingStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RotationOptionWrapperStyle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`;

const RotationButtonWrapperStyle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;
