import classes from "./Form.module.css";
import useForm from "../../../hooks/useForm";
import { useContext } from "react";
import { AuthContext } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    value: enteredName,
    isValid: isNameValid,
    hasError: hasInputNameError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: nameReset,
  } = useForm((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: isEmailValid,
    hasError: hasInputEmailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useForm((value) => value.includes("@"));

  const {
    value: enteredPassword1,
    isValid: isPasswordValid1,
    hasError: hasInputPasswordError1,
    valueChangeHandler: passwordChangeHandler1,
    inputBlurHandler: passwordBlurHandler1,
    reset: passwordReset1,
  } = useForm((value) => value.trim().length >= 1);

  const {
    value: enteredPassword2,
    isValid: isPasswordValid2,
    hasError: hasInputPasswordError2,
    valueChangeHandler: passwordChangeHandler2,
    inputBlurHandler: passwordBlurHandler2,
    reset: passwordReset2,
  } = useForm((value) => value.trim().length >= 1);

  // eslint-disable-next-line no-unused-vars
  const nameInputClasses = !hasInputNameError
    ? classes.formNameInput
    : `${classes.formNamelInput} ${classes.error}`;
  // eslint-disable-next-line no-unused-vars
  const emailInputClasses = !hasInputEmailError
    ? classes.formEmailInput
    : `${classes.formEmailInput} ${classes.error}`;
  // eslint-disable-next-line no-unused-vars
  const passwordInputClasses1 = !hasInputPasswordError1
    ? classes.formPasswordInput
    : `${classes.formPasswordInput} ${classes.error}`;
  const passwordInputClasses2 = !hasInputPasswordError2
    ? classes.formPasswordInput
    : `${classes.formPasswordInput} ${classes.error}`;

  let formIsValid = false;
  if (isNameValid && isEmailValid && isPasswordValid1 && isPasswordValid2)
    formIsValid = true;

  // const formSubmitHandler = (event) => {
  //   event.preventDefault();
  //   console.log(enteredName, enteredEmail, enteredPassword);
  //   nameReset();
  //   emailReset();
  //   passwordReset();
  // };
  const formSubmitHandler = (event) => {
    event.preventDefault();
    // console.log(enteredEmail, enteredPassword1);

    fetch(
      `${process.env.REACT_APP_REST_API_ENDPOINT}/dj-rest-auth/registration/`,
      {
        method: "POST",
        body: JSON.stringify({
          username: enteredName,
          email: enteredEmail,
          password1: enteredPassword1,
          password2: enteredPassword2,
          // username: "a",
          // password: "a",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        // console.log("json", json);
        if (json.key) {
          // console.log("josn.key", json.key);
          fetch(
            `${process.env.REACT_APP_REST_API_ENDPOINT}/dj-rest-auth/user`,
            {
              method: "GET",
              headers: {
                Authorization: `Token ${json.key}`,
              },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              // console.log(response);
              if (response.username) {
                nameReset();
                emailReset();
                passwordReset1();
                passwordReset2();

                auth.signin(response.username, () => {
                  navigate(-1);
                });
              } else {
                // console.log("not foumnd");
              }
            });
        } else {
          // console.log("not found");
        }
      });
  };

  return (
    <form className={classes.form} onSubmit={formSubmitHandler}>
      <div className={nameInputClasses}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          placeholder="Your name"
          value={enteredName}
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
        ></input>
        {hasInputNameError && (
          <div className={classes.nameErrorText}>Enter valid name</div>
        )}
      </div>
      <div className={emailInputClasses}>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          placeholder="mail@example.com"
          value={enteredEmail}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        ></input>
        {hasInputEmailError && (
          <div className={classes.emailErrorText}>Enter valid email</div>
        )}
      </div>
      <div className={passwordInputClasses1}>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Min. 8 charecter"
          value={enteredPassword1}
          onChange={passwordChangeHandler1}
          onBlur={passwordBlurHandler1}
        ></input>
        {hasInputPasswordError1 && (
          <div className={classes.passwordErrorText}>
            password must be 8 character long
          </div>
        )}
      </div>
      <div className={passwordInputClasses2}>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          placeholder="Min. 8 charecter"
          value={enteredPassword2}
          onChange={passwordChangeHandler2}
          onBlur={passwordBlurHandler2}
        ></input>
        {hasInputPasswordError2 && (
          <div className={classes.passwordErrorText}>
            password must be 8 character long
          </div>
        )}
      </div>
      <div className={classes.checkbox}>
        <input type="checkbox" id="terms"></input>
        <p>I agree to the Terms and Conditions</p>
      </div>
      <div className={classes.formSubmit}>
        <button disabled={!formIsValid}>
          <p>Signup</p>
        </button>
      </div>
    </form>
  );
};

export default Form;
