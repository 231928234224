import { Button } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
const BoldButton = ({ fontWeight, onUpdate }) => {
  return (
    <Button
      variant="outlined"
      disableElevation
      disableRipple
      sx={{
        padding: "2px",
        margin: 0,
        minWidth: "auto",
        borderRadius: "0px",
        backgroundColor: `${fontWeight === "700" ? "#d9f0fa" : "white"}`,
        "&:hover": {
          backgroundColor: `${fontWeight === "700" ? "#d9f0fa" : "white"}`,
        },
      }}
      onClick={() =>
        onUpdate({
          fontWeight: fontWeight === "400" ? "700" : "400",
        })
      }
    >
      <FormatBoldIcon color="primary" variant="outlined" />
    </Button>
  );
};

export default BoldButton;
