import { useRef } from "react";
import { useDrag } from "react-dnd";
import RenderImage from "../UI/Canvas/RenderImage";

const DraggableComponent = ({
  index,
  src,
  height,
  width,
  type,
  rotation,
  onDropSignImage,
}) => {
  const canvasRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: {
      name: type,
      index: index,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        const target = dropResult.name.index;
        const source = item.index;
        const offsetX = dropResult.name.x;
        const offsetY = dropResult.name.y;

        //max height or width of initial sign image, keeps aspect ratio
        const ratio = Math.max(
          1,
          Math.max(src.naturalHeight / 250, src.naturalWidth / 250)
        );

        onDropSignImage(target, {
          type: type,
          img: source,
          offsetX: offsetX,
          offsetY: offsetY,
          height: `${src.naturalHeight / ratio}px`,
          width: `${src.naturalWidth / ratio}px`,
        });
      } else {
        // console.log("no drop result");
      }
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(canvasRef);
  return (
    <RenderImage
      image={src}
      height={height}
      width={width}
      rotation={rotation}
      canvasRef={canvasRef}
      cursor="grabbing"
    />
  );
};

export default DraggableComponent;
