import { useState } from "react";

const useSplit = () => {
  const [selectRange, setSelectRange] = useState(true);
  const [selectCustomRange, setSelectCustomRange] = useState(true);
  const [selectFixedRange, setSelectFixedRange] = useState(false);

  const [selectPage, setSelectPage] = useState(false);
  const [selectAllPage, setSelectAllPage] = useState(true);
  const [selectCustomPage, setSelectCustomPage] = useState(false);

  const [customRangeList, setCustomRangeList] = useState([]);
  const [fixedRangeList, setFixedRangeList] = useState(1);
  const [extractPageList, setExtractPageList] = useState("");

  const splitByRangehandler = () => {
    setSelectRange(true);
    setSelectPage(false);
  };
  const customRangeHandler = () => {
    setSelectCustomRange(true);
    setSelectFixedRange(false);
  };
  const fixedRangeHandler = () => {
    setSelectFixedRange(true);
    setSelectCustomRange(false);
  };

  const splitByPageHandler = () => {
    setSelectPage(true);
    setSelectRange(false);
  };
  const allPageHandler = () => {
    setSelectAllPage(true);
    setSelectCustomPage(false);
  };
  const customPageHandler = () => {
    setSelectCustomPage(true);
    setSelectAllPage(false);
  };

  const setCustomRangeListHandler = (newRanges) => {
    setCustomRangeList((prev) => [...prev, newRanges]);
  };
  const setFixedRangeListHandler = (newRanges) => {
    setFixedRangeList(newRanges);
  };
  const setExtractPageListHandler = (newPage) => {
    setExtractPageList(newPage);
  };

  const isSplitPdfValid = () => {
    if (selectRange) {
      if (selectCustomRange && customRangeList) return true;
      if (selectFixedRange && fixedRangeList) return true;
    } else {
      if (selectAllPage) return true;
      if (selectCustomPage && extractPageList) return true;
    }
    return false;
  };

  return {
    selectRange: selectRange,
    selectCustomRange: selectCustomRange,
    selectFixedRange: selectFixedRange,
    selectPage: selectPage,
    selectAllPage: selectAllPage,
    selectCustomPage: selectCustomPage,
    splitByRangehandler: splitByRangehandler,
    customRangeHandler: customRangeHandler,
    fixedRangeHandler: fixedRangeHandler,
    splitByPageHandler: splitByPageHandler,
    allPageHandler: allPageHandler,
    customPageHandler: customPageHandler,

    customRangeList: customRangeList,
    // setCustomRangeListHandler: setCustomRangeListHandler,
    setCustomRangeListHandler: setCustomRangeList,
    fixedRangeList: fixedRangeList,
    setFixedRangeListHandler: setFixedRangeListHandler,
    extractPageList: extractPageList,
    setExtractPageListHandler: setExtractPageListHandler,

    isSplitPdfValid: isSplitPdfValid,
  };
};

export default useSplit;
