import styled from "styled-components";
import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";

const Container = ({
  pdfNames,
  pages,
  order,
  position,
  mosaic,
  lowPage,
  highPage,
}) => {
  let top = null,
    bottom = null,
    left = null,
    right = null;
  if (position >= 0 && position <= 2) top = "10px";
  else if (position >= 6 && position <= 8) bottom = "10px";
  else top = "calc(50% - 8px)";

  if (position % 3 === 0) left = "10px";
  else if (position % 3 === 2) right = "10px";
  else left = "calc(50% - 8px)";

  return (
    <ContainerWrapperStyle>
      {order.map((ord, index) => (
        <PdfCard height="300px" width="250px" cursor="auto">
          <PdfViewer
            page={pages[ord]}
            pageInfo={ord + 1}
            canvasHeight="230"
            canvasWidth="200"
            pageNumberPosition={
              ord + 1 >= lowPage && ord + 1 <= highPage
                ? mosaic
                  ? [
                      { top: "10px", left: "10px" },
                      { top: "10px", left: "calc(50% - 8px)" },
                      { top: "10px", right: "10px" },
                      { top: "calc(50% - 8px)", left: "10px" },
                      { top: "calc(50% - 8px)", left: "calc(50% - 8px)" },
                      { top: "calc(50% - 8px)", right: "10px" },
                      { bottom: "10px", left: "10px" },
                      { bottom: "10px", left: "calc(50% - 8px)" },
                      { bottom: "10px", right: "10px" },
                    ]
                  : [
                      {
                        left: left,
                        right: right,
                        top: top,
                        bottom: bottom,
                      },
                    ]
                : null
            }
          />
        </PdfCard>
      ))}
    </ContainerWrapperStyle>
  );
};

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
