import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import styled from "styled-components";
import { ReactComponent as PasswordIcon } from "../../../assets/images/password.svg";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";

const Container = ({ pdfNames, pages }) => {
  return (
    <ContainerWrapperStyle>
      <PdfCard height="300px" width="250px" cursor="auto">
        {pages.length > 0 ? (
          <PdfViewer
            page={pages[0]}
            pageInfo={pdfNames}
            canvasHeight="230"
            canvasWidth="200"
          />
        ) : (
          <Box
            style={{
              position: "relative",
              height: "80%",
              width: "80%",
              boxShadow: "0 0 8px 0px rgb(0 0 0 / 20%)",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
            <PasswordIcon
              style={{
                position: "absolute",
                top: "calc(50% - 40px)",
                left: "calc(50% - 40px)",
                height: "80px",
                width: "80px",
              }}
            />
          </Box>
        )}
      </PdfCard>
    </ContainerWrapperStyle>
  );
};

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
