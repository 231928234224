import {
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import PasswordForm from "./PasswordForm";

const RightSection = ({ formik, selectedType, selectTypeHandler }) => {
  const [protectPdfOpen, setProtectPdfOpen] = useState(false);

  const handleDrawerToggle = () => {
    setProtectPdfOpen((prev) => !prev);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>

      <RightSectionStyle right={protectPdfOpen}>
        <Box
          sx={{
            height: "25px",
            width: "25px",
            mt: 1,
            display: "none",
            cursor: "pointer",
            ml: 1,
            p: "8px",
            borderRadius: "50%",
            transition: "0.3s ease-in-out",
            "&:hover": {
              background: "#dedad9",
            },
            "@media(max-width: 900px)": { display: "inline-block" },
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </Box>
        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          Protect PDF
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />

        <PasswordForm formik={formik} />

        <Box
          sx={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <RadioGroup
            value={selectedType}
            onChange={(event) =>
              selectTypeHandler(parseInt(event.target.value))
            }
            sx={{
              width: "80%",
              "& .MuiRadio-root": {
                padding: "4px",
              },
            }}
          >
            <FormControlLabel
              sx={{
                color: "#818181",
              }}
              value={0}
              control={
                <Radio
                  sx={{
                    color: "#818181",
                  }}
                />
              }
              label="Merge compressed pdf"
            />
            <FormControlLabel
              sx={{
                color: "#818181",
              }}
              value={1}
              control={
                <Radio
                  sx={{
                    color: "#818181",
                  }}
                />
              }
              label="Individual pdf"
            />
          </RadioGroup>
        </Box>
      </RightSectionStyle>
    </>
  );
};

export default RightSection;

const RightSectionStyle = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  padding-bottom: 100px;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;

  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }
`;
