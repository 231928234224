import classes from "./SignPdf.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { Typography } from "@mui/material";

import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import Container from "./Container";
import SignButton from "./Button/SignButton";
import TextButton from "./Button/TextButton";
import DownloadButton from "../../DownloadSection/DownloadButton";
import * as actions from "../../../redux/signPdf/action";
import { NotificationContext } from "../../../context/NotificationContext";

const SignPdf = ({
  title,
  description,
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const { signList } = useSelector((letseditpdf) => letseditpdf.singPdf);
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const [pdfPages, setPdfPages] = useState(null); //1d array
  const [activeSignType, setActiveSignType] = useState(0);
  const [sign, setSign] = useState([]);
  const [signImagePerPage, setSignImagePerPage] = useState({});
  const [textPerPage, setTextPerPage] = useState({});

  const containerRef = useRef(null);
  const canvasRefs = useRef([]);
  const aRef = useRef(null);
  const canvasRef = useRef(null);
  const typographyRef = useRef(null);

  const dispatch = useDispatch();

  const { notificationContext } = useContext(NotificationContext);
  const { setShowNotification, notificationType, notificationMessage } =
    notificationContext;

  useEffect(() => {
    const temp = [];
    for (const i of signList) {
      const image = new Image();
      image.src = JSON.parse(i.file);
      temp.push({ ...i, file: image });
    }
    setSign(temp);
  }, []);

  useEffect(() => {
    const updateStates = async () => {
      try {
        const pdfName = await getFileName(files[0]);
        const doc = await PdfjsDocument(files[0]);
        const pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );
        canvasRefs.current = [...Array(doc.numPages)].map((e) => null);
        uploadedFiles.current.push(files[0]);
        pdfNames.current.push(pdfName);
        setPdfPages(pages);
      } catch (err) {
        setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
    };
    updateStates();
  }, [files]);

  const signUploadHandler = (file) => {
    setSign((prev) => [...prev, file]);
    dispatch(
      actions.insertSign({ ...file, file: JSON.stringify(file.file.src) })
    );
  };
  const hideSignHandler = (index) => {
    setSign((prev) => {
      const old = [...prev];
      const target = old[index];
      target.visible = false;
      old.splice(index, 1, target);
      return old;
    });
    dispatch(actions.deleteSign(index));
  };

  const signImageAssignHandler = (pageId, obj) => {
    setSignImagePerPage((prev) => {
      const old = { ...prev };
      const signs = old[pageId];
      if (signs) {
        signs.push(obj);
        return {
          ...old,
          [pageId]: signs,
        };
      } else {
        const newArray = [];
        newArray.push(obj);
        return {
          ...old,
          [pageId]: newArray,
        };
      }
    });
  };
  const updateSignImageHandler = (
    pageIndex,
    signIndex,
    newPosX,
    newPosY,
    newHeight,
    newWidth
  ) => {
    const container = containerRef.current.getBoundingClientRect();
    for (let index = 0; index < pdfPages.length; index++) {
      const page = canvasRefs.current[index].current.getBoundingClientRect();

      const offsetX = page["x"] - container["x"];
      const offsetY = page["y"] - container["y"];

      const left = page["left"] - container["left"];
      const right = left + page["width"];
      const top = page["top"] - container["top"];
      const bottom = top + page["height"];

      if (
        newPosX >= left &&
        newPosX < right &&
        newPosY >= top &&
        newPosY < bottom
      ) {
        if (pageIndex === index) {
          setSignImagePerPage((prev) => {
            const copyPrev = { ...prev };
            let oldSign = copyPrev[pageIndex];
            let current = oldSign[signIndex];
            current = {
              ...current,
              offsetX: newPosX - offsetX,
              offsetY: newPosY - offsetY,
              height: newHeight + "px",
              width: newWidth + "px",
            };
            oldSign.splice(signIndex, 1, current);
            return {
              ...copyPrev,
              [pageIndex]: oldSign,
            };
          });
        } else {
          setSignImagePerPage((prev) => {
            const copyPrev = { ...prev };
            let deleteSign = copyPrev[pageIndex][signIndex];

            let oldSigns = copyPrev[pageIndex];
            let goodOldSigns = oldSigns.filter(
              (e, eindex) => eindex !== signIndex
            );

            let newSigns = copyPrev[index];
            if (newSigns) {
              newSigns = [
                ...newSigns,
                {
                  ...deleteSign,
                  offsetX: newPosX - offsetX,
                  offsetY: newPosY - offsetY,
                  height: newHeight + "px",
                  width: newWidth + "px",
                },
              ];
            } else {
              newSigns = [
                {
                  ...deleteSign,
                  offsetX: newPosX - offsetX,
                  offsetY: newPosY - offsetY,
                  height: newHeight + "px",
                  width: newWidth + "px",
                },
              ];
            }

            return {
              ...copyPrev,
              [pageIndex]: goodOldSigns,
              [index]: newSigns,
            };
          });
        }

        break;
      }
    }
  };
  const deleteSignImageHandler = (pageId, signImageId) => {
    setSignImagePerPage((prev) => {
      const old = { ...prev };
      const current = old[pageId];
      current.splice(signImageId, 1);
      return { ...old, [pageId]: current };
    });
  };

  const textAssignHandler = (pageId, obj) => {
    setTextPerPage((prev) => {
      const old = { ...prev };
      const texts = old[pageId];
      if (texts) {
        texts.push(obj);
        return {
          ...old,
          [pageId]: texts,
        };
      } else {
        const newArray = [];
        newArray.push(obj);
        return {
          ...old,
          [pageId]: newArray,
        };
      }
    });
  };
  const updateTextPositionHandler = (
    pageIndex,
    textIndex,
    newPosX,
    newPosY,
    textRef
  ) => {
    const container = containerRef.current.getBoundingClientRect();
    for (let index = 0; index < pdfPages.length; index++) {
      const page = canvasRefs.current[index].current.getBoundingClientRect();

      const offsetX = page["x"] - container["x"];
      const offsetY = page["y"] - container["y"];

      const left = page["left"] - container["left"];
      const right = left + page["width"];
      const top = page["top"] - container["top"];
      const bottom = top + page["height"];

      if (
        newPosX >= left &&
        newPosX < right &&
        newPosY >= top &&
        newPosY < bottom
      ) {
        if (pageIndex === index) {
          setTextPerPage((prev) => {
            const copyPrev = { ...prev };
            let oldSign = copyPrev[pageIndex];
            let current = oldSign[textIndex];
            current = {
              ...current,
              offsetX: newPosX - offsetX,
              offsetY: newPosY - offsetY,
              textRef: textRef,
            };
            oldSign.splice(textIndex, 1, current);
            return {
              ...copyPrev,
              [pageIndex]: oldSign,
            };
          });
        } else {
          setTextPerPage((prev) => {
            const copyPrev = { ...prev };
            let deleteSign = copyPrev[pageIndex][textIndex];

            let oldSigns = copyPrev[pageIndex];
            let goodOldSigns = oldSigns.filter(
              (e, eindex) => eindex !== textIndex
            );

            let newSigns = copyPrev[index];
            if (newSigns) {
              newSigns = [
                ...newSigns,
                {
                  ...deleteSign,
                  offsetX: newPosX - offsetX,
                  offsetY: newPosY - offsetY,
                  textRef: textRef,
                },
              ];
            } else {
              newSigns = [
                {
                  ...deleteSign,
                  offsetX: newPosX - offsetX,
                  offsetY: newPosY - offsetY,
                  textRef: textRef,
                },
              ];
            }
            return {
              ...copyPrev,
              [pageIndex]: goodOldSigns,
              [index]: newSigns,
            };
          });
        }

        break;
      }
    }
  };
  const updateTextHandler = (pageIndex, textIndex, updatedObj) => {
    setTextPerPage((prev) => {
      const old = { ...prev };
      const texts = old[pageIndex];
      texts.splice(textIndex, 1, updatedObj);
      return { ...old, [pageIndex]: texts };
    });
  };
  const deleteTextHandler = (pageIndex, textIndex) => {
    setTextPerPage((prev) => {
      const old = { ...prev };
      const texts = old[pageIndex];
      texts.splice(textIndex, 1);
      return { ...old, [pageIndex]: texts };
    });
  };

  const downloadHandler = async () => {
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees, StandardFonts, rgb } = PDFLib;
    const pdfDoc = await PDFDocument.create();
    const doc = await PdfLibDocument(uploadedFiles.current[0]);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (let index = 0; index < copiedPages.length; index++) {
      const sign_list = signImagePerPage[index];
      const textList = textPerPage[index];
      const page = copiedPages[index];
      const { width, height } = page.getSize();
      if (sign_list) {
        for (const sgn of sign_list) {
          if (sgn.type === "signImage" || sgn.type === "signText") {
            let img = await fetch(sign[sgn["img"]].file.src).then((res) =>
              res.arrayBuffer()
            );
            let imageData = null;
            try {
              imageData = await pdfDoc.embedPng(img);
            } catch {
              imageData = await pdfDoc.embedJpg(img);
            }
            page.drawImage(imageData, {
              x: sgn.offsetX,
              y: height - sgn.offsetY - parseFloat(sgn.height),
              width: parseFloat(sgn.width),
              height: parseFloat(sgn.height),
            });
          }
        }
      }
      if (textList) {
        for (const text of textList) {
          let typography = typographyRef.current;
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");

          typography.style.fontSize = `${text.fontSize}px`;
          typography.style.fontStyle = text.fontStyle;
          typography.style.fontWeight = text.fontWeight;
          typography.style.fontFamily = text.fontFamily;
          typography.style.color = text.color;
          typography.innerHTML = text.text;

          const divHeight = typography.getBoundingClientRect().height;
          const divWidth = typography.getBoundingClientRect().width;

          canvas.height = divHeight;
          canvas.width = divWidth + 30;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.font = `${text.fontStyle} ${text.fontWeight} ${text.fontSize}px ${text.fontFamily}`;

          ctx.fillStyle = text.color;
          ctx.fillText(text.text, 15, (canvas.height * 3) / 4);

          const imageBuffer = canvas.toDataURL("image/png");
          const image = new Image();
          image.src = imageBuffer;
          let img = await fetch(image.src).then((res) => res.arrayBuffer());
          let imageData = null;
          try {
            imageData = await pdfDoc.embedPng(img);
          } catch {
            imageData = await pdfDoc.embedJpg(img);
          }
          page.drawImage(imageData, {
            x: text.offsetX - 15,
            y: height - text.offsetY - parseFloat(canvas.height),
            width: parseFloat(canvas.width),
            height: parseFloat(canvas.height),
          });
        }
      }
    }

    copiedPages.forEach((page) => {
      // eslint-disable-next-line no-undef
      pdfDoc.addPage(page);
    });

    const signedPDFFile = await pdfDoc.save();
    downloadDocument(signedPDFFile, "signedPDF.pdf", "application/pdf", aRef);
  };

  return (
    <>
      <div className={classes.signPdf}>
        <Typography
          variant="h2"
          sx={{
            marginTop: "10px",
            fontWeight: "bold",
            fontSize: {
              xl: "45px",
              lg: "40px",
              md: "36px",
              sm: "32px",
              xs: "25px",
            },
            background:
              "linear-gradient(90.07deg, #ee0979 5.77%, #ff6a00 106.28%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          {title}
        </Typography>
        <Typography
          fontStyle="normal"
          fontWeight="400"
          color="#4A4A4A"
          variant="body1"
          sx={{
            px: 1,
            fontSize: {
              xl: "17px",
              lg: "16px",
              md: "15px",
              sm: "14px",
              xs: "12px",
            },
            textAlign: "center !important",
            textAlignLast: "center",
          }}
        >
          {description}
        </Typography>
        <div className={classes.signPdf__horizontalLine}></div>
        <div className={classes.signPdf__tools}>
          <TextButton
            activeSignType={activeSignType}
            onChangeActiveSignType={setActiveSignType}
          />
          <SignButton
            activeSignType={activeSignType}
            onChangeActiveSignType={setActiveSignType}
            sign={sign}
            onSignUpload={signUploadHandler}
            onDropSignImage={signImageAssignHandler}
            onHideSign={hideSignHandler}
          />
        </div>
        {pdfPages?.length > 0 && (
          <Container
            pdfNames={pdfNames.current}
            pdfPages={pdfPages}
            activeSignType={activeSignType}
            sign={sign}
            signImagePerPage={signImagePerPage}
            onSignImageUpdate={updateSignImageHandler}
            onDeleteSignImage={deleteSignImageHandler}
            textPerPage={textPerPage}
            onDropText={textAssignHandler}
            onTextUpdate={updateTextHandler}
            onTextPositionUpdate={updateTextPositionHandler}
            onDeleteText={deleteTextHandler}
            containerRef={containerRef}
            canvasRefs={canvasRefs}
          />
        )}
      </div>
      <DownloadButton
        onDownloadClick={downloadHandler}
        text="Apply Changes"
        buttonPosition="fixed-bottom"
      />
      <a ref={aRef} />
      <canvas
        ref={canvasRef}
        style={{
          visibility: "hidden",
          height: "0px",
          width: "0px",
          position: "absolute",
          top: "0px",
        }}
      />

      <p
        ref={typographyRef}
        style={{ visibility: "hidden", position: "absolute", top: "0px" }}
      />
    </>
  );
};

export default SignPdf;
