import { createContext, useRef, useState } from "react";
import Notification from "../components/common/Notification";

export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [showNotification, setShowNotification] = useState(false);
  const notificationMessage = useRef("");
  const notificationType = useRef("info"); //based on mui "Alert" type
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  return (
    <NotificationContext.Provider
      value={{
        notificationContext: {
          showNotification,
          setShowNotification,
          notificationMessage,
          notificationType,
          disableDownloadButton,
          setDisableDownloadButton,
        },
      }}
    >
      <Notification
        open={showNotification}
        setOpen={setShowNotification}
        message={notificationMessage.current}
        type={notificationType.current}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
