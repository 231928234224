import classes from "./Layout.module.css";
import Header from "../components/Header/Header";

const Layout = (props) => {
  return (
    <div className={classes.layout}>
      <div>
        <Header />
      </div>
      <div className={classes.container}>{props.children}</div>
    </div>
  );
};

export default Layout;
