import LoginForm from "../components/Login-Signup/Login/LoginForm";
import LoginSignupStyle from "../components/common/LoginSignupStyle";
import Layout from "../Layouts/Layout";
import classes from "./LoginPage.module.css";
import { Typography } from "@mui/material";

const LoginPage = () => {
  return (
    <Layout>
      <div className={classes.loginPage}>
        <div className={classes.loginForm}>
          <LoginForm />
        </div>
        <div className={classes.style}>
          <LoginSignupStyle leftOffset="170">
            <Typography
              fontStyle="normal"
              fontWeight="400"
              fontSize="50px"
              lineHeight="59px"
              color="#EBF3FA"
            >
              Login to your
            </Typography>
            <Typography
              fontStyle="normal"
              fontWeight="700"
              fontSize="64px"
              lineHeight="75px"
              color="#EBF3FA"
            >
              Workspace!
            </Typography>
          </LoginSignupStyle>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
