import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import Container from "./Container";
import RightSection from "./RightSection";
import DownloadButton from "../../DownloadSection/DownloadButton";
import { NotificationContext } from "../../../context/NotificationContext";

const WatermarkPdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const [order, setOrder] = useState(null); //number
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const [mode, setMode] = useState(0); //number
  const [position, setPosition] = useState(0);
  const [mosaic, setMosaic] = useState(false);
  const [lowPage, setLowPage] = useState(1);
  const [highPage, setHighPage] = useState(1);
  const [transparency, setTransparency] = useState(0);
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      try {
        const pdfName = await getFileName(files[0]);
        const doc = await PdfjsDocument(files[0]);
        const pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );
        uploadedFiles.current.push(files[0]);
        pdfNames.current.push(pdfName);
        pdfPages.current.push(...pages);
        setHighPage(pdfPages.current.length);
        setOrder([...Array(doc.numPages).keys()].map((e) => e));
      } catch (err) {
        setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
    };
    initializeStates();
  }, [files]);

  const modeHandler = (newVal) => {
    setMode(newVal);
  };

  const downloadHandler = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Watermark in progress";
    notificationType.current = "info";
    setShowNotification(true);

    let opacity = 1;
    if (transparency === 1) opacity = 0.75;
    else if (transparency === 2) opacity = 0.5;
    else if (transparency === 3) opacity = 0.25;
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees, StandardFonts, rgb } = PDFLib;
    const pdfDoc = await PDFDocument.create();
    const doc = await PdfLibDocument(uploadedFiles.current[0]);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    let imageData = null;
    if (mode === 1) {
      if (!image) {
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = "no image file selected!";
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
      const imageByte = await fetch(image.src).then((res) => res.arrayBuffer());
      try {
        imageData = await pdfDoc.embedPng(imageByte);
      } catch {
        try {
          imageData = await pdfDoc.embedJpg(imageByte);
        } catch (err) {
          setDisableDownloadButton(false);
          setShowNotification(false);
          notificationMessage.current = "Only JPEG and PNG image allowed";
          notificationType.current = "error";
          setShowNotification(true);
          return;
        }
      }
    } else {
      if (text.length <= 0) {
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = "No text provided!";
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
    }

    for (let index = 0; index < copiedPages.length; index++) {
      const page = copiedPages[index];

      if (index + 1 >= lowPage && index + 1 <= highPage) {
        const { width, height } = page.getSize();

        if (mode === 0) {
          if (mosaic) {
            for (const x of [
              10,
              width / 2 - (10 * text.length) / 2,
              width - 10 * text.length,
            ]) {
              for (const y of [height - 30, height / 2 - 10, 10]) {
                if (mode === 0) {
                  page.drawText(`${text}`, {
                    x: x,
                    y: y,
                    opacity: opacity,
                    size: 20,
                  });
                }
              }
            }
          } else {
            let offsetX, offsetY;

            if (position >= 0 && position <= 2) offsetY = height - 30;
            else if (position >= 6 && position <= 8) offsetY = 10;
            else offsetY = height / 2 - 10;
            if (position % 3 === 0) offsetX = 10;
            else if (position % 3 === 1)
              offsetX = width / 2 - (10 * text.length) / 2;
            else offsetX = width - 10 * text.length;

            page.drawText(`${text}`, {
              x: offsetX,
              y: offsetY,
              opacity: opacity,
              size: 20,
            });
          }
        } else {
          if (mosaic) {
            for (const x of [
              10,
              width / 2 - image.naturalWidth / 2,
              width - image.naturalWidth,
            ]) {
              for (const y of [
                height - image.naturalHeight,
                height / 2 - image.naturalHeight / 2,
                10,
              ]) {
                page.drawImage(imageData, {
                  x: x,
                  y: y,
                  opacity: opacity,
                });
              }
            }
          } else {
            let offsetX, offsetY;

            if (position >= 0 && position <= 2)
              offsetY = height - image.naturalHeight;
            else if (position >= 6 && position <= 8) offsetY = 10;
            else offsetY = height / 2 - image.naturalHeight / 2;
            if (position % 3 === 0) offsetX = 10;
            else if (position % 3 === 1)
              offsetX = width / 2 - image.naturalWidth / 2;
            else offsetX = width - image.naturalWidth;

            page.drawImage(imageData, {
              x: offsetX,
              y: offsetY,
              opacity: opacity,
            });
          }
        }
      }

      pdfDoc.addPage(page);
    }
    setShowNotification(false);
    notificationMessage.current = "file will be downloaded soon";
    notificationType.current = "info";
    setShowNotification(true);

    const signedPDFFile = await pdfDoc.save();
    downloadDocument(
      signedPDFFile,
      "WatermarkPdf.pdf",
      "application/pdf",
      aRef
    );
    setDisableDownloadButton(false);
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
  };

  return (
    <>
      <WatermarkPdfStyle>
        {order !== null && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            order={order}
            position={position}
            mosaic={mosaic}
            lowPage={lowPage}
            highPage={highPage}
          />
        )}
      </WatermarkPdfStyle>
      <RightSection
        mode={mode}
        modeHandler={modeHandler}
        text={text}
        textHandler={setText}
        image={image}
        imageHandler={setImage}
        position={position}
        positionHandler={setPosition}
        mosaic={mosaic}
        mosaicHandler={setMosaic}
        lowPage={lowPage}
        setLowPage={setLowPage}
        highPage={highPage}
        setHighPage={setHighPage}
        pageCount={pdfPages.current.length}
        transparency={transparency}
        transparencyHandler={setTransparency}
      />
      <DownloadButton
        onDownloadClick={downloadHandler}
        text="Continue"
        buttonPosition="dynamic_bottom"
      />
      <a ref={aRef} />
    </>
  );
};

export default WatermarkPdf;

const WatermarkPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
