import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
  downloadDocument,
} from "../../../helpers/utils";
import useSplit from "../../../hooks/useSplit";
import DownloadButton from "../../DownloadSection/DownloadButton";
import Container from "./Container";
import RightSection from "./RightSection";
import JSZip from "jszip";
import { NotificationContext } from "../../../context/NotificationContext";

const SplitPdf = ({ files, setUploadedFiles }) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const [order, setOrder] = useState(null); //number
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  const {
    selectRange,
    selectCustomRange,
    selectFixedRange,
    selectPage,
    selectAllPage,
    selectCustomPage,
    splitByRangehandler,
    customRangeHandler,
    fixedRangeHandler,
    splitByPageHandler,
    allPageHandler,
    customPageHandler,

    customRangeList,
    setCustomRangeListHandler,
    fixedRangeList,
    setFixedRangeListHandler,
    extractPageList,
    setExtractPageListHandler,

    isSplitPdfValid,
  } = useSplit();

  useEffect(() => {
    const initializeStates = async () => {
      try {
        const pdfName = await getFileName(files[0]);
        const doc = await PdfjsDocument(files[0]);
        const pages = await pdfjsExtractPages(
          doc,
          [...Array(doc.numPages).keys()].map((e) => e + 1)
        );
        pdfNames.current.push(pdfName);
        pdfPages.current.push(...pages);
      } catch (err) {
        setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
      uploadedFiles.current.push(files[0]);
      setOrder(uploadedFiles.current.length - 1);
    };
    initializeStates();
  }, [files]);

  const downloadHandler = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "splitting in progress";
    notificationType.current = "info";
    setShowNotification(true);
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees } = PDFLib;
    const doc = await PdfLibDocument(uploadedFiles.current[0]);
    const pdfPool = [];

    if (selectRange) {
      let ranges = [];
      if (selectCustomRange) ranges = customRangeList;
      else {
        for (
          let left = 1;
          left <= pdfPages.current.length;
          left += fixedRangeList
        ) {
          let right = Math.min(
            pdfPages.current.length,
            left + fixedRangeList - 1
          );
          ranges.push([left, right]);
        }
      }

      for (const range of ranges) {
        const pdfDoc = await PDFDocument.create();
        const copiedPages = await pdfDoc.copyPages(
          doc,
          [...Array(range[1] - range[0] + 1).keys()].map(
            (e, idx) => range[0] + idx - 1
          )
        );
        copiedPages.forEach((page) => {
          pdfDoc.addPage(page);
        });
        const pdfFile = await pdfDoc.save();
        pdfPool.push(pdfFile);
      }
    } else {
      let selected = new Array(pdfPages.current.length).fill(false);
      if (selectCustomPage) {
        const parts = extractPageList.split(",");
        for (const part of parts) {
          const range = part.split("-");
          if (
            range.length === 1 &&
            parseInt(range[0]) >= 1 &&
            parseInt(range[0]) <= pdfPages.current.length
          )
            selected[parseInt(range[0]) - 1] = true;
          else {
            for (
              let index = parseInt(range[0]);
              index <= parseInt(range[1]) &&
              index >= 1 &&
              index <= pdfPages.current.length;
              index++
            ) {
              selected[index - 1] = true;
            }
          }
        }
      } else {
        for (let index = 1; index <= pdfPages.current.length; index++)
          selected[index - 1] = true;
      }

      for (let index = 0; index < pdfPages.current.length; index++) {
        if (selected[index]) {
          const pdfDoc = await PDFDocument.create();
          const copiedPages = await pdfDoc.copyPages(doc, [index]);
          copiedPages.forEach((page) => {
            pdfDoc.addPage(page);
          });
          const pdfFile = await pdfDoc.save();
          pdfPool.push(pdfFile);
        }
      }
    }

    setShowNotification(false);
    notificationMessage.current = "download will start soon";
    notificationType.current = "info";
    setShowNotification(true);

    const zip = new JSZip();
    const pdfs = zip.folder("Split_PDF");
    for (let index = 0; index < pdfPool.length; index++) {
      pdfs.file(`splitPDF_${index + 1}`, pdfPool[index], { base64: true });
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      downloadDocument(content, "splitPdf.zip", "application/zip", aRef);
    });
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
    setDisableDownloadButton(false);
  };

  return (
    <>
      <SplitPdfStyle>
        {order !== null && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            splitMode={
              selectRange
                ? selectCustomRange
                  ? "custom_range"
                  : "fixed_range"
                : selectCustomPage
                ? "custom_page"
                : "all_page"
            }
            list={
              selectRange
                ? selectCustomRange
                  ? customRangeList
                  : fixedRangeList
                : selectCustomPage
                ? extractPageList
                : "all"
            }
          />
        )}
      </SplitPdfStyle>
      <RightSection
        numPages={pdfPages.current.length}
        selectRange={selectRange}
        selectCustomRange={selectCustomRange}
        selectFixedRange={selectFixedRange}
        selectPage={selectPage}
        selectAllPage={selectAllPage}
        selectCustomPage={selectCustomPage}
        splitByRangehandler={splitByRangehandler}
        customRangeHandler={customRangeHandler}
        fixedRangeHandler={fixedRangeHandler}
        splitByPageHandler={splitByPageHandler}
        allPageHandler={allPageHandler}
        customPageHandler={customPageHandler}
        customRangeList={customRangeList}
        setCustomRangeListHandler={setCustomRangeListHandler}
        fixedRangeList={fixedRangeList}
        setFixedRangeListHandler={setFixedRangeListHandler}
        extractPageList={extractPageList}
        setExtractPageListHandler={setExtractPageListHandler}
        isSplitPdfValid={isSplitPdfValid}
      />
      <DownloadButton
        onDownloadClick={downloadHandler}
        text="Split PDF"
        buttonPosition="dynamic-bottom"
      />
      <a ref={aRef} />
    </>
  );
};

export default SplitPdf;

const SplitPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
