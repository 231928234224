import { useContext } from "react";
import GoogleLogin from "react-google-login";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../hooks/useAuth";
import Form from "./Form";
import classes from "./SignupForm.module.css";

const SignupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const onSuccess = (loginResponse) => {
    // console.log("success");
    const access_token = loginResponse.accessToken;

    fetch(`${process.env.REACT_APP_REST_API_ENDPOINT}/google/`, {
      method: "POST",
      body: JSON.stringify({
        access_token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log("onsuccess from login", json);
        auth.signin(json.username, () => {
          navigate(-1);
        });
      });
  };

  const onFailure = (response) => {
    // console.log("failure");
  };

  return (
    <div className={classes.signupForm}>
      <h3 className={classes.title}>Sign up</h3>
      <div className={classes.googleSignUp}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img
                src="/assets/images/googleSignUp.webp"
                alt="google sign up"
              />
            </button>
          )}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      <div className={classes.divider}>
        <hr />
        <p>Or Sign up with Email</p>
        <hr />
      </div>
      <div className={classes.form}>
        <Form />
      </div>
      <div className={classes.signup}>
        <div className={classes.haveAccount}>
          <p>Already have an account?</p>
        </div>
        <div className={classes.link}>
          <Link to="/account/login">
            <p>Sign in</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
