import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
} from "../../../helpers/utils";
import arrayMove from "array-move";
import Container from "./Container";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import RightSection from "./RightSection";
import DownloadButton from "../../DownloadSection/DownloadButton";
import { NotificationContext } from "../../../context/NotificationContext";

let aRef;
const CompressPdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //2d array
  const [order, setOrder] = useState([]); //1d array
  const [selectedType, setSelectedType] = useState(0);
  aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      const newFiles = [];
      const newNames = [];
      const newPages = [];
      const newOrder = [];
      for (const file of files) {
        try {
          const pdfName = await getFileName(file);
          const doc = await PdfjsDocument(file);
          const pages = await pdfjsExtractPages(doc, [1]);

          newFiles.push(file);
          newNames.push(pdfName);
          newPages.push(pages);
          newOrder.push(uploadedFiles.current.length + newFiles.length - 1);
        } catch (err) {
          setShowNotification(false);
          notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
          notificationType.current = "error";
          setShowNotification(true);
          return;
        }
      }
      for (const item of newFiles) {
        uploadedFiles.current.push(item);
      }
      for (const item of newNames) {
        pdfNames.current.push(item);
      }
      for (const item of newPages) {
        pdfPages.current.push(item);
      }
      setOrder((prev) => [...prev, ...newOrder]);
    };
    initializeStates();
  }, [files]);

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder((oldOrder) => arrayMove(oldOrder, oldIndex, newIndex));
  };
  const deleteHandler = (pdfIndex, pageIndex, orderIndex) => {
    uploadedFiles.current.splice(pdfIndex, 1, null);
    pdfNames.current.splice(pdfIndex, 1, null);
    pdfPages.current.splice(pdfIndex, 1, null);
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
  };

  const onDownloadClick = () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Uploading files";
    notificationType.current = "info";
    setShowNotification(true);

    const formData = new FormData();

    let index = 0;
    for (const ord of order) {
      formData.append(`file_${index}`, uploadedFiles.current[ord]);
      index += 1;
    }

    formData.append("fileSize", index);
    formData.append("shouldMerge", 1 ^ selectedType);

    fetch(`${process.env.REACT_APP_REST_API_ENDPOINT}/tools/pdf-compress/`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (!data.link) throw new Error("Internal server error");

        const blob = await fetch(data.link).then((r) => r.blob());
        setShowNotification(false);
        notificationMessage.current = "file will be downloaded soon";
        notificationType.current = "info";
        setShowNotification(true);
        if (selectedType === 0)
          downloadDocument(
            blob,
            "compressedMerged.pdf",
            "application/pdf",
            aRef
          );
        else
          downloadDocument(blob, "compressedPDF.zip", "application/zip", aRef);
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current = "downloaded!";
        notificationType.current = "success";
        setShowNotification(true);
      })
      .catch((err) => {
        setDisableDownloadButton(false);
        setShowNotification(false);
        notificationMessage.current =
          err?.message || "Pdf can not be compressed";
        notificationType.current = "error";
        setShowNotification(true);
      });
  };

  return (
    <>
      <CompressPdfStyle>
        {order?.length > 0 && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            order={order}
            onSortEnd={sortEndHandler}
            onDelete={deleteHandler}
            axis={"xy"}
            distance={5}
          />
        )}
      </CompressPdfStyle>

      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />

      <RightSection
        selectedType={selectedType}
        selectTypeHandler={setSelectedType}
      />
      <DownloadButton
        onDownloadClick={onDownloadClick}
        text="Compress PDF"
        buttonPosition="bottom-dynamic"
      />
      <a ref={aRef} />
    </>
  );
};
export default CompressPdf;

const CompressPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
