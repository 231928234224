/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Button,
  IconButton,
  Radio,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import Modal from "../../UI/Modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef, useState } from "react";
import RenderImage from "../../UI/Canvas/RenderImage";
import { createImageBuffer } from "../../../helpers/utils";
import { Colors, SignFonts } from "../../../helpers/statics";
import CanvasDraw from "react-canvas-draw";
import { ReactComponent as SignText } from "../../../assets/images/signText.svg";
import { ReactComponent as SignDraw } from "../../../assets/images/signDraw.svg";
import { ReactComponent as UploadSign } from "../../../assets/images/uploadSign.svg";

const Input = styled("input")({
  display: "none",
});

const AddSignModal = ({ onClose, onSignUpload }) => {
  const [text, setText] = useState("");
  const [selectedFontFamily, setSelectedFontFamily] = useState(null);
  const [activeOption, setActiveOption] = useState(0);
  const [uploadedSign, setUploadedSign] = useState(null);
  const [selectedColor, setSelectedColor] = useState(Colors[0]);
  const selectedOption = useRef(null);
  const [brushRadius, setBrushRadius] = useState(1);
  const drawRef = useRef(null);
  const previewTextRef = useRef([]);
  const hiddenCanvasRef = useRef(null);
  previewTextRef.current = [...Array(SignFonts.length)].map((e) => null);
  const smallDevice = useMediaQuery("(max-width:500px)");

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    disableDownloadButton,
    setDisableDownloadButton,
  } = notificationContext;

  const uploadSignHandler = async (event) => {
    if (event.target.files.length > 0) {
      setDisableDownloadButton(true);
      let imageBuffer;
      try {
        setShowNotification((prev) => false);
        notificationMessage.current = "Uploading image...";
        notificationType.current = "info";
        setShowNotification(true);

        imageBuffer = await createImageBuffer(event.target.files);

        setShowNotification((prev) => false);
        notificationMessage.current = "Upload done";
        notificationType.current = "success";
        setShowNotification(true);
        setUploadedSign(imageBuffer);
        setDisableDownloadButton(false);
      } catch (err) {
        setShowNotification((prev) => false);
        notificationMessage.current = err?.message || "Failed to upload image!";
        notificationType.current = "error";
        setShowNotification(true);
        setDisableDownloadButton(false);
      }
    }
  };
  const downloadHandler = async () => {
    if (activeOption === 0) {
      const height =
        previewTextRef.current[selectedOption.current].getBoundingClientRect()
          .height;
      const width =
        previewTextRef.current[selectedOption.current].getBoundingClientRect()
          .width;
      const canvas = hiddenCanvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.height = height;
      canvas.width = width + 30;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = `30px ${selectedFontFamily}`;
      ctx.fillStyle = selectedColor;
      ctx.fillText(text, 15, canvas.height / 2 + 5);
      const imageBuffer = canvas.toDataURL("image/png");
      const image = new Image();
      image.src = imageBuffer;
      onSignUpload({ type: "signText", file: image, visible: true });
    } else if (activeOption === 1) {
      onSignUpload({ type: "signImage", file: uploadedSign[0], visible: true });
    } else if (activeOption === 2) {
      const signData = drawRef.current.getDataURL("image/png");
      const image = new Image();
      image.src = signData;
      onSignUpload({ type: "signImage", file: image, visible: true });
    }
    onClose();
  };

  const handleChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedColor === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <>
      <Modal open={true}>
        <AddSignModalStyle>
          <HeaderStyle>
            <Typography
              variant="p"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "19px",
                color: "#818181",
              }}
            >
              Create signature
            </Typography>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </HeaderStyle>
          <UploadOption>
            <Button
              startIcon={
                <SignText
                  fill={`${
                    activeOption === 0
                      ? "url(#paint0_linear_356_156)"
                      : "#818181"
                  }`}
                />
              }
              onClick={() => setActiveOption(0)}
            >
              <Typography
                textTransform="none"
                sx={{
                  fontSize: { xs: ".9rem", md: "1rem" },
                  background:
                    activeOption === 0
                      ? "-webkit-linear-gradient(90.07deg, #EE0979 5.77%, #FF6A00 106.28%)"
                      : "#818181",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Type
              </Typography>
            </Button>

            <label
              htmlFor="icon-button-file"
              style={{
                cursor: disableDownloadButton ? "not-allowed" : "pointer",
              }}
            >
              <Input
                disabled={disableDownloadButton}
                accept="image/*"
                id="icon-button-file"
                type="file"
                sx={{ display: "none" }}
                onChange={(event) => {
                  uploadSignHandler(event);
                }}
                onClick={() => setActiveOption(1)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <UploadSign
                  fill={`${
                    activeOption === 1
                      ? "url(#paint0_linear_356_156)"
                      : "#818181"
                  }`}
                />
                <Typography
                  textTransform="none"
                  component="p"
                  sx={{
                    fontSize: { xs: ".9rem", md: "1rem" },
                    lineHeight: { xs: "1.2" },
                    background:
                      activeOption === 1
                        ? "-webkit-linear-gradient(90.07deg, #EE0979 5.77%, #FF6A00 106.28%)"
                        : "#818181",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Upload
                </Typography>
              </Box>
            </label>
            <Button
              startIcon={
                <SignDraw
                  fill={`${
                    activeOption === 2
                      ? "url(#paint0_linear_356_156)"
                      : "#818181"
                  }`}
                />
              }
              onClick={() => setActiveOption(2)}
            >
              <Typography
                textTransform="none"
                sx={{
                  fontSize: { xs: ".9rem", md: "1rem" },
                  background:
                    activeOption === 2
                      ? "-webkit-linear-gradient(90.07deg, #EE0979 5.77%, #FF6A00 106.28%)"
                      : "#818181",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Draw
              </Typography>
            </Button>
          </UploadOption>
          <UploadSection>
            {activeOption === 0 && (
              <>
                <TextField
                  id="text-field"
                  variant="outlined"
                  size="small"
                  placeholder="Type your signature"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  sx={{ width: "80%", maxWidth: "400px" }}
                />
                <ColorPicker>
                  {Colors.map((color, index) => (
                    <Radio
                      key={index}
                      {...controlProps(color)}
                      sx={{
                        color: color,
                        "&.Mui-checked": {
                          color: color,
                        },
                      }}
                    />
                  ))}
                </ColorPicker>
                <PreviewText>
                  {SignFonts.map((font, index) => (
                    <Box
                      ref={(el) => (previewTextRef.current[index] = el)}
                      key={index}
                      sx={{
                        padding: "2px 10px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, .05)",
                        },
                        backgroundColor:
                          selectedFontFamily === font
                            ? "rgba(0, 0, 0, .15)"
                            : "none",
                      }}
                      onClick={() => {
                        selectedOption.current = index;
                        setSelectedFontFamily(font);
                      }}
                    >
                      <Typography
                        sx={{
                          padding: "0px",
                          fontWeight: "400",
                          fontStyle: "normal",
                          fontSize: "20px",
                          fontFamily: font,
                          color: selectedColor,
                        }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  ))}
                </PreviewText>
              </>
            )}

            {uploadedSign && activeOption === 1 && (
              <RenderImage
                image={uploadedSign[0]}
                height="200"
                width="300"
                rotation={0}
              />
            )}
            {activeOption === 2 && (
              <>
                <Box
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: { xs: "flex-start", sm: "center" },
                    alignItems: "center",
                    gap: { xs: "5px", sm: "20px" },
                  }}
                >
                  <ColorPicker>
                    {Colors.map((color, index) => (
                      <Radio
                        key={index}
                        {...controlProps(color)}
                        sx={{
                          color: color,
                          "&.MuiRadio-root": {
                            padding: "0px 4px",
                          },
                          "&.Mui-checked": {
                            color: color,
                          },
                        }}
                      />
                    ))}
                  </ColorPicker>

                  <Slider
                    value={brushRadius}
                    onChange={(event, newVal) => setBrushRadius(newVal)}
                    aria-labelledby="input-slider"
                    size="small"
                    min={1}
                    max={10}
                    sx={{
                      width: "120px",
                      padding: "10px 0px !important",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    border: "1px solid #bbb2b2",
                    margin: "1px",
                  }}
                >
                  <CanvasDraw
                    ref={drawRef}
                    hideGrid={true}
                    canvasWidth={smallDevice ? 280 : 400}
                    canvasHeight={smallDevice ? 170 : 300}
                    brushColor={selectedColor}
                    brushRadius={brushRadius}
                    lazyRadius={0}
                  />
                </Box>
                <Button onClick={() => drawRef.current.clear()}>clear</Button>
              </>
            )}
          </UploadSection>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              bottom: "0px",
              background: "#FF6429",
              padding: "4px 16px",
              "&:hover": {
                background: "red",
              },
              textTransform: "none",
              marginBottom: "10px",
            }}
            onClick={downloadHandler}
            disabled={
              (activeOption === 0 &&
                (text.length <= 0 || selectedFontFamily === null)) ||
              (activeOption === 1 && !uploadedSign)
            }
          >
            Save
          </Button>
        </AddSignModalStyle>
      </Modal>
      <canvas
        ref={hiddenCanvasRef}
        style={{
          visibility: "hidden",
        }}
      />
    </>
  );
};

export default AddSignModal;

const AddSignModalStyle = styled.div`
  max-height: 80vh;
  max-width: 600px;
  width: 95vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`;
const HeaderStyle = styled.div`
  width: 100%;
  height: 50px;
  padding: 0px 50px;
  box-sizing: border-box;
  background-color: #ebf3fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 10;
  @media (max-width: 899px) {
    padding: 0px 20px;
    height: 40px;
  }
`;

const UploadOption = styled.div`
  margin-top: 55px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 899px) {
    margin-top: 45px;
  }
`;

const UploadSection = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const ColorPicker = styled.div`
  margin: 5px 0px;
`;
const PreviewText = styled.div`
  box-sizing: border-box;
  margin: 5px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
