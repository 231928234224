import React, { useState } from "react";
import Cookies from "js-cookie";
export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const setSession = (session) => {
    Cookies.remove("session");
    Cookies.set("session", session);
  };

  const getSession = () => {
    const cookies = Cookies.get("session");
    if (cookies === undefined) return false;
    else return true;
  };

  const removeSession = () => {
    Cookies.remove("session");
  };

  const [hasSession, setHasSession] = useState(getSession());
  // const [userName, setUserName] = useState("");

  const signin = (newUserName, callback) => {
    // setUserName(newUserName);
    setSession(true);
    setHasSession(true);
    callback();
  };
  const signout = (callback) => {
    // setUserName("");
    removeSession();
    setHasSession(false);
    // callback();
  };

  return (
    <AuthContext.Provider value={{ hasSession, signin, signout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
