import styled from "styled-components";
import StayPrimaryLandscapeSharpIcon from "@mui/icons-material/StayPrimaryLandscapeSharp";
import StayPrimaryPortraitSharpIcon from "@mui/icons-material/StayPrimaryPortraitSharp";

const RotationIcon = ({
  index,
  selectedRotationType,
  setSelectedRotationTYpe,
}) => {
  let valid = false;
  if (index === 0 && selectedRotationType === 2) valid = true;
  else if (index === 1 && selectedRotationType === 0) valid = true;
  else if (index === 2 && selectedRotationType === 1) valid = true;
  let type = 1;
  if (index === 0) type = 2;
  else if (index === 1) type = 0;
  return (
    <IconBoxStyle
      onClick={() => setSelectedRotationTYpe(type)}
      style={{
        position: "relative",
        border: `2px solid ${valid ? "red" : "transparent"}`,
      }}
    >
      {index === 0 && (
        <>
          <StayPrimaryPortraitSharpIcon
            style={{
              color: `${valid ? "red" : "#818181"}`,
            }}
          />
          <StayPrimaryLandscapeSharpIcon
            style={{
              position: "absolute",
              left: "48%",
              color: `${valid ? "red" : "#818181"}`,
            }}
          />
        </>
      )}
      {index === 1 && (
        <StayPrimaryPortraitSharpIcon
          style={{
            color: `${valid ? "red" : "#818181"}`,
          }}
        />
      )}
      {index === 2 && (
        <StayPrimaryLandscapeSharpIcon
          style={{
            color: `${valid ? "red" : "#818181"}`,
          }}
        />
      )}
    </IconBoxStyle>
  );
};

export default RotationIcon;

const IconBoxStyle = styled.div`
  height: 70px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9edf7;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(147, 184, 217, 1);
`;
