import { useContext, useEffect, useRef, useState } from "react";
import JSZip from "jszip";
import {
  downloadDocument,
  getFileName,
  PdfjsDocument,
  pdfjsExtractPages,
  PdfLibDocument,
} from "../../../helpers/utils";
import styled from "styled-components";
import Container from "./Container";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import DownloadButton from "../../DownloadSection/DownloadButton";
import RightSection from "./RightSection";
import { NotificationContext } from "../../../context/NotificationContext";

const RotatePdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const pdfNames = useRef([]); //1d array
  const pdfPages = useRef([]); //1d array
  const pdfOrientation = useRef([]); //1d array
  const currentOrientation = useRef([]); //1d array
  const [order, setOrder] = useState([]); //1d array
  const [rotations, setRotations] = useState([]); //1d array
  const [selectedRotationType, setSelectedRotationTYpe] = useState(null); //integer
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      const newFiles = [];
      const newPdfNames = [];
      const newPages = [];
      const newOrder = [];
      const newRotations = [];
      const newPdfOrientation = [];
      const newCurrentOrientation = [];

      for (const file of files) {
        try {
          const pdfName = await getFileName(file);
          const doc = await PdfjsDocument(file);
          const pages = await pdfjsExtractPages(doc, [1]);
          const viewport = pages[0].getViewport({ scale: 1 });
          if (viewport.height >= viewport.width) {
            newPdfOrientation.push(0);
            newCurrentOrientation.push(0);
          } else {
            newPdfOrientation.push(1);
            newCurrentOrientation.push(1);
          }

          newFiles.push(file);
          newPdfNames.push(pdfName);
          newPages.push(pages);
          newRotations.push(0);
          newOrder.push(uploadedFiles.current.length + newFiles.length - 1);
        } catch (err) {
          setShowNotification(false);
          notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
          notificationType.current = "error";
          setShowNotification(true);
          return;
        }
      }
      for (const item of newFiles) {
        uploadedFiles.current.push(item);
      }
      for (const item of newPdfNames) {
        pdfNames.current.push(item);
      }
      for (const item of newPages) {
        pdfPages.current.push(item);
      }
      for (const item of newPdfOrientation) {
        pdfOrientation.current.push(item);
      }
      for (const item of newCurrentOrientation) {
        currentOrientation.current.push(item);
      }
      setRotations((prev) => [...prev, ...newRotations]);
      setOrder((prev) => [...prev, ...newOrder]);
    };
    initializeStates();
  }, [files]);

  const pageRotateHandler = (pdfIndex, pageIndex, delta) => {
    currentOrientation.current[pdfIndex] ^= 1;
    setRotations((prev) => {
      const cpy = [...prev];
      const target = cpy[pdfIndex];
      const newVal = (((target + delta) % 4) + 4) % 4;
      cpy.splice(pdfIndex, 1, newVal);
      return cpy;
    });
  };

  const deleteHandler = (pdfIndex, pageIndex, orderIndex) => {
    uploadedFiles.current.splice(pdfIndex, 1, null);
    pdfNames.current.splice(pdfIndex, 1, null);
    pdfPages.current.splice(pdfIndex, 1, null);
    pdfOrientation.current.splice(pdfIndex, 1, null);
    currentOrientation.current.splice(pdfIndex, 1, null);
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
    setRotations((prev) => {
      const oldRotations = [...prev];
      oldRotations.splice(pdfIndex, 1, null);
      return oldRotations;
    });
  };

  const resetHandler = () => {
    const newRotations = [];
    const newOrder = [];
    [...uploadedFiles.current].map((file, index) => {
      if (file) {
        newRotations.push(0);
        newOrder.push(index);
      } else newRotations.push(null);
    });

    setSelectedRotationTYpe(null);
    setOrder(newOrder);
    setRotations(newRotations);
    currentOrientation.current = pdfOrientation.current;
  };

  const wholeRotationHandler = (delta) => {
    currentOrientation.current = [...currentOrientation.current].map(
      (orient) => {
        if (orient === null) return null;
        return orient ^ 1;
      }
    );
    setRotations((prev) => {
      const newRotations = [...prev].map((old) => {
        if (old === null) return null;
        return (((old + delta) % 4) + 4) % 4;
      });
      return newRotations;
    });
  };

  const portraitRotationHandler = (delta) => {
    const copiedOrientation = currentOrientation.current;
    currentOrientation.current = [...currentOrientation.current].map(
      (orient) => {
        if (orient === null) return null;
        return 1;
      }
    );
    setRotations((prev) => {
      const newRotations = [...prev].map((old, index) => {
        if (old === null) return null;
        if (copiedOrientation[index] === 0)
          return (((old + delta) % 4) + 4) % 4;
        return old;
      });
      return newRotations;
    });
  };

  const landscapeRotateHandler = (delta) => {
    const copiedOrientation = currentOrientation.current;
    currentOrientation.current = [...currentOrientation.current].map(
      (orient) => {
        if (orient === null) return null;
        return 0;
      }
    );
    setRotations((prev) => {
      const newRotations = [...prev].map((old, index) => {
        if (old === null) return null;
        if (copiedOrientation[index] === 1)
          return (((old + delta) % 4) + 4) % 4;
        return old;
      });
      return newRotations;
    });
  };

  const onDownloadClick = async () => {
    setDisableDownloadButton(true);
    setShowNotification(false);
    notificationMessage.current = "Rotation in progress";
    notificationType.current = "info";
    setShowNotification(true);
    // eslint-disable-next-line no-undef
    const { PDFDocument, degrees } = PDFLib;
    const rotatedPdfs = [];
    const rotatedPdfnames = [];

    for (const i of order) {
      try {
        const pdfDoc = await PDFDocument.create();
        const doc = await PdfLibDocument(uploadedFiles.current[i]);
        const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());
        copiedPages.forEach((page) => {
          // eslint-disable-next-line no-undef
          page.setRotation(degrees(rotations[i] * 90));
          pdfDoc.addPage(page);
        });
        const rotatedFile = await pdfDoc.save();
        rotatedPdfs.push(rotatedFile);
        rotatedPdfnames.push(`rotated_${pdfNames.current[i]}`);
      } catch (err) {
        setShowNotification(false);
        notificationMessage.current = "Something went wrong!";
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
    }

    setShowNotification(false);
    notificationMessage.current = "file(s) will be downloaded soon";
    notificationType.current = "info";
    setShowNotification(true);

    if (rotatedPdfs.length > 1) {
      const zip = new JSZip();
      for (let i = 0; i < rotatedPdfs.length; i++) {
        zip.file(rotatedPdfnames[i], rotatedPdfs[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then(function (content) {
        downloadDocument(content, "rotatePdf.zip", "application/zip", aRef);
      });
    } else {
      downloadDocument(
        rotatedPdfs[0],
        rotatedPdfnames[0],
        "application/pdf",
        aRef
      );
    }
    setDisableDownloadButton(false);
    setShowNotification(false);
    notificationMessage.current = "downloaded!";
    notificationType.current = "success";
    setShowNotification(true);
  };

  return (
    <>
      <RotatePdfStyle>
        {order?.length > 0 && (
          <Container
            pdfNames={pdfNames.current}
            pages={pdfPages.current}
            rotations={rotations}
            order={order}
            onRotate={pageRotateHandler}
            onDelete={deleteHandler}
          />
        )}
      </RotatePdfStyle>
      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />
      <RightSection
        onReset={resetHandler}
        selectedRotationType={selectedRotationType}
        setSelectedRotationTYpe={setSelectedRotationTYpe}
        wholeRotationHandler={wholeRotationHandler}
        portraitRotationHandler={portraitRotationHandler}
        landscapeRotateHandler={landscapeRotateHandler}
        numPdfs={pdfNames.current.filter((e) => e !== null).length}
      />

      <DownloadButton onDownloadClick={onDownloadClick} text="Rotate PDF" />
      <a ref={aRef} />
    </>
  );
};

export default RotatePdf;

const RotatePdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
