import PdfViewer from "../../common/PdfViewer";
import PdfCard from "../../UI/Card/PdfCard";
import styled from "styled-components";

const Container = ({
  pdfNames,
  pages,
  order,
  hasCoverPage,
  position,
  marginType,
  lowPage,
  highPage,
}) => {
  let top = null,
    bottom = null,
    left = null,
    right = null;
  if (position >= 0 && position <= 2) {
    if (marginType === 0) top = "0px";
    else if (marginType === 1) top = "10px";
    else top = "20px";
  } else if (position >= 6 && position <= 8) {
    if (marginType === 0) bottom = "0px";
    else if (marginType === 1) bottom = "10px";
    else bottom = "20px";
  } else {
    top = "calc( 50% - 8px)";
  }

  if (position % 3 === 0) {
    if (marginType === 0) left = "0px";
    else if (marginType === 1) left = "10px";
    else left = "20px";
  } else if (position % 3 === 2) {
    if (marginType === 0) right = "0px";
    else if (marginType === 1) right = "10px";
    else right = "20px";
  } else {
    left = "calc( 50% - 8px)";
  }

  return (
    <ContainerWrapperStyle>
      {order.map((ord, index) => (
        <PdfCard height="300px" width="250px" cursor="auto">
          <PdfViewer
            page={pages[ord]}
            pageInfo={ord + 1}
            canvasHeight="230"
            canvasWidth="200"
            pageNumberPosition={
              ord + 1 >= lowPage &&
              ord + 1 <= highPage &&
              !(hasCoverPage && index === 0)
                ? [
                    {
                      left: left,
                      right: right,
                      top: top,
                      bottom: bottom,
                    },
                  ]
                : null
            }
          />
        </PdfCard>
      ))}
    </ContainerWrapperStyle>
  );
};

export default Container;

const ContainerWrapperStyle = styled.div`
  width: 100%;
  padding: 10px 10px 50px 10px;
  box-sizing: border-box;
  background-color: #f3f0ec;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-content: center;
  gap: 35px;
`;
