import { useState } from "react";
import SplitPdf from "../components/Tools/SplitPdf/SplitPdf";
import ToolInfos from "../components/Tools/ToolInfos";
import InitialUpload from "../components/UploadOptions/InitialUpload";
import Layout from "../Layouts/Layout";

const SplitPdfPage = () => {
  const infos = ToolInfos.splitPdf;
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const title = infos.title;
  const description = infos.description;
  const isMultipleAllowed = infos.isMultipleAllowed;
  const fileType = infos.fileType;

  return (
    <Layout>
      {!uploadedFiles ? (
        <InitialUpload
          title={title}
          description={description}
          isMultipleAllowed={isMultipleAllowed}
          fileType={fileType}
          setUploadedFiles={setUploadedFiles}
        />
      ) : (
        <SplitPdf
          files={uploadedFiles}
          isMultipleAllowed={isMultipleAllowed}
          fileType={fileType}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </Layout>
  );
};

export default SplitPdfPage;
