import DroppableComponent from "../../common/DroppableComponent";
import Box from "@mui/material/Box";

const Container = ({
  pdfNames,
  pdfPages,
  activeSignType,

  sign,
  signImagePerPage,
  onSignImageUpdate,
  onDeleteSignImage,

  textPerPage,
  onTextPositionUpdate,
  onTextUpdate,
  containerRef,
  canvasRefs,
  onDropText,
  onDeleteText,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#e2e7eb",
        p: "40px 40px 100px 40px",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "column wrap",
        gap: "20px",
        overflow: "auto",
        "@media(min-width: 1000px)": {
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      ref={(el) => {
        containerRef.current = el;
      }}
    >
      {pdfPages.map((page, index) => (
        <DroppableComponent
          key={index}
          index={index}
          page={page}
          canvasRefs={canvasRefs}
          containerRef={containerRef}
          sign={sign}
          signImageComponents={signImagePerPage[index]}
          textComponents={textPerPage[index]}
          onSignImageUpdate={onSignImageUpdate}
          onDeleteSignImage={onDeleteSignImage}
          onTextPositionUpdate={onTextPositionUpdate}
          onTextUpdate={onTextUpdate}
          activeSignType={activeSignType}
          onDropText={onDropText}
          onDeleteText={onDeleteText}
        />
      ))}
    </Box>
  );
};

export default Container;
