import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Input,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const RightSection = ({
  pageMode,
  changeMode,
  coverPage,
  setCoverPage,
  position,
  setPosition,
  marginType,
  setMarginType,
  lowPage,
  setLowPage,
  highPage,
  setHighPage,
  pageCount,
  hasCoverPage,
}) => {
  const [paginationPdfOpen, setPaginationPdfOpen] = useState(false);

  const handleDrawerToggle = () => {
    setPaginationPdfOpen(!paginationPdfOpen);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, position: "absolute", top: "65px", right: "10px" }}
      >
        <MenuIcon />
      </IconButton>

      <RightSectionStyle right={paginationPdfOpen}>
        <Box
          sx={{
            height: "25px",
            width: "25px",
            mt: 1,
            display: "none",
            cursor: "pointer",
            ml: 1,
            p: "8px",
            borderRadius: "50%",
            transition: "0.3s ease-in-out",
            "&:hover": {
              background: "#dedad9",
            },
            "@media(max-width: 900px)": { display: "inline-block" },
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </Box>
        <Typography
          variant="h1"
          lineHeight="35px"
          fontWeight="400"
          fontStyle="normal"
          textAlign="center"
          color="#FF6429"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "25px",
              lg: "25px",
              xl: "30px",
            },
            margin: {
              xs: "0px 0px 10px 0px",
              sm: "0px 0px 10px 0px",
              md: "16px 0px",
              lg: "18px 0px",
              xl: "20px 0px",
            },
          }}
        >
          PDF pagination
        </Typography>
        <Divider
          sx={{
            border: "1px solid #87C7FF",
            width: "100%",
          }}
        />
        <PageModeOptionStyle>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: "#818181",
              }}
              control={
                <Checkbox
                  checked={coverPage}
                  onChange={(event) => setCoverPage(!!event.target.checked)}
                />
              }
              label="First page is cover page"
            />
          </FormGroup>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "15px",
            }}
          >
            <SelectPositionStyle>
              <Typography
                variant="p"
                sx={{
                  color: "#FF6429",
                }}
              >
                POSITION
              </Typography>
              <Box
                sx={{
                  height: "75px",
                  width: "75px",
                  border: "1px solid black",
                  marginTop: "5px",
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {[...Array(9).keys()].map((index) => (
                  <BoxStyle
                    key={index}
                    index={index}
                    position={position}
                    onClick={() => {
                      if (index !== 3 && index !== 4 && index !== 5)
                        setPosition(index);
                    }}
                  />
                ))}
              </Box>
            </SelectPositionStyle>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                jusitfyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "#FF6429", marginBottom: "5px" }}
              >
                MARGIN
              </Typography>
              <TextField
                select
                value={marginType}
                onChange={(event) =>
                  setMarginType(parseInt(event.target.value))
                }
                sx={{
                  width: "160px",
                  "& .MuiInputBase-input": { padding: "6px" },
                }}
              >
                <MenuItem key={0} value={0}>
                  Small
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Recommended
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Big
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        </PageModeOptionStyle>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="p"
              sx={{ color: "#FF6429", marginBottom: "10px" }}
            >
              Which page do you want to number?
            </Typography>
            <InputSection>
              <Box
                sx={{
                  height: "44px",
                  width: "150px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  from page
                </Typography>
                <Input
                  type="number"
                  value={lowPage}
                  size="small"
                  onChange={(event) => {
                    const newVal = parseInt(event.target.value);
                    if (newVal > 0 && newVal <= pageCount && newVal <= highPage)
                      setLowPage(newVal);
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  height: "44px",
                  width: "90px",
                  backgroundColor: "#E1ECF5",
                  border: "1px solid #4A4A4A",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: ".3px",
                    color: "#4A4A4A",
                    padding: "0px 6px",
                  }}
                >
                  to
                </Typography>
                <Input
                  type="number"
                  value={highPage}
                  size="small"
                  onChange={(event) => {
                    const newVal = parseInt(event.target.value);
                    if (newVal > 0 && newVal <= pageCount && newVal >= lowPage)
                      setHighPage(newVal);
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                    boxShadow: "inset 1px 1px 3px 0 hsl(210deg 2% 49% / 20%)",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },

                    "& .MuiInput-input": {
                      textAlign: "end",
                      padding: "0px",
                    },

                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Box>
            </InputSection>
          </Box>
        </Box>
      </RightSectionStyle>
    </>
  );
};

export default RightSection;

const RightSectionStyle = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  right: 0px;
  top: 70px;
  position: fixed;
  box-sizing: border-box;
  background-color: #ebf3fa;
  border-left: 1px solid #87c7ff;
  overflow: hidden;
  overflow-y: auto;

  transition: ease all 0.5s;
  @media screen and (max-width: 1535px) {
    width: 350px;
  }
  @media screen and (max-width: 1199px) {
    width: 300px;
  }
  @media screen and (max-width: 899px) {
    height: calc(100vh - 50px);
    top: 50px;
    right: ${(props) => (props.right ? "0px" : "-300px")};
    z-index: 1000;
  }
`;

const PageModeOptionStyle = styled.div`
  padding: 30px 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

const SelectPositionStyle = styled.div``;

const BoxStyle = styled.div`
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.index < 6 ? "1px dashed #818181" : "none"};
  border-right: ${(props) =>
    props.index % 3 !== 2 ? "1px dashed #818181" : "none"};
  background-color: ${(props) =>
    props.position === props.index ? "red" : "none"};
  &:hover {
    background-color: ${(props) =>
      props.position !== props.index ? "rgba(0, 0, 0, 0.1)" : "none"};
  }
`;

const InputSection = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;
