import TextFieldsIcon from "@mui/icons-material/TextFields";
import { Button } from "@mui/material";

const TextButton = ({ activeSignType, onChangeActiveSignType }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<TextFieldsIcon />}
      disableElevation
      disableFocusRipple
      disableRipple
      sx={{
        textTransform: "none",
        height: "35px",
        width: "90px",
        borderRadius: "8px",
        borderColor: activeSignType === 0 ? "#FF6429" : "#818181",
        color: activeSignType === 0 ? "#FF6429" : "#818181",
        backgroundColor: "white",

        "&:hover": {
          backgroundColor: "white",
          borderColor: activeSignType === 0 ? "#FF6429" : "#818181",
        },
      }}
      onClick={() => {
        if (activeSignType === 1) onChangeActiveSignType(0);
      }}
    >
      Text
    </Button>
  );
};

export default TextButton;
