import { Box } from "@mui/system";
import { useState } from "react";

const PdfCard = ({ children, height, width, borderColor, cursor }) => {
  const [bgColor, setbgColor] = useState("#ebf3fa");
  let color = null;
  if (borderColor) color = `2px solid ${borderColor}`;
  return (
    <Box
      sx={{
        height: height ? height : "280px",
        width: width ? width : "200px",
        border: borderColor ? color : "none",
        boxSizing: "border-box",
        backgroundColor: bgColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0 8px 2px rgb(0 0 0 / 20%)",
        cursor: cursor ? cursor : "pointer",
      }}
      onMouseEnter={() => setbgColor("#ffffff")}
      onMouseLeave={() => setbgColor("#ebf3fa")}
    >
      {children}
    </Box>
  );
};

export default PdfCard;
