import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Container from "./Container";
import {
  createImageBuffer,
  downloadDocument,
  getFileName,
} from "../../../helpers/utils";
import arrayMove from "array-move";
import { NotificationContext } from "../../../context/NotificationContext";
import SecondaryUpload from "../../UploadOptions/SecondaryUpload";
import DownloadButton from "../../DownloadSection/DownloadButton";
import RightSection from "./RightSection";

const ImageToPdf = ({
  files,
  isMultipleAllowed,
  fileType,
  setUploadedFiles,
}) => {
  const uploadedFiles = useRef([]); //1d array
  const imageNames = useRef([]); //1d array
  const images = useRef([]); //1d array
  const [order, setOrder] = useState([]); //1d array
  const [rotations, setRotations] = useState([]); //1d array
  const [selectedType, setSelectedType] = useState(0); //merge all or not, currently not using
  const canvasRef = useRef(null);
  const aRef = useRef(null);

  const { notificationContext } = useContext(NotificationContext);
  const {
    setShowNotification,
    notificationType,
    notificationMessage,
    setDisableDownloadButton,
  } = notificationContext;

  useEffect(() => {
    const initializeStates = async () => {
      let names;
      let image;

      try {
        names = await getFileName(files);
        image = await createImageBuffer(files);
      } catch (err) {
        if (uploadedFiles.current.length === 0) setUploadedFiles(null);
        setShowNotification(false);
        notificationMessage.current = err?.message || "file upload failed!"; //need to provide more meaningful feedback
        notificationType.current = "error";
        setShowNotification(true);
        return;
      }
      uploadedFiles.current.push(...files);
      imageNames.current.push(...names);
      images.current.push(...image);

      setRotations((prev) => {
        return [...prev, ...[...Array(files.length).keys()].map((e) => 0)];
      });
      setOrder((prev) => {
        const len = prev.length;
        return [
          ...prev,
          ...[...Array(files.length).keys()].map((e) => e + len),
        ];
      });
    };
    initializeStates();
  }, [files]);

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder((array) => arrayMove(array, oldIndex, newIndex));
  };
  const rotateHandler = (imageIndex, delta) => {
    setRotations((prev) => {
      const cpy = [...prev];
      const newVal = (((cpy[imageIndex] + delta) % 4) + 4) % 4;
      cpy.splice(imageIndex, 1, newVal);
      return cpy;
    });
  };
  const deleteHandler = (imageIndex, orderIndex) => {
    uploadedFiles.current.splice(imageIndex, 1, null);
    imageNames.current.splice(imageIndex, 1, null);
    images.current.splice(imageIndex, 1, null);
    setOrder((prev) => {
      const oldOrder = [...prev];
      oldOrder.splice(orderIndex, 1);
      return oldOrder;
    });
    setRotations((prev) => {
      const oldRotations = [...prev];
      oldRotations.splice(imageIndex, 1, null);
      return oldRotations;
    });
  };

  const onDownloadClick = async () => {
    setDisableDownloadButton(true);
    // eslint-disable-next-line no-undef
    const pdfDoc = await PDFLib.PDFDocument.create();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    for (const index of order) {
      const image = images.current[index];
      const rot = rotations[index];
      const imageW = image.naturalWidth;
      const imageH = image.naturalHeight;

      canvas.height = imageH;
      canvas.width = imageW;

      if (rot === 0 || rot === 2) {
        canvas.height = imageH;
        canvas.width = imageW;
      } else {
        canvas.width = imageH;
        canvas.height = imageW;
      }
      if (rot !== 0) {
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rot * 90 * Math.PI) / 180);
        if (rot === 2) ctx.translate(-canvas.width / 2, -canvas.height / 2);
        else ctx.translate(-canvas.height / 2, -canvas.width / 2);
      }
      ctx.drawImage(image, 0, 0);
      const url = canvas.toDataURL("image/jpeg", 0.5);
      const pngBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pngImage = await pdfDoc.embedJpg(pngBytes);

      const page = pdfDoc.addPage(
        rot === 0 || rot === 2 ? [imageW, imageH] : [imageH, imageW]
      );
      await page.drawImage(pngImage, {
        x: 0,
        y: 0,
      });
    }

    const pdfFile = await pdfDoc.save();
    downloadDocument(pdfFile, "imageToPdf.pdf", "application/pdf", aRef);
    setDisableDownloadButton(false);
  };

  return (
    <>
      <ImageToPdfStyle>
        {order?.length > 0 && (
          <Container
            imageNames={imageNames.current}
            images={images.current}
            rotations={rotations}
            order={order}
            onSortEnd={sortEndHandler}
            onRotate={rotateHandler}
            onDelete={deleteHandler}
            axis={"xy"}
            distance={5}
          />
        )}
      </ImageToPdfStyle>

      <SecondaryUpload
        isMultipleAllowed={isMultipleAllowed}
        fileType={fileType}
        setUploadedFiles={setUploadedFiles}
      />
      <RightSection
        selectedType={selectedType}
        selectTypeHandler={setSelectedType}
      />
      <DownloadButton
        onDownloadClick={onDownloadClick}
        text="Continue"
        buttonPosition="bottom-dynamic"
      />
      <canvas
        ref={canvasRef}
        style={{
          visibility: "hidden",
          height: "0px",
          width: "0px",
          position: "absolute",
          top: "0px",
        }}
      />
      <a ref={aRef} />
    </>
  );
};

export default ImageToPdf;

const ImageToPdfStyle = styled.div`
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #f3f0ec;
  margin-right: 400px;
  padding: 60px 25px 30px 25px;
  @media screen and (max-width: 1535px) {
    margin-right: 350px;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 300px;
  }
  @media screen and (max-width: 899px) {
    margin-right: 0px;
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 350px) {
    padding: 30px 0px 60px 0px;
  }
`;
