import { Box, Divider, Typography } from "@mui/material";
import { fontSize, styled } from "@mui/system";
import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Privacy.css";

const PrivacyPage = () => {
  return (
    <>
      <Header />
      <Box sx={{ mt: 5, mb: 20, width: "60%", mx: "auto" }}>
        <PrivacyPolicyStyle>
          <PrivacyPolicySection>
            <Typography
              variant="h3"
              fontSize="42px"
              fontWeight="600"
              // textAlign="center"
              sx={{
                color: "#383e45",
                // margin: "100px auto 8px auto",
                textAlign: "center",
              }}
            >
              PRIVACY POLICY
            </Typography>
            <Typography
              variant="body1"
              fontStyle="italic"
              lineHeight="32px"
              fontSize="13px"
              sx={{
                marginBottom: "24px",
                color: "#555",
              }}
            >
              (Last Updated and Effective date : 02 July 2019)
            </Typography>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
              }}
            >
              {`Please read this Privacy Policy carefully to understand our policies
          and practices regarding your Personal Data (as defined below) and how
          we will treat it.This Privacy Policy applies to Inverse.AI's apps.
          This Privacy Policy explains how we, and affiliated companies
          (collectively, "Inverse.AI" or "we", "us", "our") collect, use,
          secure, and/or disclose end users’ (“you” or “your”) .`}
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                INFORMATION WE COLLECT AND HOW
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              While using our Services, we may ask you to provide us with
              certain personally identifiable information that can be used to
              identify you (“Personal Data”). Our Services are designed to avoid
              collecting information such as your name, address, email address
              or other similar contact data unless you provide such data to us.
              The information we collect include:
            </Typography>
            <ul>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Device Information
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  When you download and use our Services, we automatically
                  collect information on the type of device you use and the
                  mobile device identifiers (such as your device ID). We also
                  collect information about Wi-Fi or mobile data connections
                  availability.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Usage Information
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We collect information automatically about your activity
                  through our Services, for example features you have used,
                  in-app purchase history, subscriptions, total unit you have
                  purchased, total unit you have used and number of files you
                  have saved.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Other information
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We may collect upon your permission about your music library
                  and other cloud directory. If you decline permission for us to
                  collect your audio data, we will not collect it.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Information we obtain from third parties
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We may receive information about you from our analytic service
                  providers with data they collect through our Services in
                  accordance with their own privacy policies. A list of the
                  third parties that operate in our Services can be found in the
                  APPENDIX B below.
                </Typography>
              </li>
            </ul>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                WHAT WE WILL USE AND WHY
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="h5"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              We have set out below a description of the ways in which we use
              your information:
            </Typography>
            <ul>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We use your device ID to recognize you and setup our app for
                  you.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We check your internet connectivity to fetch useful data which
                  is needed to setup our app.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We will use your Device storage for saving Data our app
                  produced.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We will use your storage to track your usages and
                  In-App-Purchase activities.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We will use your mobile data or wifi to push your usage
                  information to our server to track on your used minutes.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h6"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  To serve personalized advertisements in our Services using
                  third-party advertising networks.
                </Typography>
              </li>
            </ul>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                STORAGE, DISCLOSURE, TRANSFER AND SHARING OF YOUR INFORMATION
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <ul>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Storage
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  We use your device storage to save converted audio, imported
                  files from cloud and few information regarding
                  In-app-purchases.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="21px"
                  sx={{
                    color: "#3F3F3F",
                    mb: 1,
                  }}
                >
                  Share with our service providers and partners
                </Typography>
                <Typography
                  variant="body1"
                  lineHeight="19px"
                  fontSize="14px"
                  fontWeight="normal"
                  letterSpacing=".3px"
                  sx={{
                    color: "#6B6B6B",
                    marginBottom: "10px",
                  }}
                >
                  {` We use third parties to help us operate and improve our Services.
              These third parties assist us with various tasks, including
              analytics, marketing, advertising. We may also share information
              with advertising partners who distribute advertising in our
              Services. For further information on advertising networks go to
              ‘APPENDIX A - Advertising networks and their partners’ below and
              for further information on analytics service provides go to
              ‘APPENDIX B - Analytics and other service providers’ below.`}
                </Typography>
              </li>
            </ul>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                AGE LIMITS
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              There is no Age limitation.
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                LINKS TO OTHER SITES
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              {`Our Privacy Policy may contain links to other sites that are not
          operated by us. If you click on a third-party link, you will be
          directed to that third party's site. We strongly advise you to review
          the privacy terms of every site you visit. We have no control over and
          assume no responsibility for the content, privacy terms or practices
          of any third-party sites or services.`}
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                CHANGES TO THIS PRIVACY POLICY
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              {`This Privacy Policy may change from time to time, and we encourage you
          to review it periodically. Your continued use of our Services
          signifies your acceptance of changes to this Privacy Policy. In some
          cases, we may notify you of changes to this Privacy Policy with a
          prominent notice on our Services (for example by way of a notification
          or pop-up), before the changes become effective and update the
          "effective date" at the top of this Privacy Policy.`}
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                CONTACT US
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              If you have any questions about this Privacy Policy, please
              contact us using the in-app support feature in our Services, which
              is the preferred method to be able to quickly respond to your
              requests or contact our data protection officer at the address
              provided below:
              <br />
              <br />
              Tahlil Ahmed Chowdhury
              <br />
              <br />
              Inverse.AI
              <br />
              <br />
              11B , Rose Garden Tower , Dorga Gate
              <br />
              <br />
              Sylhet , 3100
              <br />
              <br />
              Sylhet, Bangladesh
              <br />
              <br />
              inverseaibd@gmail.com
              <br />
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                APPENDIX A - Advertising networks and their partners
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              Below, you can find a list with third-party advertising networks
              and their partners, with whom we work to provide you with a
              personalized advertisement experience within our apps. The
              advertising networks listed below.
              <br />
              <br />
              {`In order to provide personalized advertisements to our users, those
          listed below may use device information that include personal and
          non-personal information, such as advertising (or ad) identifiers, IP
          address regarding the delivery of advertisements and your interaction
          with them, inventory of installed apps and/or other tracking
          technologies to enable and optimize this advertising procedure. Ad
          identifiers are non-permanent device identifiers such as the Android
          advertising ID and/or Apple's ID for advertising (IDFA).`}
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                Facebook Audience Network
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              Facebook Audience Network is an advertising service provided by
              Facebook, Inc. (US)
              <br />
              <br />
              Privacy Policy:
              <>
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://www.facebook.com/about/privacy/
                </a>
              </>
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                Google AdSense
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              {`Google, as a third-party vendor, uses cookies to serve ads on our
          Service. Google's use of the DoubleClick cookie enables it and its
          partners to serve ads to our users based on their visit to our Service
          or other websites on the Internet`}
              <br />
              <br />
              You may opt out of the use of the DoubleClick Cookie for
              interest-based advertising by visiting the Google Ads Settings web
              page:
              <>
                <a
                  href="http://www.google.com/ads/preferences/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  http://www.google.com/ads/preferences/
                </a>
              </>
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                AdMob by Google and their partners
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              AdMob by Google is provided by Google Inc. (US)
              <br />
              <br />
              For more information on how AdMob uses the collected information,
              please visit the Privacy Policy of Google:
              <>
                <a
                  href="http://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  http://www.google.com/policies/privacy/
                </a>
              </>
              <br />
              <br />
              For more information on AdMob Partners, please visit:
              <>
                <a
                  href="https://support.google.com/admob/answer/9012903"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://support.google.com/admob/answer/9012903
                </a>
              </>
              <br />
              <br />
              You can opt-out from AdMob by Google service by following the
              instructions described by Google:
              <>
                <a
                  href="https://support.google.com/ads/answer/2662922?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://support.google.com/ads/answer/2662922?hl=en
                </a>
              </>
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                Unity Ads
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              Unity Ads is an advertising service provided by Unity Ads Inc.
              (US)
              <br />
              <br />
              Privacy Policy:
              <>
                <a
                  href="https://unity3d.com/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://unity3d.com/legal/privacy-policy
                </a>
              </>
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                APPENDIX B - Analytics and other service providers
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              We use your information to perform our own analytics and to enable
              analytics provided by third parties and other essential functions.
              We use analytical information for supporting business analysis and
              operations, business intelligence, product development, improving
              the Services, personalizing content, providing advertising, and
              making recommendations. In order to learn about how your
              information is used by our analytics service providers, you can
              follow the hyperlinks in the list below to each provider’s privacy
              notice.
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                Facebook Ads conversion
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              Facebook Ads conversion tracking is an analytics service provided
              by Facebook, Inc. (US) that connects data from the Facebook
              advertising network with actions performed on this Application.
              <br />
              <br />
              Privacy Policy:
              <>
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://www.facebook.com/about/privacy/
                </a>
              </>
            </Typography>

            <Box
              sx={{
                width: "100%",
                margin: "30px 0",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="h2"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="21px"
                sx={{
                  color: " #3F3F3F",
                  padding: "15px 0",
                }}
              >
                Firebase
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Typography
              variant="body1"
              lineHeight="19px"
              fontSize="14px"
              fontWeight="normal"
              letterSpacing=".3px"
              sx={{
                color: "#6B6B6B",
                marginBottom: "15px",
              }}
            >
              Firebase is analytics service provided by Google Inc. (US)
              <br />
              <br />
              You may opt-out of certain Firebase features through your mobile
              device settings, such as your device advertising settings or by
              following the instructions provided by Google in their Privacy
              Policy:
              <>
                <a
                  href="http://www.google.com/intl/en/policies/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  http://www.google.com/intl/en/policies/privacy/
                </a>
              </>
            </Typography>
          </PrivacyPolicySection>
        </PrivacyPolicyStyle>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPage;

const PrivacyPolicyStyle = styled("div")({
  // width: "70%",
  // margin: "auto",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "flex-start",
});

const PrivacyPolicySection = styled("div")({
  // maxWidth: "1000px",
  // display: "flex",
  // flexFlow: "column nowrap",
  // justifyContent: "center",
  // alignItems: "flex-start",
  "& a": {
    color: "blue!important",
    cursor: "pointer",
  },
});

// const Section = styled.div``;
