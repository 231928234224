import * as types from "./types";

export const insertSign = (signObj) => ({
  type: types.INSERT_SIGN,
  payload: signObj,
});

export const deleteSign = (index) => ({
  type: types.DELETE_SIGN,
  payload: index,
});
