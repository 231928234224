import RenderImage from "../../UI/Canvas/RenderImage";

import { Rnd } from "react-rnd";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { color } from "@mui/system";

const ResizableSignImageComponent = ({
  pageIndex,
  signIndex,
  sign,
  signImageObject,
  onSignImageUpdate,
  onDeleteSignImage,
  canvasRef,
  containerRef,
}) => {
  const [hoverEl, setHoverEl] = useState(null);
  const open = Boolean(hoverEl);
  const handleHover = (event) => {
    setHoverEl(event.currentTarget);
  };

  const offsetX =
    canvasRef.current.getBoundingClientRect()["x"] -
    containerRef.current.getBoundingClientRect()["x"];
  const offsetY =
    canvasRef.current.getBoundingClientRect()["y"] -
    containerRef.current.getBoundingClientRect()["y"];

  const signWidth = parseFloat(signImageObject.width);
  const signHeight = parseFloat(signImageObject.height);
  return (
    <ClickAwayListener onClickAway={() => setHoverEl(null)}>
      <Rnd
        style={{
          zIndex: 1,
        }}
        size={{ width: signWidth, height: signHeight }}
        position={{
          x: signImageObject.offsetX + offsetX,
          y: signImageObject.offsetY + offsetY,
        }}
        bounds="parent"
        lockAspectRatio
        onDragStop={(e, d) => {
          const nextX = d.x;
          const nextY = d.y;

          onSignImageUpdate(
            pageIndex,
            signIndex,
            nextX,
            nextY,
            signHeight,
            signWidth
          );
        }}
        onResize={(e, direction, ref, delta, position) => {
          onSignImageUpdate(
            pageIndex,
            signIndex,
            position.x,
            position.y,
            parseFloat(ref.style.height),
            parseFloat(ref.style.width)
          );
        }}
      >
        <Box
          sx={{
            height: `${signHeight}px`,
            width: `${signWidth}px`,
            border: "1px solid transparent",
            // background: "red",
            "&:hover": {
              border: "1px solid red",
            },
          }}
          onMouseEnter={handleHover}
        >
          <RenderImage
            image={sign[signImageObject["img"]].file}
            height={signHeight}
            width={signWidth}
            rotation={0}
            keepAspectRation={false}
            cursor="move"
          />
          <Popper
            anchorEl={hoverEl}
            open={open}
            placement="top-start"
            disablePortal
          >
            <Button
              variant="outlined"
              sx={{
                padding: "2px",
                minWidth: "auto",
                borderRadius: "0px",
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "#d9f0fa",
                },
              }}
              onClick={() => onDeleteSignImage(pageIndex, signIndex)}
            >
              <IconButton sx={{ padding: 0, minWidth: "auto" }}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Button>
          </Popper>
        </Box>
      </Rnd>
    </ClickAwayListener>
  );
};

export default ResizableSignImageComponent;
