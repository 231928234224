import { Button } from "@mui/material";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
const ItalicButton = ({ fontStyle, onUpdate }) => {
  return (
    <Button
      variant="outlined"
      disableElevation
      disableRipple
      sx={{
        padding: "2px",
        margin: 0,
        minWidth: "auto",
        borderRadius: "0px",
        backgroundColor: `${fontStyle === "italic" ? "#d9f0fa" : "white"}`,
        "&:hover": {
          backgroundColor: `${fontStyle === "italic" ? "#d9f0fa" : "white"}`,
        },
      }}
      onClick={() =>
        onUpdate({
          fontStyle: fontStyle === "normal" ? "italic" : "normal",
        })
      }
    >
      <FormatItalicIcon color="primary" variant="outlined" />
    </Button>
  );
};

export default ItalicButton;
