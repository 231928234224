import { Typography } from "@mui/material";
import classes from "./InitialUpload.module.css";
import UploadManager from "./UploadManager";

const InitialUpload = ({ title, description, ...rest }) => {
  return (
    <div className={classes.initialUpload}>
      <Typography
        variant="h2"
        sx={{
          marginTop: "30px",
          fontWeight: "bold",
          fontSize: {
            xl: "45px",
            lg: "40px",
            md: "36px",
            sm: "32px",
            xs: "25px",
          },
          background:
            "linear-gradient(90.07deg, #ee0979 5.77%, #ff6a00 106.28%)",
          backgroundClip: "text",
          color: "transparent",
        }}
      >
        {title}
      </Typography>
      <Typography
        fontStyle="normal"
        fontWeight="400"
        color="#4A4A4A"
        variant="body1"
        sx={{
          px: 2,
          fontSize: {
            xl: "17px",
            lg: "16px",
            md: "15px",
            sm: "14px",
            xs: "12px",
          },
          textAlign: "center !important",
          textAlignLast: "center",
        }}
      >
        {description}
      </Typography>
      <UploadManager {...rest} />
    </div>
  );
};

export default InitialUpload;
