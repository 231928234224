import React, { createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { PersistGate } from "redux-persist/integration/react";
import { wrapper } from "./redux/store";
import { useStore } from "react-redux";

import NotFound from "./pages/NotFoundPage";

import HomePage from "./pages/HomePage";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import AuthProvider from "./hooks/useAuth";
import NotificationProvider from "./context/NotificationContext";

import SignPdfPage from "./pages/SignPdfPage";
import PdfToImagePage from "./pages/PdfToImagePage";
import ImageToPdfPage from "./pages/ImageToPdfPage";
import SplitPdfPage from "./pages/SplitPdfPage";
import CompressPdfPage from "./pages/CompressPdfPage";
import PdfPaginationPage from "./pages/PdfPaginationPage";
import WatermarkPdfPage from "./pages/WatermarkPdfPage";
import OrganizePdfPage from "./pages/OrganizePdfPage";
import UnlockPdfPage from "./pages/UnlockPdfPage";
import RotatePdfPage from "./pages/RotatePdfPage";
import ProtectPdfPage from "./pages/ProtectPdfPage";
import MergePdfPage from "./pages/MergePdfPage";
import PrivacyPage from "./pages/Privacy/Privacy";

export const userContext = createContext();

const App = (props) => {
  const store = useStore();

  return (
    <PersistGate loading={null} persistor={store.__persistor}>
      <DndProvider backend={HTML5Backend}>
        <AuthProvider>
          <NotificationProvider>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />}></Route>
                <Route
                  path="/tools/merge-pdf"
                  element={<MergePdfPage />}
                ></Route>
                <Route path="/tools/sign-pdf" element={<SignPdfPage />}></Route>
                <Route
                  path="/tools/pdf-to-image"
                  element={<PdfToImagePage />}
                ></Route>
                <Route
                  path="/tools/image-to-pdf"
                  element={<ImageToPdfPage />}
                ></Route>
                <Route
                  path="/tools/split-pdf"
                  element={<SplitPdfPage />}
                ></Route>
                <Route
                  path="/tools/compress-pdf"
                  element={<CompressPdfPage />}
                ></Route>
                <Route
                  path="/tools/pagination-pdf"
                  element={<PdfPaginationPage />}
                ></Route>
                <Route
                  path="/tools/watermark-pdf"
                  element={<WatermarkPdfPage />}
                ></Route>
                <Route
                  path="/tools/organize-pdf"
                  element={<OrganizePdfPage />}
                ></Route>
                <Route
                  path="/tools/unlock-pdf"
                  element={<UnlockPdfPage />}
                ></Route>
                <Route
                  path="/tools/rotate-pdf"
                  element={<RotatePdfPage />}
                ></Route>
                <Route path="tools/protect-pdf" element={<ProtectPdfPage />} />
                <Route path="/account/login" element={<LoginPage />}></Route>
                <Route path="/account/signup" element={<SignupPage />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPage />} />
                <Route path="/*" element={<NotFound />}></Route>
              </Routes>
            </Router>
          </NotificationProvider>
        </AuthProvider>
      </DndProvider>
    </PersistGate>
  );
};

export default wrapper.withRedux(App);
